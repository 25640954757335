let statusOptions = [
  {
    label: "All",
    value: "all",
  },

  {
    label: "Answered",
    value: "Answered",
  },
  {
    label: "Pushed",
    value: "Pushed",
  },
  {
    label: "Normal Unspecified",
    value: "NormalUnspecified",
  },
  {
    label: "No Answer",
    value: "NoAnswer",
  },
  {
    label: "Protocol Error",
    value: "ProtocolError",
  },
  {
    label: "Inter Working Unspecified",
    value: "InterWorkingUnspecified",
  },

  {
    label: "Not Answered",
    value: "not_answered",
  },
  {
    label: "User Disconnected",
    value: "user_disconnected",
  },
  {
    label: "Max Dial Time Exceeded",
    value: "MaxDialTimeExceeded",
  },
  {
    label: "Not Dialed",
    value: "Not Dialed",
  },
  {
    label: "Normal Call Clearing",
    value: "NormalCallClearing",
  },
  {
    label: "Subscriber Absent",
    value: "SubscriberAbsent",
  },
];

export { statusOptions };
