import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import { API_URL } from "../../config";
import { get_data, post_data } from "../../cruxreactlib/networkhandler";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import CreateDependentField from "../TicketDetails/components/FieldTypes/CreateDependentField";
import CreateDropDownField from "../TicketDetails/components/FieldTypes/CreateDropdownField";
import CreateTextField from "../TicketDetails/components/FieldTypes/CreateTextField";

function CreateTicket() {
  let [ticket, setTicket] = useState({});
  let [data, setData] = useState([]);
  let [loader, setLoader] = useState(true);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(function () {
    appContext.setTitle("Ticket");
    let ticketFields = appContext.ticketFields;

    if (ticketFields && ticketFields?.length) {
      setData(ticketFields);
      setLoader(false);
    } else {
      get_data(`${API_URL}/convo/config/v1/`, appContext).then(function (data) {
        if (data) {
          const filtered_data = data;
          appContext.setTicketFields(filtered_data);
          setData(filtered_data);
          setLoader(false);
        }
      });
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    let payload = {};
    Object.entries(ticket)
      ?.filter((info) => info?.[1]?.length || info?.[1] != "")
      ?.map((info) => {
        payload[info[0]] = info[1];
      });

    post_data(`${API_URL}/convo/ticket/v1/`, payload, appContext, true).then(
      function (data) {
        if (data) {
          navigate(`/details/${data?.data?.id}`);
        }
      }
    );
    // ticket["source"] = 2;
    // if (ticket["subject"] && ticket["description"] && ticket["phone"]) {
    //   post_data(`${API_URL}/convo/ticket/v1/`, ticket, appContext, true).then(
    //     function (data) {
    //       if (data) {
    //         navigate(`/ticket/details/${data.ticket_id}`);
    //       }
    //     }
    //   );
    // } else {
    //   appContext.setAlert("Provide valid data !!", "alert_error");
    // }
  }

  function checkValidFilter(item) {
    if (item?.field_type == "dropdown" || item?.field_type == "dependent") {
      return true;
    }
    return true;
  }

  function renderFieldType(item) {
    if (item?.field_type == "dependent" && !item?.parent_field) {
      return (
        <CreateDependentField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={data}
          isClearable={true}
          create={true}
        />
      );
    } else if (item?.field_type == "dropdown" && !item?.parent_field) {
      return (
        <CreateDropDownField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={data}
          isClearable={true}
          create={true}
        />
      );
    } else if (
      (item?.field_type == "text" || item?.field_type == "integer") &&
      !item?.parent_field
    ) {
      return (
        <CreateTextField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={data}
          create={true}
        />
      );
    } else if (item?.field_type == "phone" && !item?.parent_field) {
      return (
        <CreateTextField
          phone={true}
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={data}
          create={true}
        />
      );
    }
  }
  return loader ? (
    <></>
  ) : (
    <form className="form" onSubmit={handleSubmit}>
      <h1 style={{ margin: "1vh 1vw" }}>Create Dashboard Ticket</h1>

      <div className="flex_box_col">
        {data?.config?.ticket_fields?.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              {checkValidFilter(item) && renderFieldType(item)}
            </React.Fragment>
          );
        })}
        <div className="input-box">
          <label className="label_jodit">Description</label>
          <JoditEditor
            className="input_jodit"
            value={ticket?.description}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => {
              setTicket({ ...ticket, description: newContent });
            }}
          />
        </div>
      </div>

      <div className="btn-container">
        <input className="dark-btn" type="submit" />
      </div>
    </form>
  );
}

export default CreateTicket;
