import { API_URL } from "../config";
import { get_data } from "../cruxreactlib/networkhandler";

async function getDiallerDocument(id, appContext, mapping) {
  const data = await get_data(
    `${API_URL}/convo/ticket/dialler/${id}/v1/`,
    appContext
  );
  if (data) {
    return handleData(data?.data, mapping, id);
  }
}

async function handleDiallerTicket(payload, mapping, appContext) {
  let dialler_id = payload?.uui;
  const data = await getDiallerDocument(dialler_id, appContext, mapping);
  if (data) {
    return data;
  }
}

function handleData(document, mapping, id) {
  let payload = {};
  payload.phone = document?.phone;
  payload.campaign = document?.campaign;
  payload.subject = document?.subject;
  payload.dialler = id;
  payload.description = document?.description || "Ticket Description";

  Object.keys(document)?.map((item) => {
    if (mapping[item]) {
      payload[mapping[item]] = document[item];
    }
  });

  return payload;
}

export { handleDiallerTicket };
