import React, { useContext, useEffect, useState } from "react";
import styles from "./css/dashboard.module.css";
import { AppContext } from "../../../../App";
import SideBar from "./SideBar/index";
import Ticket from "./Ticket/index";
function TicketDashboard() {
  const appContext = useContext(AppContext);
  const [ticketData, setTicketData] = useState({}); //store ticket data
  const [key, setKey] = useState(0); //unique index state
  const [dbFields, setDBFields] = useState([]);
  //effect on reload
  useEffect(() => {
    setDBFields(appContext.ticketFields?.config?.db_fields);
    appContext.setTitle("Ticket Fields");
  }, [appContext.reload, appContext.ticketFields]);

  function checkDbFields() {
    let check = false;
    dbFields?.map((item) => {
      if (!item?.is_used) {
        check = true;
      }
    });

    return check;
  }

  //handle add new ticket
  function handleTicketItem(item) {
    if (!checkDbFields()) {
      appContext.setAlert("No Fields Left", "alert_error");
      return;
    }

    if (ticketData?.isNew) {
      return;
    }

    let id = key;
    setTicketData({
      uid: id,
      isNew: true,
      title: item.value,
      icon: item.name,
      type: item.value,
    });
    id++;
    setKey(id);
  }

  return (
    <div className={styles.ticket_container}>
      <SideBar callbackfn={handleTicketItem} />
      <Ticket
        ticketData={ticketData}
        setTicketData={setTicketData}
        callbackfn={handleTicketItem}
        appContext={appContext}
        dbFields={dbFields}
      />
    </div>
  );
}

export default TicketDashboard;
