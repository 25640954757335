import React, { useEffect } from "react";
import styles from "../../css/field.module.css";
import Select from "react-select";
import { customStyles } from "../../../../cruxreactlib/utils/customStyles";
function DropdownField({
  item,
  filter,
  setFilter,
  ticketFields,
  isClearable,
  create,
}) {
  return (
    <div className={styles.filter}>
      <label className={styles.label}>
        {ticketFields?.field_dict?.[item?.key]?.label || "--"}
      </label>
      <Select
        styles={customStyles}
        isClearable={isClearable}
        placeholder={`Select ${
          ticketFields?.field_dict?.[item?.key]?.label || "--"
        }`}
        // placeholder="--"
        options={item?.choices?.map((info) => {
          if (info?.value) {
            return { ...info, value: info?.value };
          } else {
            return { ...info, value: info?.label };
          }
        })}
        value={
          item?.choices?.filter(function (info, idx) {
            if (info?.value) {
              return filter?.[item?.key] == info?.value;
            } else {
              return filter?.[item?.key] == info?.label;
            }
          }) ||
          []?.map((info) => ({ ...info, value: info?.value || info?.label }))
        }
        isDisabled={item?.can_edit === false && !create}
        onChange={function (e) {
          setFilter({
            ...filter,
            [item?.key]: e?.value,
          });
        }}
      />
    </div>
  );
}

export default DropdownField;
