import React from "react";
import { AppContext } from "../../../../App";
import { useState, useContext, useEffect } from "react";
import GroupDetails from "./components/Group";
import Navbar from "./components/Navbar";
import styles from "./css/style.module.css";
import { TeamContext } from "../../../../Routes/AdminRoutes/Components/TeamsRoute";
import { ReactComponent as NoFound } from "../../../../assets/NoFound.svg";
function Group() {
  let [loader, setLoader] = useState(true);
  let [query, setQuery] = useState("");

  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { details, constants } = teamContext || {};
  const { groups } = details || {};

  useEffect(
    function () {
      appContext.setTitle("Groups");
      if (details && details?.groups !== undefined) {
        setLoader(false);
      }
    },
    [appContext.reload]
  );

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <Navbar query={query} setQuery={setQuery} />
      <div className={`"item-row-container" ${styles.container}`}>
        <div className={styles.header}>
          <p className={styles.heading}>Groups</p>
          <p className={styles.heading}>Ecosystem</p>{" "}
          <p className={styles.heading}>Business hours</p>{" "}
          <p className={styles.heading} style={{ visibility: "hidden" }}>
            del
          </p>
          <p className={styles.heading} style={{ visibility: "hidden" }}>
            Seen
          </p>
        </div>

        <div className={styles.group_container}>
          {groups?.length > 0 ? (
            <>
              {groups?.map(function (item, index) {
                if (query) {
                  return item?.name
                    ?.toLowerCase()
                    .includes(query.toLowerCase()) ? (
                    <GroupDetails
                      data={item}
                      constants={constants}
                      key={index}
                    />
                  ) : null;
                } else {
                  return (
                    <GroupDetails
                      data={item}
                      constants={constants}
                      key={index}
                    />
                  );
                }
              })}
            </>
          ) : (
            <h1 className={styles.no_user}>
              <NoFound />
              <p className={styles.no_user_text}>No Group found</p>
            </h1>
          )}
        </div>
      </div>
    </>
  );
}

export default Group;
