import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppContext } from "../../App";
import { API_URL } from "../../config";
import {
  get_data,
  post_data_without_reload,
} from "../../cruxreactlib/networkhandler";
import styles from "./css/style.module.css";

import DiallerData from "./components/DiallerData";
import DatePicker from "../../cruxreactlib/utils/DatePicker";
import { formatDate } from "../../cruxreactlib/utils/DatePicker/components/utility";
import Select from "react-select";
import { statusOptions } from "./components/seed";

function DiallerLogs() {
  const appContext = useContext(AppContext);
  let [details, setDetails] = useState([]);
  let [page, setPage] = useState(1);
  let [next, setNext] = useState(true);
  let [count, setCount] = useState(0);
  let [diallerFilters, setDiallerFilters] = useState({});
  let [loader, setLoader] = useState(false);
  let [selectedDate, setSelectedDate] = useState({
    from_slot: formatDate(
      new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    ),
    to_slot: formatDate(new Date()),
  });

  useEffect(
    function () {
      let n_slot = [];

      n_slot[0] = formatDate(
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      );
      n_slot[1] = formatDate(new Date());
      setDiallerFilters((current) => {
        return {
          ...current,
          n_slot_id: n_slot,
          call_status: statusOptions?.slice(1)?.map((item) => item?.value),
        };
      });

      appContext.setTitle("Logs");
      appContext.setPage("Logs");
    },
    [appContext.reload]
  );

  useEffect(() => {
    if (Object?.keys(diallerFilters)?.length > 0) {
      setNext(true);
      get_dialler_logs_count();
      get_dialler_logs();
    }
  }, [appContext.reload, diallerFilters]);

  function get_dialler_logs_count() {
    post_data_without_reload(
      `${API_URL}/convo/ticket/dialler/count/v1/`,
      {
        filters: Object.entries(diallerFilters)
          ?.filter((item) => item[1])
          ?.map((item) => ({
            key: item[0],
            value: item[1],
            operator: item[0] === "n_slot_id" ? "range" : "in",
          })),
      },
      appContext,
      false
    ).then(function (data) {
      if (data) {
        setCount(data?.count);
      }
    });
  }

  function get_dialler_logs() {
    setLoader(true);
    post_data_without_reload(
      `${API_URL}/convo/ticket/dialler/list/v1/`,
      {
        filters: Object.entries(diallerFilters)
          ?.filter((item) => item[1])
          ?.map((item) => ({
            key: item[0],
            value: item[1],
            operator: item[0] === "n_slot_id" ? "range" : "in",
          })),
        page: 1,
      },
      appContext,
      false
    ).then(function (data) {
      if (data) {
        setDetails(data?.data);
        setNext(data?.has_next);
        setPage(data?.page);
        setLoader(false);
      }
    });
  }

  function handleDate(from_slot, to_slot) {
    setSelectedDate({ from_slot: from_slot, to_slot: to_slot });
    let n_slot = [];
    n_slot[0] = from_slot;
    n_slot[1] = to_slot;

    setDiallerFilters((current) => {
      return { ...current, n_slot_id: n_slot };
    });
  }

  function handleStatusChange(e) {
    const { label, value } = e;

    if (value === "all") {
      setDiallerFilters((current) => {
        return {
          ...current,
          call_status: statusOptions?.slice(1)?.map((item) => item?.value),
        };
      });
    } else {
      setDiallerFilters((current) => {
        return { ...current, call_status: [value] };
      });
    }
  }

  useEffect(() => {
    console.log(diallerFilters, "kd");
  }, [diallerFilters]);

  return (
    <>
      <div className={styles.logs_header}>
        <div className={styles.count_container}>
          <span className={styles.count_label}>Count</span>:{" "}
          <span className={styles.label_bold}>{count}</span>
        </div>
        <div className={styles.logs_slot}>
          <div className={styles.logs_time}>
            <DatePicker callBackfn={handleDate} />
          </div>
          <Select
            className={styles.status_container}
            options={statusOptions}
            value={statusOptions?.filter((item) =>
              diallerFilters?.call_status?.length > 1
                ? item?.value === "all"
                : item?.value === diallerFilters?.call_status?.[0]
            )}
            onChange={handleStatusChange}
            placeholder="status"
          />
        </div>
      </div>

      {loader ? (
        <div className="loader_container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className={styles.dialler_container} id="scrollable">
          <InfiniteScroll
            scrollableTarget="scrollable"
            dataLength={details?.length}
            pageStart={1}
            next={function () {
              post_data_without_reload(
                `${API_URL}/convo/ticket/dialler/list/v1/`,
                {
                  filters: Object.entries(diallerFilters)
                    ?.filter((item) => item[1])
                    ?.map((item) => ({
                      key: item[0],
                      value: item[1],
                      operator: item[0] === "n_slot_id" ? "range" : "in",
                    })),
                  page: page,
                },
                appContext,
                false
              ).then(function (data) {
                if (data) {
                  setDetails(details?.concat(data?.data));
                  setNext(data?.has_next);
                  setPage(data?.page);
                  setLoader(false);
                }
              });
            }}
            hasMore={next}
            loader={<h4 style={{ textAlign: "center" }}>Loading ... </h4>}
            endMessage={
              details?.length > 0 ? (
                <p style={{ textAlign: "center", marginTop: "2vh" }}>
                  <b>Yay! You have seen all data !!</b>
                </p>
              ) : (
                <></>
              )
            }
          >
            <div className={styles.dialler_wrapper}>
              {details?.length > 0 ? (
                details?.map(function (item, idx) {
                  return <DiallerData item={item} key={idx} />;
                })
              ) : (
                <p style={{ textAlign: "center" }}>
                  <b>No Logs found !!</b>
                </p>
              )}{" "}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </>
  );
}

export default DiallerLogs;
