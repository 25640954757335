import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../App";
import Header from "../components/Header/Header";
import { API_URL } from "../config";
import { get_data } from "../cruxreactlib/networkhandler";
import SwitchHeader from "../cruxreactlib/SwitchHeader";
function HeaderRoutes(props) {
  const appContext = useContext(AppContext);

  useEffect(
    function () {
      get_filters();
    },
    [appContext.reload]
  );

  function get_filters() {
    get_data(`${API_URL}/convo/config/v1/`, appContext).then(function (data) {
      if (data) {
        const filtered_data = data;
        appContext.setTicketFields(filtered_data);
      }
    });
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default HeaderRoutes;
