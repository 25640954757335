import React from "react";
import styles from "../Search/styles.module.css";

const Search = (props) => {
  const { query, setQuery } = props.context;
  return (
    <div>
      <input
        className={styles.input}
        placeholder="Search by Name/Phone"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  );
};

export default Search;
