import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../../App";
import { API_URL } from "../../../../../config";
import {
  delete_data,
  get_data,
  patch_data,
  post_data,
} from "../../../../../cruxreactlib/networkhandler";
import styles from "./style.module.css";
import { ReactComponent as Delete } from "../../../../../assets/Admin/Delete.svg";

function EditBusinessHour() {
  const appContext = useContext(AppContext);
  const { business_hour_id } = useParams();
  const navigate = useNavigate();

  let [name, setName] = useState("");
  let [bh, setBh] = useState([
    { idx: 1, start_time: "1200", end_time: "1200" },
  ]);

  useEffect(function () {
    appContext.setTitle("Business Hour");
    get_data(
      `${API_URL}/convo/users/working/hour/${business_hour_id}/v1/`,
      appContext
    ).then(function (data) {
      setName(data?.data?.name);
      setBh(data?.data?.slots.map((slot, idx) => ({ ...slot, idx: idx + 1 })));
    });
  }, []);

  const formatTime = (time) =>
    ("0" + Math.floor(time / 100)).slice(-2) +
    ":" +
    ("0" + (time % 100)).slice(-2);

  function handleSubmit(e) {
    e.preventDefault();
    if (!name) {
      appContext.setAlert("Provide valid name !!", "alert_error");
      return;
    }
    patch_data(
      `${API_URL}/convo/users/working/hour/${business_hour_id}/v1/`,
      { name: name },
      appContext,
      true
    ).then(function (data) {
      if (data) {
        navigate("/teams/business-hour");
      }
    });
  }

  function handleClose() {
    navigate("/teams/business-hour");
  }

  function handleAddHour() {
    post_data(
      `${API_URL}/convo/users/working/hour/slot/${business_hour_id}/v1/`,
      { start_time: 1200, end_time: 1200, is_active: 0 },
      appContext,
      false
    ).then(function (data) {
      if (data) {
        setBh((prevBh) => [
          ...prevBh,
          { idx: prevBh?.length + 1, start_time: 1200, end_time: 1200 },
        ]);
      }
    });
  }

  function handleDeleteSlot(idx, slot_id) {
    delete_data(
      `${API_URL}/convo/users/working/hour/slot/${business_hour_id}/${slot_id}/v1/`,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setBh((prevBh) => prevBh.filter((item) => item.idx !== idx));
      }
    });
  }

  function handleActiveSlot(e, slot_id) {
    e.preventDefault();
    patch_data(
      `${API_URL}/convo/users/working/hour/slot/${slot_id}/v1/`,
      {
        is_active: e.target.checked,
      },
      appContext,
      true
    );
  }

  function handleTimeChange(e, name, id, idx) {
    e.preventDefault();
    patch_data(
      `${API_URL}/convo/users/working/hour/slot/${business_hour_id}/${id}/v1/`,
      { [name]: parseInt(e.target.value.split(":").join(""), 10) },
      appContext,
      false
    );

    setBh((prevBh) =>
      prevBh.map((element, index) =>
        index === idx
          ? {
              ...element,
              [name]: parseInt(e.target.value.split(":").join(""), 10),
            }
          : element
      )
    );
  }

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.edit_heading}>Edit Business Hour</h2>
        <form className={styles.edit_form} onSubmit={handleSubmit}>
          <div className={styles.input_container}>
            <label className={styles.label}>Business Hour</label>
            <input
              type="text"
              className={styles.input}
              name="name"
              required
              placeholder="Business Hour .."
              value={name}
              onChange={function (e) {
                setName(e.target.value);
              }}
            />
          </div>

          <div className={styles.outer_div}>
            {bh?.map(function (item, idx) {
              return (
                <div className={styles.input_box_row} key={idx}>
                  <input
                    type="time"
                    className={styles.inline_input}
                    style={{ width: "40%" }}
                    value={formatTime(item?.start_time)}
                    onChange={(e) =>
                      handleTimeChange(e, "start_time", item.id, idx)
                    }
                  />
                  <p>to</p>
                  <input
                    type="time"
                    className={styles.inline_input}
                    style={{ width: "40%" }}
                    value={formatTime(item?.end_time)}
                    onChange={(e) =>
                      handleTimeChange(e, "end_time", item.id, idx)
                    }
                  />

                  <div className={`${styles.incentive_info} ${styles.toggle}`}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        checked={item?.is_active}
                        className={styles.hide}
                        onChange={(e) => {
                          handleActiveSlot(e, item?.id);
                        }}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                  </div>
                  <button
                    type="button"
                    className={styles.cta}
                    onClick={() => handleDeleteSlot(item?.idx, item?.id)}
                  >
                    <Delete />
                  </button>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className={`btn ${styles.add_btn}`}
            onClick={handleAddHour}
          >
            + Add New Row
          </button>

          <div className={styles.btn_div}>
            <button className="dark-btn" type="submit">
              Edit Hour
            </button>
            <button type="button" className="btn" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditBusinessHour;
