import React, { useState, useEffect } from "react";
import styles from "../style.module.css";
import { ReactComponent as Email } from "../../../../assets/Admin/Email.svg";
import { ReactComponent as Phone } from "../../../../assets/Admin/Phone.svg";
import { ReactComponent as FB } from "../../../../assets/Admin/facebook.svg";
import { ReactComponent as Twitter } from "../../../../assets/Admin/twitter.svg";

function UserDetail({ user }) {
  let [randomColor, setRandomColor] = useState("#ffffff");

  useEffect(() => {
    const getRandomLightColor = () => {
      const getComponent = () => Math.floor(Math.random() * 128) + 128;
      const rgb = [getComponent(), getComponent(), getComponent()];
      return `#${rgb
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;
    };
    setRandomColor(getRandomLightColor());
  }, [user]);

  return (
    <div className={styles.user_container_wrapper}>
      <div className={styles.user_item_wrapper}>
        <p>Name</p>
        <div className={styles.col}>
          <p
            className={styles.icon_div}
            style={{ backgroundColor: randomColor }}
          >
            {user?.name?.charAt(0)}
          </p>

          <p className={styles.text}>{user?.name || "--"}</p>
        </div>
      </div>
      <div className={styles.user_item_wrapper}>
        <p>Email</p>
        <div className={styles.col}>
          <Email />
          <p className={styles.text}>{user?.email || "--"}</p>
        </div>
      </div>
      <div className={styles.user_item_wrapper}>
        <p>Facebook ID</p>
        <div className={styles.col}>
          <FB />
          <p className={styles.text}>{user?.facebook_id || "--"}</p>
        </div>
      </div>
      <div className={styles.user_item_wrapper}>
        <p>Twitter ID</p>
        <div className={styles.col}>
          <Twitter />
          <p className={styles.text}>{user?.twitter_id || "--"}</p>
        </div>
      </div>
      <div className={styles.user_item_wrapper}>
        <p>Phone</p>
        <div className={styles.col}>
          <Phone />
          <p className={styles.text}>{user?.phone || "--"}</p>
        </div>
      </div>
    </div>
  );
}

export default UserDetail;
