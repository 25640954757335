// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_header__RxAj7 {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5vh 1vw;
  max-height: 7.5vh;
  background-color: white;
  z-index: 10;
}

.style_select__TQfP8 {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0.5vh 0;
}
.style_subheader__qe2iD {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.style_icon_btn__K-XCT {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  font-size: 1.25rem;
  color: #4d4d4d;
}
.style_search__0rJiz {
  border: 1px solid #cfd7df;
  padding: 0.5vh 0.5vw;
  outline: none;
  margin: 0.5vh 1vw;
  padding: 0.5vh 0.5vw;
  border-radius: 5px;
  font-weight: 500;
  justify-content: center;
  background-color: #f3f5f7;
  color: var(--primary-color);
  border: 1px solid #cfd7df;
  background-image: linear-gradient(to bottom, #fff, #f3f5f7);
}

.style_profile__KVpbI {
  cursor: pointer;
}

.style_logout_btn__wdhLJ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/style.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,aAAa;EACb,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,2BAA2B;EAC3B,yBAAyB;EACzB,2DAA2D;AAC7D;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".header {\n  position: sticky;\n  top: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 1.5vh 1vw;\n  max-height: 7.5vh;\n  background-color: white;\n  z-index: 10;\n}\n\n.select {\n  border: none;\n  outline: none;\n  cursor: pointer;\n  background-color: transparent;\n  padding: 0.5vh 0;\n}\n.subheader {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n}\n.icon_btn {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  border: none;\n  background-color: transparent;\n  font-size: 1.25rem;\n  color: #4d4d4d;\n}\n.search {\n  border: 1px solid #cfd7df;\n  padding: 0.5vh 0.5vw;\n  outline: none;\n  margin: 0.5vh 1vw;\n  padding: 0.5vh 0.5vw;\n  border-radius: 5px;\n  font-weight: 500;\n  justify-content: center;\n  background-color: #f3f5f7;\n  color: var(--primary-color);\n  border: 1px solid #cfd7df;\n  background-image: linear-gradient(to bottom, #fff, #f3f5f7);\n}\n\n.profile {\n  cursor: pointer;\n}\n\n.logout_btn {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `style_header__RxAj7`,
	"select": `style_select__TQfP8`,
	"subheader": `style_subheader__qe2iD`,
	"icon_btn": `style_icon_btn__K-XCT`,
	"search": `style_search__0rJiz`,
	"profile": `style_profile__KVpbI`,
	"logout_btn": `style_logout_btn__wdhLJ`
};
export default ___CSS_LOADER_EXPORT___;
