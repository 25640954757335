import React, { useContext, useEffect, useState } from "react";
import styles from "./css/style.module.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { get_data } from "../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../config";
import SlaInfo from "./components/SlaInfo";
import SlaSidebar from "./components/SlaSidebar";

function SlaPolicy() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [slaData, setSlaData] = useState([]);

  // Handle redirect to create rule page
  function handleAddRule() {
    navigate(`/workflows/sla/newpolicy`);
  }

  useEffect(() => {
    setLoader(true);
    fetchAutomationData("creation");
  }, [appContext.reload]);

  async function fetchAutomationData(type) {
    const data = await get_data(
      `${API_URL}/convo/ticket/sla/list/v1/`,
      appContext
    );

    if (data) {
      setSlaData(data?.data);
      setLoader(false);
    }
  }

  return (
    <div className={styles.automation_wrapper}>
      <div className={styles.container}>
        <div className={styles.rule_add_container}>
          <span>
            The first matching SLA policy will be applied to tickets with
            matching conditions
          </span>
          <div className={styles.btn_container}>
            <button className={styles.new_rule_btn} onClick={handleAddRule}>
              Add Policy
            </button>
          </div>
        </div>

        {loader ? (
          <div className="loader_container">
            <div className="loader"></div>
          </div>
        ) : (
          <div className={styles.automation_list_container}>
            <SlaInfo slaData={slaData} />
          </div>
        )}
      </div>

      <SlaSidebar />
    </div>
  );
}

export default SlaPolicy;
