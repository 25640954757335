import React, { useContext, useEffect, useState } from "react";
import styles from "./css/style.module.css";
import SideItem from "./Components/SideItem";
import { get_data } from "../../../../../cruxreactlib/networkhandler";
import { AppContext } from "../../../../../App";
import { API_URL } from "../../../../../config";
function SideBar({ callbackfn }) {
  const [sideData, setSideData] = useState([]);
  const appContext = useContext(AppContext);

  useEffect(() => {
    getConstantData();
  }, []);

  async function getConstantData() {
    const data = await get_data(
      `${API_URL}/convo/config/constants/v1/`,
      appContext
    );
    if (data) {
      setSideData(data?.data?.field_types);
    }
  }

  //call parent fxn to add new ticket
  function handleItem(item) {
    callbackfn(item);
  }
  return (
    <div className={styles.container}>
      <p className={styles.side_header}>Choose Ticket Field</p>
      <div className={styles.ticket_fields}>
        {sideData?.map((item, idx) => {
          return (
            <div key={idx} onClick={() => handleItem(item)}>
              <SideItem item={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideBar;
