import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../style.module.css";
import { BsCalendar2Date } from "react-icons/bs";
import { ReactComponent as Profile } from "../../../../../assets/Admin/Profile.svg";

function Ticket({ data }) {
  return (
    <div className={styles.ticket}>
      <NavLink to={`/details/${data.id}`}>{data.subject}</NavLink>
      {/* <p>#{data.id}</p> */}
      {data?.agent ? (
        <p className={styles.calendar_icon}>
          <Profile /> {data?.agent}
        </p>
      ) : null}
      <p className={styles.calendar_icon} style={{ paddingLeft: "2px" }}>
        <BsCalendar2Date /> {data.created_at}
      </p>
    </div>
  );
}

export default Ticket;
