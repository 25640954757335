import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "../style.module.css";
import { ReactComponent as Phone } from "../../../../assets/Admin/Phone.svg";
import { ReactComponent as Profile } from "../../../../assets/Admin/Profile.svg";
function Ticket({ data, idx }) {
  let [randomColor, setRandomColor] = useState("#ffffff");
  const navigate = useNavigate();
  useEffect(() => {
    const getRandomLightColor = () => {
      const getComponent = () => Math.floor(Math.random() * 128) + 128;
      const rgb = [getComponent(), getComponent(), getComponent()];
      return `#${rgb
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;
    };
    setRandomColor(getRandomLightColor());
  }, [data]);

  function handleClick() {
    navigate(`/details/${data.id}`);
  }
  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <div className={styles.col}>
        <p className={styles.icon_div} style={{ backgroundColor: randomColor }}>
          {data?.status}
        </p>
        <div className={styles.text_wrapper}>
          <div>
            <span className={styles.bold_text}>{data.subject}</span>&nbsp;#
            <span>{data.id}</span>
          </div>
          <div className={styles.col_flex}>
            <p className={styles.small_text}>
              <Phone /> {data.phone}
            </p>
            <p className={styles.small_text}>Created {data.created_at}</p>
          </div>
          <div className={styles.col_flex}>
            <p className={styles.small_text}>
              <Profile />
              {data.agent}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ticket;
