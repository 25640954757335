import React from "react";
import { NavLink } from "react-router-dom";
import { API_URL } from "../../../../../../config";
import { patch_data } from "../../../../../../cruxreactlib/networkhandler";
import styles from "../style.module.css";

function BusinessHour({ data, idx, appContext }) {
  function handleActiveHour(e, hour_id) {
    e.preventDefault();
    patch_data(
      `${API_URL}/convo/users/working/hour/${hour_id}/v1/`,
      {
        is_active: e.target.checked,
      },
      appContext,
      true
    );
  }
  return (
    <div className={styles.wrapper} key={idx}>
      <NavLink
        to={`/teams/business-hour/${data.id}`}
        className={styles.item_row}
      >
        <div className="item-col">
          <div className={styles.col_text_bold}>{data?.name}</div>
          <div className={styles.col_text_small}>{data.id}</div>
        </div>
      </NavLink>

      <div className={`${styles.incentive_info} ${styles.toggle}`}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={data?.is_active}
            className={styles.hide}
            onChange={(e) => {
              handleActiveHour(e, data?.id);
            }}
          />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
      </div>
    </div>
  );
}

export default BusinessHour;
