import React, { useContext, useEffect, useState } from "react";
import styles from "./css/style.module.css";
import { useNavigate } from "react-router-dom";
import TicketCreation from "./components/TicketCreation";
import TicketTrigger from "./components/TicketTrigger";
import TicketUpdate from "./components/TicketUpdate";
import { AppContext } from "../../../../App";
import { get_data } from "../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../config";
import TabBar from "../../../../cruxreactlib/utils/TabBar";
import AutomationSidebar from "./components/AutomationSidebar";

function AutomationDashboard() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchType, setSearchType] = useState("creation");
  const [automationData, setAutomationData] = useState([]);

  // Handle redirect to create rule page
  function handleAddRule() {
    navigate(`/workflows/automation/newrule/${searchType}`);
  }

  useEffect(() => {
    setLoader(true);
    fetchAutomationData(searchType);
  }, [appContext.reload, searchType]);

  async function fetchAutomationData(type) {
    const data = await get_data(
      `${API_URL}/convo/automation/list/v1/?automation_type=${type}`,
      appContext
    );

    if (data) {
      setAutomationData(data?.data);
      setLoader(false);
    }
  }

  return (
    <div className={styles.automation_wrapper}>
      <div className={styles.container}>
        <label className={styles.rules_label}>Rules that run on:</label>
        <div className={styles.tab_container}>
          {/* Tabbar for ticket type */}
          <TabBar
            tabs={[
              {
                title: "Ticket Creation",
                execute: { func: setSearchType, value: "creation" },
              },
              {
                title: "Ticket Updates",
                execute: { func: setSearchType, value: "updation" },
              },
            ]}
            styles={styles}
          />
        </div>
        <div className={styles.rule_add_container}>
          <span>Executing all matching rules</span>
          <div className={styles.btn_container}>
            <button className={styles.new_rule_btn} onClick={handleAddRule}>
              New Rule
            </button>
          </div>
        </div>

        {loader ? (
          <div className="loader_container">
            <div className="loader"></div>
          </div>
        ) : (
          <div className={styles.automation_list_container}>
            {searchType === "creation" ? (
              <TicketCreation
                searchType={searchType}
                automationData={automationData}
              />
            ) : searchType === "trigger" ? (
              <TicketTrigger
                searchType={searchType}
                automationData={automationData}
              />
            ) : (
              <TicketUpdate
                searchType={searchType}
                automationData={automationData}
              />
            )}
          </div>
        )}
      </div>
      <AutomationSidebar />
    </div>
  );
}

export default AutomationDashboard;
