import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "../../../css/style.module.css";
import { customStyles } from "../../styles";

function EventIssueRender({
  item,
  choices,
  handleTypeChange,
  setId,
  fieldType,
}) {
  return (
    <div className={styles.condition_item2}>
      <div>
        from
        <Select
          styles={customStyles}
          options={choices}
          isClearable={true}
          placeholder="old_value"
          className={styles.condition_select2}
          value={choices?.filter((info) => info.value == item?.old_value)}
          onChange={(e) => {
            handleTypeChange(
              "old_value",
              e?.value || "",
              fieldType,
              choices?.find((info) => info?.choices?.length)?.choices?.[0]?.key
            );
          }}
        />
      </div>
      <div>
        to
        <Select
          styles={customStyles}
          options={choices?.map((item) => {
            return { ...item };
          })}
          isClearable={true}
          placeholder="new_value"
          className={styles.condition_select2}
          value={choices?.filter((info) => info.value == item?.new_value)}
          onChange={(e) =>
            handleTypeChange(
              "new_value",
              e?.value || "",
              fieldType,
              choices?.find((info) => info?.choices?.length)?.choices?.[0]?.key
            )
          }
        />
      </div>
    </div>
  );
}

export default EventIssueRender;
