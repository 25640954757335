import React, { useContext, useState } from "react";
import styles from "../../css/style.module.css";
import { NavLink } from "react-router-dom";
import { ReactComponent as Edit } from "../../../../../../assets/Admin/Edit.svg";
import { patch_data } from "../../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../../config";
import { AppContext } from "../../../../../../App";

function GroupDetails({ data, constants }) {
  const appContext = useContext(AppContext);
  let [show, setShow] = useState(false);
  function handleMouseEnter() {
    setShow(true);
  }
  function handleMouseLeave() {
    setShow(false);
  }

  function getValue(value) {
    const ecosystems = constants?.ecosystem;
    const key = Object.keys(ecosystems).find((k) => ecosystems[k] === value);
    return key || "---";
  }

  function handleUpdate(e) {
    e.preventDefault();
    patch_data(
      `${API_URL}/convo/users/group/${data?.id}/v1/`,
      { is_active: e.target.checked },
      appContext,
      true
    );
  }

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.col}>
        <p className={styles.bold}>{data?.name}</p>
      </div>
      <div className={styles.col}>
        <p className={styles.p}>{getValue(data?.ecosystem)}</p>
      </div>
      <div className={styles.col}>
        <p className={styles.p}>
          {data?.working_hours === "" || data?.working_hours === null
            ? "Default"
            : data?.working_hours}
        </p>
      </div>
      <div className={`${styles.incentive_info} ${styles.toggle}`}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={data?.is_active}
            className={styles.hide}
            onChange={handleUpdate}
          />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
      </div>
      <div
        className={styles.col}
        style={{ visibility: show ? "visible" : "hidden" }}
      >
        <NavLink className={styles.btn} to={`/teams/group/edit/${data.id}`}>
          <Edit />
        </NavLink>
      </div>
    </div>
  );
}

export default GroupDetails;
