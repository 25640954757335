// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field_filter__vzXFm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.field_input__Rpfe7 {
  width: 100%;
  border: 0;
  border: 1px solid #cfd7df;
  border-radius: 4px;
  padding: 5px 10px;
  /* padding: 4px 12px 5px; */
  background-color: #fff;
  /* outline: none; */
}
`, "",{"version":3,"sources":["webpack://./src/modules/TicketDetails/css/field.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,SAAS;EACT,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,2BAA2B;EAC3B,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".filter {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.input {\n  width: 100%;\n  border: 0;\n  border: 1px solid #cfd7df;\n  border-radius: 4px;\n  padding: 5px 10px;\n  /* padding: 4px 12px 5px; */\n  background-color: #fff;\n  /* outline: none; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `field_filter__vzXFm`,
	"input": `field_input__Rpfe7`
};
export default ___CSS_LOADER_EXPORT___;
