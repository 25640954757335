import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../../../App";
import { API_URL } from "../../../../../config";
import styles from "./style.module.css";
import BusinessHour from "./components/BusinessHour";
import { get_data } from "../../../../../cruxreactlib/networkhandler";
import { NavLink } from "react-router-dom";

function BusinessHours() {
  const appContext = useContext(AppContext);

  let [data, setData] = useState([]);
  useEffect(
    function () {
      appContext.setTitle("Business Hour");
      get_data(`${API_URL}/convo/users/working/hour/list/v1/`).then(function (
        data
      ) {
        if (data) {
          setData(data?.data);
        }
      });
    },
    [appContext.reload]
  );

  return (
    <>
      <div className={styles.btncontainer}>
        <h2>Business Hours</h2>
        <NavLink className="dark-btn" to="/teams/create/business-hour">
          Add Business Hour
        </NavLink>
      </div>
      <div className={styles.container}>
        {data?.map(function (item, idx) {
          return <BusinessHour data={item} idx={idx} appContext={appContext} />;
        })}
      </div>
    </>
  );
}

export default BusinessHours;
