import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "./components/Ticket";
import { AppContext } from "../../../App";
import { API_URL } from "../../../config";
import {
  get_data,
  post_data_without_reload,
} from "../../../cruxreactlib/networkhandler";

import { ReactComponent as NoFound } from "../../../assets/NoFound.svg";
import UserDetail from "./components/UserDetail";
function UserDetails() {
  const appContext = useContext(AppContext);
  let [loader, setLoader] = useState(true);
  let [page, setPage] = useState(1);
  let [next, setNext] = useState(true);
  let [user, setUser] = useState({});
  let [tickets, setTickets] = useState([]);
  const { user_id } = useParams();
  let navigate = useNavigate();

  useEffect(
    function () {
      appContext.setTitle(`User Tickets`);
      appContext.setPage("user tickets");

      if (user_id) {
        get_user_tickets();
        get_user_details();
      }
    },
    [appContext.reload, user_id]
  );
  function get_user_details() {
    setNext(true);
    get_data(`${API_URL}/convo/customer/${user_id}/v1/`, appContext).then(
      function (data) {
        if (data) {
          setUser(data.data);
          setLoader(false);
        }
      }
    );
  }

  function get_user_tickets() {
    setNext(true);
    post_data_without_reload(
      `${API_URL}/convo/ticket/list/v1/`,
      {
        filters: [{ key: "consumer", value: [user_id], operator: "in" }],
        page: 1,
      },
      appContext
    ).then(function (data) {
      if (data) {
        setNext(data?.has_next);
        setTickets(data.data);
        setPage(data?.page);
      }
    });
  }

  return (
    <div className={styles.home}>
      {loader ? (
        <div className="loader_container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <UserDetail user={user} />
          <div className={styles.container}>
            <div className={styles.tickets} id="scrollableDiv">
              <InfiniteScroll
                dataLength={tickets.length}
                pageStart={1}
                scrollableTarget="scrollableDiv"
                next={function () {
                  post_data_without_reload(
                    `${API_URL}/convo/ticket/list/v1/`,
                    {
                      filters: [
                        { key: "consumer", value: [user_id], operator: "in" },
                      ],
                      page: page,
                    },
                    appContext
                  ).then(function (data) {
                    if (data) {
                      setTickets(tickets.concat(data.data));
                      setNext(data?.has_next);
                      setPage(data?.page);
                    }
                  });
                }}
                hasMore={next}
                loader={<h4 style={{ textAlign: "center" }}>Loading ... </h4>}
                endMessage={
                  tickets.length > 0 ? (
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen all tickets !!</b>
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                <div className={styles.ticket_container}>
                  {tickets.length > 0 ? (
                    tickets.map(function (item, idx) {
                      return <Ticket data={item} key={idx} idx={idx} />;
                    })
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <div className={styles.no_found_wrapper}>
                        <NoFound />
                        <p className={styles.no_found_text}>
                          No Ticket Found for this user.
                        </p>
                      </div>
                    </p>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserDetails;
