import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import Navbar from "./components/Navbar";
import styles from "./css/style.module.css";
import User from "./components/User";
import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as NoFound } from "../../assets/NoFound.svg";
function Users() {
  const appContext = useContext(AppContext);

  let [query, setQuery] = useState("");
  let [loader, setLoader] = useState(true);
  const [users, setUsers] = useState([]);
  const [next, setNext] = useState(false);
  const [page, setPage] = useState(1);

  const context = { query, setQuery };

  useEffect(
    function () {
      appContext.setTitle("Users");
      get_all_users();
    },
    [appContext.reload, query]
  );

  function get_all_users() {
    get_data(
      `${API_URL}/convo/customer/search/v1/?query_str=${query}&page=1`
    ).then((data) => {
      if (data) {
        setUsers(data?.data);
        setLoader(false);
        setPage(data?.page);
        setNext(data?.has_next);
      }
    });
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <Navbar context={context} />

      <div className={` ${styles.container}`}>
        <div className={styles.header_wrapper}>
          <div className={styles.header}>
            <p className={styles.heading}>Name</p>
            <p className={styles.heading}>Email</p>
            <p className={styles.heading}>Facebook ID</p>
            <p className={styles.heading}>Twitter ID</p>
            <p className={styles.heading}>Phone</p>{" "}
          </div>
          <div style={{ flex: "0.1", visibility: "hidden" }}>
            <p className={styles.heading}>Phone</p>
          </div>
        </div>
        <div className={styles.user_container} id="scrollableDiv">
          <InfiniteScroll
            dataLength={users.length}
            pageStart={1}
            scrollableTarget="scrollableDiv"
            next={function () {
              get_data(
                `${API_URL}/convo/customer/search/v1/?query_str=${query}&page=${page}`,
                appContext
              ).then(function (data) {
                if (data) {
                  setUsers(users.concat(data.data));
                  setNext(data?.has_next);
                  setPage(data?.page);
                }
              });
            }}
            hasMore={next}
            loader={<h4 style={{ textAlign: "center" }}>Loading ... </h4>}
            endMessage={
              users.length > 0 ? (
                <p style={{ textAlign: "center", marginTop: "1vh" }}>
                  <b>Yay! You have seen all users !!</b>
                </p>
              ) : (
                <></>
              )
            }
          >
            <div className={styles.user_wrapper_container}>
              {users?.length > 0 ? (
                users?.map((item, idx) => <User data={item} key={idx} />)
              ) : (
                <h1 className={styles.no_user}>
                  <NoFound />
                  <p className={styles.no_user_text}>No Users found</p>
                </h1>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
}

export default Users;
