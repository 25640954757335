import React, { useState, useEffect } from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import IssueComponent from "./IssueComponent";
import EventBoolean from "../../FieldComponents/EventBoolean";
import EventText from "../../FieldComponents/EventText";
import { customStyles } from "../../../components/styles";
function Events({
  item,
  event,
  idx,
  selectedOption,
  setEvent,
  automationData,
  edit,
}) {
  // State for hover effect
  const [hover, setHover] = useState(false);

  // Options for the ticket type dropdown
  const ticketOptions = [
    { label: "Ticket", value: "Tickets" },
    { label: "Creation", value: "Creation" },
  ];

  // State to manage hiding/showing additional options
  const [hide, setHide] = useState(false);

  // State to manage field type
  const [fieldType, setFieldType] = useState("");

  // State to store constants mapping and ticket fields
  const [constantsMapping, setConstantsMapping] = useState({});
  const [ticketFields, setTicketFields] = useState([]);

  // State to manage issue IDs and choices
  const [issueId, setIssueId] = useState(0);
  const [subIssueId, setSubIssueId] = useState(0);
  const [issueChoices, setIssueChoices] = useState([]);
  const [subIssueChoices, setSubIssueChoices] = useState([]);
  const [furtherChoices, setFurtherChoices] = useState([]);

  // State to manage loader
  const [loader, setLoader] = useState(true);

  // Options for "Any Value" and "None"
  let otherConstant = [
    {
      label: "Any Value",
      value: "_=_=_=",
    },
    {
      label: "None",
      value: "",
    },
  ];

  useEffect(() => {
    // Set constants and ticket fields when automation data changes
    setConstantsMapping(automationData?.constants);
    setTicketFields(automationData?.ticketFields);

    // Find automation data for the selected key
    const automation_data = automationData?.ticketFields?.find(
      (info) => info.key == item?.key
    );

    // Set field type and choices
    setFieldType(automation_data?.field_type || "");
    if (automation_data) {
      let d = automation_data?.choices || [];
      d = d?.map((info) => ({ ...info, value: info?.value || info?.label }));
      setIssueChoices([...otherConstant, ...d]);
    }

    // Set loader to false
    setLoader(false);
  }, [item, automationData]);

  // Function to handle event deletion
  function handleEventDelete() {
    let propertyData = event;
    let properyArr = propertyData.properties;
    properyArr.splice(idx, 1);
    propertyData.properties = properyArr;
    event = propertyData;
    setEvent({ ...event });
  }

  // Function to handle type change for events
  function handleTypeChange(label, value, field_type, key) {
    let data = { ...item, [label]: value };

    // Reset old and new values for dependent fields
    if (
      label == "key" &&
      (field_type == "dependent" || field_type == "dropdown")
    ) {
      data.old_value = "_=_=_=";
      data.new_value = "_=_=_=";
    }

    // Handle property nullification based on old and new values
    if (
      (!data.old_value || data.old_value == "_=_=_=") &&
      (!data.new_value || data.new_value == "_=_=_=")
    ) {
      data.property = null;
    }

    // Handle property setting for old and new values
    if (
      (label == "old_value" || label == "new_value") &&
      field_type == "dependent"
    ) {
      if (!data?.property) {
        if (data?.old_value && data?.old_value != "_=_=_=") {
          if (!data?.property) {
            data.property = {};
          }
          data.property.old_value = "_=_=_=";
        }
        if (data?.new_value && data?.new_value != "_=_=_=") {
          if (!data?.property) {
            data.property = {};
          }
          data.property.new_value = "_=_=_=";
        }

        if (data?.property?.new_value || data?.property?.old_value) {
          if (!data?.property) {
            data.property = {};
          }

          data.property.key = key;
        }
      } else {
        if (!data?.property?.old_value && !data?.property?.new_value) {
          data.property = null;
        } else {
          data.property = { ...data.property, [label]: "_=_=_=" };
        }
      }
    }

    let conditionData = event;
    conditionData.properties[idx] = data;
    event = conditionData;
    setEvent({ ...event });
  }

  // Function to render different event components based on field type
  function RenderEventComponents() {
    if (fieldType == "dependent") {
      return (
        <IssueComponent
          issueChoices={issueChoices}
          subIssueChoices={subIssueChoices}
          furtherChoices={furtherChoices}
          setIssueId={setIssueId}
          setSubIssueId={setSubIssueId}
          item={item}
          handleTypeChange={handleTypeChange}
          event={event}
          setEvent={setEvent}
          automationData
          idx={idx}
          fieldType={fieldType}
          check={fieldType != "dropdown"}
        />
      );
    } else if (fieldType == "dropdown") {
      return (
        <IssueComponent
          issueChoices={issueChoices}
          subIssueChoices={subIssueChoices}
          furtherChoices={furtherChoices}
          setIssueId={setIssueId}
          setSubIssueId={setSubIssueId}
          item={item}
          handleTypeChange={handleTypeChange}
          event={event}
          setEvent={setEvent}
          automationData
          fieldType={fieldType}
          idx={idx}
        />
      );
    } else if (fieldType == "boolean") {
      return (
        <EventBoolean
          item={item}
          callbackfn={handleTypeChange}
          fieldType={fieldType}
        />
      );
    } else if (fieldType == "integer") {
      return (
        <EventText
          item={item}
          callbackfn={handleTypeChange}
          number={true}
          fieldType={fieldType}
        />
      );
    } else {
      return (
        <EventText
          item={item}
          callbackfn={handleTypeChange}
          fieldType={fieldType}
        />
      );
    }
  }

  return (
    <div
      className={styles.condition_box}
      key={idx}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.delete_wrapper}>
        <div className={styles.action_delete}>
          <div className={styles.wrapper}>
            {!hide ? (
              <div className={styles.open_wrapper}>
                <div className={styles.arrow_wrapper}>
                  {/* Dropdown for selecting event type */}
                  <Select
                    styles={customStyles}
                    options={ticketFields
                      ?.filter((info) => info.parent_field == "")
                      ?.map((info) => {
                        return {
                          ...info,
                          label: `${info.label} is changed`,
                          value: info.key,
                        };
                      })}
                    placeholder="Choose Event"
                    className={styles.condition_select1}
                    onChange={(e) => {
                      item.old_value = null;
                      item.new_value = null;
                      setFieldType(e?.field_type);
                      handleTypeChange("key", e?.value, e?.field_type);
                    }}
                    value={ticketFields?.filter(
                      (info) => info.key == item?.key
                    )}
                  />
                </div>
                {fieldType && (
                  <div className={styles.arrow_wrapper2}>
                    {RenderEventComponents()}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.open_wrapper}>
                {/* Show key as disabled if hide is true */}
                <Select
                  styles={customStyles}
                  options={ticketFields?.map((info) => {
                    return { ...info, value: info.key };
                  })}
                  placeholder="key"
                  className={styles.condition_select1}
                  value={ticketFields?.filter((info) => info.key == item?.key)}
                  isDisabled={true}
                />
              </div>
            )}
            <div>
              {!hide ? (
                <span
                  className={styles.wrapper_span}
                  onClick={() => setHide(!hide)}
                >
                  <IoMdArrowDropdown />
                </span>
              ) : (
                <span
                  className={styles.wrapper_span}
                  onClick={() => setHide(!hide)}
                >
                  <IoMdArrowDropup />
                </span>
              )}
            </div>
          </div>
        </div>
        {event?.properties?.length == 1 && idx == 0
          ? null
          : hover && (
              <span
                className={styles.delete_icon}
                onClick={() => handleEventDelete()}
              >
                <FaTrash />
              </span>
            )}
      </div>

      {event?.properties?.length > 1 && idx != 0 && (
        <span className={styles.operator}>
          {selectedOption == "any" ? "or" : "and"}
        </span>
      )}
    </div>
  );
}

export default Events;
