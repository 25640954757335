import React, { useContext, useEffect, useState } from "react";
import styles from "./style.module.css";
import { AppContext } from "../../App";
import { ReactComponent as Profile } from "../../assets/Profile.svg";
import SideBar from "./components/SideBar";
import Search from "./components/Search";
import { NavLink } from "react-router-dom";
import FollowUpSideBar from "./components/FollowUpSideBar";
import Label from "../../cruxreactlib/utils/Label";
import SwitchHeader from "../../cruxreactlib/SwitchHeader";
import ToggleButton from "../ToggleButton";
import { get_agent_id } from "../../cruxreactlib/auth";
import { ReactComponent as Logout } from "../../assets/drawer/icon/Logout.svg";
import { navigateLogin } from "../../cruxreactlib/networkhandler";

function Header() {
  const appContext = useContext(AppContext);
  const [breakOptions, setBreakOptions] = useState([]);

  let [show, setShow] = useState(false);
  let [showFollowUp, setShowFollowUp] = useState(false);
  let agent_id = get_agent_id();

  useEffect(() => {
    setBreakOptions(convertToOptions(appContext?.ticketFields?.config?.breaks));
  }, [appContext.reload, appContext.ticketFields]);

  function handleClick() {
    setShow(!show);
  }
  function handleFollowUpClick() {
    setShowFollowUp(!showFollowUp);
  }

  function convertToOptions(data) {
    return data?.map((item) => {
      const value = item.replace(" ", "");
      return { label: item, value };
    });
  }

  function handleLogout() {
    localStorage.clear();
    navigateLogin();
  }

  return (
    <header className={styles.header}>
      <div className={styles.subheader}>
        <Label name={appContext.title} className={styles.ticket_info} />
      </div>
      <div className={styles.subheader}>
        <NavLink className="btn zIndex0" to={"/create-ticket"}>
          Create Ticket
        </NavLink>
        {/* <button className="btn zIndex0" onClick={handleFollowUpClick}>
          Follow Ups
        </button> */}
        <ToggleButton
          agent_id={agent_id}
          isAgent={true}
          breakOptions={breakOptions}
        />
        <Search className={styles.search} />
        <span className="logout_btn" onClick={handleLogout}>
          <Logout />
        </span>
        {/* <SwitchHeader /> */}
        {/* <Profile onClick={handleClick} className={styles.profile} /> */}
        {show && <SideBar show={show} setShow={setShow} />}
        {showFollowUp && (
          <FollowUpSideBar show={showFollowUp} setShow={setShowFollowUp} />
        )}
      </div>
    </header>
  );
}

export default Header;
