import React, { useRef, useState } from "react";
import styles from "../../../css/sla.module.css";

function SlaInput({ idx, payload, setPayload, name, value }) {
  const [show, setShow] = useState(false);
  const switchRef = useRef(null);

  function handleChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    let { value } = e.target;
    if (value !== "") {
      value = parseInt(value);
    }
    setPayload({ ...payload, [e.target.name]: value });
  }
  return (
    <div className={styles.sla_item_flex} key={idx}>
      {show ? (
        <div className={styles.sla_input_flex}>
          <div className={styles.sla_div}>
            <input className={styles.sla_input1} />
            <small>hrs</small>
          </div>
          <div className={styles.sla_div}>
            <input className={styles.sla_input1} />
            <small>mins</small>
          </div>
          <div className={styles.sla_div}>
            <input className={styles.sla_input1} />
            <small>sec</small>
          </div>
        </div>
      ) : (
        <input
          ref={switchRef}
          className={styles.sla_input}
          name={name}
          value={value}
          // onClick={() => setShow(true)}
          placeholder="Enter Time Span "
          onChange={handleChange}
        />
      )}
    </div>
  );
}

export default SlaInput;
