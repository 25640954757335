import React, { useEffect } from "react";
import { navigateLogin } from "../../cruxreactlib/networkhandler";

function Login() {
  useEffect(() => {
    // Get the base URL
    navigateLogin();
  }, []);

  return <></>;
}

export default Login;
