import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../App";
import { nav_data } from "./NavData";
import LinkItem from "./LinkItem";
import styles from "./style.module.css";
import { ReactComponent as TfiHeadphoneAlt } from "../../assets/drawer/icon/Tf-phone.svg";
import { ReactComponent as OtipySmallLogo } from "../../assets/Otipy.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { authorise } from "../../cruxreactlib/auth";
import Tooltip from "../../cruxreactlib/utils/ToolTip";
function Drawer(props) {
  const appContext = useContext(AppContext);
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <div className={`${props.className} ${styles.drawer}`} open={showSidebar}>
        <div className={styles.drawer_panel}>
          <NavLink
            className={styles.logo}
            to={"/home"}
            style={{ justifyContent: "center" }}
          >
            <Logo />
          </NavLink>
          {nav_data?.map(function (item, idx) {
            return authorise(item.role) && <LinkItem data={item} key={idx} />;
          })}
        </div>
        <div className={styles.drawer_tooltip}>
          <Tooltip text="Dialler CTI">
            <span
              className={styles.drawer_tab}
              onClick={appContext.toggleDialler}
            >
              <TfiHeadphoneAlt />
            </span>
          </Tooltip>
          {/* <Tooltip text="Break">
            <span
              className={styles.drawer_tab}
              onClick={appContext.toggleUsersBreak}
            >
              <UsersBreak />
            </span>
          </Tooltip> */}
        </div>
      </div>
    </>
  );
}

export default Drawer;
