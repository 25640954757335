import React, { Suspense, useContext } from "react";
import { isAgentLoggedIn } from "../../../cruxreactlib/auth";
import { Navigate, Outlet } from "react-router-dom";
import AnalyticsHeader from "../../../components/AnalyticsHeader";
import { AppContext } from "../../../App";

function AnalyticsRoute() {
  const appContext = useContext(AppContext);
  return isAgentLoggedIn() ? (
    <div className="teams_height">
      <AnalyticsHeader
        showContent={appContext.showContent}
        setShowContent={appContext.setShowContent}
      />
      <Suspense
        fallback={
          <div className="loader_container">
            <div className="loader"></div>
          </div>
        }
      >
        <div className="route_height">
          <Outlet />
        </div>
      </Suspense>
    </div>
  ) : (
    <Navigate to="/validate" replace={true} />
  );
}

export default AnalyticsRoute;
