import React, { useContext, useEffect, useState } from "react";
import JoditEditor from "jodit-react";
import { API_URL } from "../../../config";
import { post_data } from "../../../cruxreactlib/networkhandler";
import Popup from "../../../cruxreactlib/utils/Popup/index";
import { AppContext } from "../../../App";
import styles from "../css/popup.module.css";
function CreateArticle({ cluster, setCluster, allClusters, setIsOpen }) {
  let [change, setChange] = useState({});
  let [close, setClose] = useState(false);

  const appContext = useContext(AppContext);

  useEffect(function () {
    setClose(false);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    let payload = change;
    payload["cluster"] = cluster;

    post_data(
      `${API_URL}/convo/solution/article/v1/`,
      payload,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setClose(true);
        setIsOpen(false);
      }
    });
  }

  function changeCluster(e) {
    setCluster(e.target.value);
  }

  return (
    <Popup
      btnName={"New Article"}
      btnStyling={styles.optionButton}
      closeState={close}
    >
      <h1 className={styles.heading}>Create Article</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div
          className={`${styles.input_sub_container} ${styles.text_cntainer}`}
        >
          <div>
            <label className={styles.label}>Title</label>
            <input
              type="text"
              placeholder="Enter Article Title"
              value={change.title}
              className={styles.input}
              required
              onChange={function (e) {
                setChange({ ...change, title: e.target.value });
              }}
            />
          </div>
          <div>
            <label className={styles.label}>Select Cluster</label>
            <select className="select" value={cluster} onChange={changeCluster}>
              {allClusters.map(function (item, idx) {
                return (
                  <option className="option" value={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <JoditEditor
          value={change.description}
          tabIndex={1} // tabIndex of textarea
          onChange={(newContent) => {
            setChange({ ...change, description: newContent });
          }}
        />

        <div className={`${styles.input_container} ${styles.submit_container}`}>
          <input className={styles.submit} type="submit" />
        </div>
      </form>
    </Popup>
  );
}

export default CreateArticle;
