// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_select_container__kYwGT {
  width: 280px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.style_select_option__iDIkL {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/modules/UsersBreak/components/SelectBreak/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,SAAO;AACT","sourcesContent":[".select_container {\n  width: 280px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.select_option {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_container": `style_select_container__kYwGT`,
	"select_option": `style_select_option__iDIkL`
};
export default ___CSS_LOADER_EXPORT___;
