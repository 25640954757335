import { ReactComponent as Home } from "../../assets/drawer/icon/Home.svg";
import { ReactComponent as SmartAssign } from "../../assets/drawer/icon/Smart-assign.svg";
import { ReactComponent as Admin } from "../../assets/drawer/icon/admin.svg";
import { ReactComponent as Dialler } from "../../assets/drawer/icon/Dialler.svg";
import { ReactComponent as DiallerLogs } from "../../assets/drawer/icon/call_logs.svg";
import { ReactComponent as Users } from "../../assets/drawer/icon/users.svg";
import { ReactComponent as Cluster } from "../../assets/drawer/icon/cluster.svg";
import { ReactComponent as UsersBreak } from "../../assets/drawer/icon/break.svg";
import { ReactComponent as RTM } from "../../assets/drawer/icon/RTM.svg";
import { ReactComponent as Whatsapp } from "../../assets/drawer/icon/Whatsapp.svg";

const nav_data = [
  {
    path: "/home",
    title: "Home",
    icon: <Home />,
    activeicon: <Home />,
    children: [],
  },
  {
    path: "/rtm",
    title: "RTM",
    icon: <RTM />,
    activeicon: <RTM />,
    children: [],
  },
  {
    path: "/whatsapp",
    title: "Whatsapp",
    icon: <Whatsapp />,
    activeicon: <Whatsapp />,
    children: [],
  },
  {
    path: "/diallers",
    title: "Dialler",
    icon: <Dialler />,
    activeicon: <Dialler />,
    children: [],
  },
  {
    path: "/dialler/logs",
    title: "Logs",
    icon: <DiallerLogs />,
    activeicon: <DiallerLogs />,
    children: [],
  },
  {
    path: "/users",
    title: "Users",
    icon: <Users />,
    activeicon: <Users />,
    children: [],
  },
  {
    path: "/user/break",
    title: "Users Break",
    icon: <UsersBreak />,
    activeicon: <UsersBreak />,
    children: [],
  },
  {
    path: "/smart-assign",
    title: "Smart Assign",
    icon: <SmartAssign />,
    activeicon: <SmartAssign />,
    children: [],
  },
  {
    path: "/clusters",
    title: "Clusters",
    icon: <Cluster />,
    activeicon: <Cluster />,
    children: [],
  },
  {
    path: "/admin",
    title: "Admin",
    icon: <Admin />,
    activeicon: <Admin />,
    children: [],
  },
];

export { nav_data };
