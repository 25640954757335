import React, { useContext, useState } from "react";
import styles from "./style.module.css";
import { AppContext } from "../../../../App";
import AddNote from "../AddNote";
import { delete_data } from "../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../config";
import { useNavigate } from "react-router-dom";
import Merge from "../Merge";
import FollowUp from "../FollowUp";
import SideBar from "./components/SideBar";
import ExceptionFlow from "../ExceptionFlow";
import AiNote from "../AiNote";

function Navbar({
  ticket_id,
  showActivity,
  setShowActivity,
  cannedResponses,
  user_id,
  phone,
  group_id,
}) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  function handleDelete() {
    delete_data(
      `${API_URL}/convo/ticket/${ticket_id}/v1/`,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        navigate("/home");
      }
    });
  }

  function toggle() {
    setShowActivity(!showActivity);
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.subnav}>
        <button className="btn" onClick={toggle}>
          {showActivity ? "Hide " : "Show "}Activity{" "}
        </button>
        <AddNote ticket_id={ticket_id} cannedResponses={cannedResponses} />
        {/* <AiNote /> */}
        {/* <FollowUp ticket_id={ticket_id} group_id={group_id} /> */}
        {/* <Merge ticket_id={ticket_id} user_id={user_id} /> */}
        <button className="btn" onClick={handleDelete}>
          Delete
        </button>
        {/* <ExceptionFlow phone={phone} appContext={appContext} /> */}
      </div>
    </nav>
  );
}

export default Navbar;
