import React, { useContext, useEffect, useState } from "react";
import styles from "../css/weekly.module.css";
import TotalTickets from "../components/TotalTickets";
import SlaTickets from "../components/SlaTickets";
import AvgResolutionTime from "../components/AvgResolutionTime";
import NPS from "../components/NPS";

function WeeklyDetails({ chartData }) {
  return (
    <div className={styles.container}>
      <div className={styles.weekly_details_box}>
        <div className={styles.chart_box}>
          <div className={styles.chart}>
            <TotalTickets
              chartData={chartData?.details.tickets}
              tickets_ct={chartData?.ticket_ct}
              infoData={chartData}
            />
          </div>
          <div className={styles.chart}>
            <SlaTickets
              chartData={chartData?.details.resolve_within_sla}
              resolve_within_sla={chartData?.resolve_within_sla}
              infoData={chartData}
            />
          </div>
        </div>
        <div className={styles.chart_box}>
          <div className={styles.chart}>
            <AvgResolutionTime
              infoData={chartData}
              chartData={chartData?.details.avg_resolution_time}
              avg_resolution_time={chartData?.avg_resolution_time}
              title="Avg. resolution "
              color="#18FB73"
              gradientToColors={["#18FB73", "#18FB73"]}
            />
          </div>
          <div className={styles.chart}>
            {/* <NPS
              chartData={chartData?.details.nps}
              nps_value={chartData?.nps}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeeklyDetails;
