import { API_URL } from "../../../../../config";
import { get_data } from "../../../../../cruxreactlib/networkhandler";

export async function getTicketFields(appContext) {
  const constant_data = await get_data(
    `${API_URL}/convo/automation/constants/v1/`,
    appContext
  );

  const ticket_fields_data = appContext.ticketFields;
  const ticketFields = ticket_fields_data?.config?.ticket_fields;
  const fieldDict = ticket_fields_data?.field_dict;

  let automation_data = {
    ticketFields: ticketFields,
    constants: constant_data?.data,
    fieldDict: fieldDict,
  };

  return automation_data;
}
