import React, { useContext, useEffect, useState } from "react";
import styles from "./css/dashboard.module.css";
import { AppContext } from "../../../../App";
import Break from "./Components";
import BreaksSidebar from "./Components/BreaksSidebar";
function Breaks() {
  const appContext = useContext(AppContext);
  const [breakData, setBreakData] = useState({}); //store ticket data
  const [key, setKey] = useState(0); //unique index state

  //effect on reload
  useEffect(() => {
    appContext.setTitle("Breaks");
  }, [appContext.reload]);

  //handle add new ticket
  function handleTicketItem() {
    if (breakData?.isNew) {
      return;
    }

    let id = key;
    setBreakData({
      uid: id,
      isNew: true,
    });
    id++;
    setKey(id);
  }

  return (
    <div className={styles.break_wrapper}>
      <div className={styles.ticket_container}>
        <Break
          breakData={breakData}
          setBreakData={setBreakData}
          callbackfn={handleTicketItem}
          appContext={appContext}
        />
      </div>
      <div className={styles.break_sidebar}>
        <BreaksSidebar />
      </div>
    </div>
  );
}

export default Breaks;
