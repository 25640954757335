import React, { useEffect, useState } from "react";
import styles from "../css/role.module.css";

function PermissionsList({
  setSelectedPermissionIds,
  selectedPermissionIds,
  data,
}) {
  function handleChange(e, permissionId) {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedPermissionIds((prevIds) => [...prevIds, permissionId]);
    } else {
      setSelectedPermissionIds((prevIds) =>
        prevIds.filter((id) => id !== permissionId)
      );
    }
  }

  function handlePolicyChange(e, permissionIds) {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedPermissionIds((prevIds) => [
        ...prevIds,
        ...permissionIds.filter((id) => !prevIds.includes(id)),
      ]);
    } else {
      setSelectedPermissionIds((prevIds) =>
        prevIds.filter((id) => !permissionIds.includes(id))
      );
    }
  }

  useEffect(
    function () {
      console.log("Selected Permission IDs:", selectedPermissionIds);
    },
    [selectedPermissionIds]
  );

  return (
    <>
      {data?.map(function (policy, policyIndex) {
        const permissionIds = policy?.permissions?.map((item) => item?.id);
        const isPolicyChecked =
          permissionIds?.every((id) => selectedPermissionIds?.includes(id)) &&
          permissionIds?.length > 0;

        return (
          <div className={styles.policy_wrapper} key={policyIndex}>
            <div className={styles.policy_details}>
              <input
                type="checkbox"
                onChange={(e) => handlePolicyChange(e, permissionIds)}
                checked={isPolicyChecked}
                className={styles.checkbox}
              />
              <p className={styles.policy_text}>{policy?.name}</p>
            </div>
            <div className={styles.permissions_container}>
              {policy?.permissions?.map(function (item, idx) {
                return (
                  <div key={idx} className={styles.permissions_outer_wrapper}>
                    <div className={styles.permissions_wrapper}>
                      <input
                        type="checkbox"
                        onChange={(e) => handleChange(e, item?.id)}
                        checked={selectedPermissionIds.includes(item.id)}
                        className={styles.checkbox}
                      />
                      <p className={styles.permission_text}>{item?.name}</p>
                    </div>
                    <p className={styles.permission_desc}>
                      {item?.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default PermissionsList;
