import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../../App";
import { API_URL } from "../../../config";
import { post_data } from "../../../cruxreactlib/networkhandler";
import styles from "../css/style.module.css";
import { ReactComponent as SuccessIcon } from "../../../assets/Dialler/Success.svg";
import { ReactComponent as FailureIcon } from "../../../assets/Dialler/Failure.svg";
import { useNavigate } from "react-router-dom";

function CsvUpload() {
  let [csvData, setCsvData] = useState(null);
  let [uploadStatus, setUploadStatus] = useState(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      appContext.setAlert("No file selected", "alert_error");
      return;
    }
    const allowedExtensions = ["csv"];
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      appContext.setAlert(
        "Invalid file type. Please select a CSV file.",
        "alert_error"
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      parseCsv(content);
    };

    reader.readAsText(file);
  };

  const parseCsv = (content) => {
    const lines = content.split("\n");
    const headers = lines[0].split(",");

    // Trim whitespace from column names
    const trimColumnNames = (columns) => columns.map((column) => column.trim());

    // Check if mandatory columns are present
    const mandatoryColumns = trimColumnNames(["Subject", "Campaign", "Phone"]);
    const csvColumns = trimColumnNames(headers);
    const missingColumns = mandatoryColumns.filter(
      (column) => !csvColumns.includes(column)
    );

    if (missingColumns.length > 0) {
      appContext.setAlert(
        `Missing mandatory columns: ${missingColumns.join(", ")}`,
        "alert_error"
      );
      setUploadStatus("failure");
      return;
    }

    // Process CSV data, filter out empty rows
    const data = lines
      .filter((line) => line.trim() !== "") // Filter out empty rows
      .slice(1) // Skip headers
      .map((line) => {
        const values = line.split(",");
        // Map values to columns based on the header order
        const rowData = {};
        csvColumns.forEach((column, index) => {
          rowData[column.toLowerCase()] = values[index].trim(); // Convert key to lowercase
        });

        return rowData;
      });

    setCsvData(data);
    handleCustomSubmit(data);
    inputRef.current.value = null;
  };

  const handleCustomSubmit = (data) => {
    let isValid = true;
    let msg = "";

    data?.forEach((item, idx) => {
      if (item["subject"] === "") {
        isValid = false;
        msg = `Please check Subject value in the uploaded CSV at line ${
          idx + 2
        }`;
      }
      if (item["campaign"] === "") {
        isValid = false;
        msg = `Please check Campaign value in the uploaded CSV at line ${
          idx + 2
        }`;
      }
      if (item["phone"] === "") {
        isValid = false;
        msg = `Please check Phone value in the uploaded CSV at line ${idx + 2}`;
      }
    });

    if (!isValid) {
      appContext.setAlert(msg, "alert_error");
      return;
    }
    let body = { data: data };
    post_data(
      `${API_URL}/convo/ticket/dialler/create/bulk/v1/`,
      body,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setUploadStatus("success");
        navigate("/dialler/logs");
      }
    });
  };

  return (
    <>
      <div className={styles.up_wrapper}>
        <label
          htmlFor={"dialler"}
          className="btn"
        >{`Upload Dialler CSV`}</label>
        <input
          id={"dialler"}
          className="upload"
          name={`upload_${"dialler".toLowerCase()}`}
          type="file"
          style={{ display: "none" }}
          accept=".csv"
          ref={inputRef}
          onChange={handleFileUpload}
        />
      </div>
      {uploadStatus === "success" && <SuccessIcon />}
      {uploadStatus === "failure" && <FailureIcon />}
    </>
  );
}

export default CsvUpload;
