import React, { useContext, useEffect, useState } from "react";
import styles from "../css/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../../App.js";
import {
  get_data,
  patch_data,
} from "../../../../../cruxreactlib/networkhandler";
import { getTicketFields } from "../components/Constants.js";
import { API_URL } from "../../../../../config";
import EventList from "../components/EventList/index";
import Actions from "../components/Actions/index";
import ConditionList from "../components/ConditionList/index.js";
import AutomationSidebar from "../components/AutomationSidebar.js";

function EditRule() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [automationData, setAutomationData] = useState([]);
  const appContext = useContext(AppContext);
  const [conditions, setConditions] = useState({
    match_type: "any",
    properties: [{}],
  });
  const [event, setEvent] = useState({
    match_type: "any",
    properties: [{}],
  });
  const [countEvents, setCountEvents] = useState([1]);
  const [actions, setActions] = useState([]);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(false);
  const [payload, setPayload] = useState({});
  const [filterType, setFilterType] = useState("or");
  const [countConditions, setCountConditions] = useState([1]);

  useEffect(() => {
    fetchTicketConstants();
    fetchAutomationData();
  }, [appContext.reload, appContext.ticketFields]);

  // Fetch ticket constants for automation
  async function fetchTicketConstants() {
    const automation_data = await getTicketFields(appContext);
    setAutomationData(automation_data);
  }

  // Fetch automation data based on ID
  async function fetchAutomationData() {
    const data = await get_data(
      `${API_URL}/convo/automation/${id}/v1/`,
      appContext
    );

    if (data) {
      let automationData = data?.data;
      setPayload(data?.data);
      let uuid = key;
      let actionData = automationData?.actions;
      let filterActionData = actionData?.map((item) => {
        uuid++;
        if (item?.webhook && item?.properties) {
          return { ...item, type: item?.type, uid: uuid };
        } else if (item?.webhook) {
          return { webhook: item?.webhook, type: item?.type, uid: uuid };
        } else {
          return { properties: item?.properties, type: item?.type, uid: uuid };
        }
      });
      setKey(uuid);
      setActions(filterActionData);

      if (automationData?.conditions?.properties?.length == 0) {
        setConditions({ match_type: "any", properties: [{}] });
      } else {
        setConditions(automationData?.conditions);
      }

      if (automationData?.events?.properties?.length == 0) {
        setEvent({ match_type: "any", properties: [{}] });
      } else {
        setEvent(automationData?.events);
      }

      setFilterType(automationData?.condition_type);
      if (automationData?.conditions?.length > 1) {
        setCountConditions([...countConditions, 2]);
      }
    }
  }

  // Handling action validation
  function handleActionValidation() {
    if (actions.length === 0) {
      return true;
    }
    for (let i = 0; i < actions.length; i++) {
      let item = actions[i];
      if (item?.type === "property") {
        if (
          !item.properties ||
          item.properties.value === undefined ||
          !item.properties.key
        ) {
          appContext.setAlert("fill previous action", "alert_error");
          return false;
        }
      } else if (item?.type === "webhook") {
        if (!item.webhook || !item.webhook.method) {
          appContext.setAlert("fill previous action", "alert_error");
          return false;
        }
      } else {
        appContext.setAlert("fill previous action", "alert_error");
        return false;
      }
    }
    return true;
  }

  // Handling new action
  function handleAddAction() {
    let checkAction = handleActionValidation();
    if (!checkAction) {
      return;
    }
    let uuid = key;
    setActions([...actions, { uid: uuid + 1 }]);
    setKey(uuid + 1);
  }

  // Handling condition validation
  function handleAddConditionValidation(data) {
    if (!data.match_type) {
      return false;
    }
    for (let i = 0; data?.properties && i < data.properties.length; i++) {
      let info = data.properties[i];

      if (!info.operator || !info.key || !info.value) {
        appContext.setAlert("fill previous condition", "alert_error");
        return false;
      }
    }
    return true;
  }

  // Handling event validation
  function handleAddEventValidation(data) {
    if (!data.match_type) {
      return false;
    }
    for (let i = 0; data?.properties && i < data.properties.length; i++) {
      let info = data.properties[i];
      if (!info.key || !info.old_value || !info.new_value) {
        appContext.setAlert("fill previous event", "alert_error");
        return false;
      }
    }
    return true;
  }

  // Handling event data change
  function handleEventData(type) {
    console.log("update");
    let info = event;
    info = { ...info, match_type: type };
    setEvent({ ...info });
  }

  // Handling new event
  function handleAddEvent(idx, matchType) {
    let info = event;

    let checkAction = handleAddEventValidation(info);
    if (!checkAction) {
      return;
    }
    info = { match_type: matchType, properties: [...info.properties, {}] };
    setEvent({ ...info });
  }

  // Handling new condition
  function handleAddCondition(idx, matchType) {
    let info = conditions;
    let checkAction = handleAddConditionValidation(info);
    if (!checkAction) {
      return;
    }
    info = { match_type: matchType, properties: [...info.properties, {}] };
    setConditions({ ...info });
  }

  // Handling form submission
  function handleSubmit(e) {
    e.preventDefault();

    let finalPayload = {
      name: payload?.name,
      actions: actions,
      conditions: conditions,
    };

    if (payload.description) {
      finalPayload.description = payload.description;
    }

    if (payload?.type === "updation") {
      finalPayload.events = event;
    }
    setPayload({
      ...finalPayload,
    });

    checkValidationErrors(finalPayload);
  }

  // Cleaning payload from null or undefined values
  function cleanPayload(payload) {
    if (payload === null || payload === undefined) {
      return null;
    }

    if (Array.isArray(payload)) {
      return payload.map((item) => cleanPayload(item));
    }

    if (typeof payload === "object") {
      const cleanedObj = {};

      for (const key in payload) {
        const cleanedValue = cleanPayload(payload[key]);

        if (cleanedValue !== null) {
          cleanedObj[key] = cleanedValue;
        }
      }

      // Special handling for 'actions', 'conditions', and 'events'
      if (cleanedObj.actions) {
        cleanedObj.actions = cleanedObj.actions.map((action) =>
          cleanPayload(action)
        );
      }

      if (cleanedObj.conditions) {
        cleanedObj.conditions = cleanPayload(cleanedObj.conditions);
      }

      if (cleanedObj.events) {
        cleanedObj.events = cleanPayload(cleanedObj.events);
      }

      return Object.keys(cleanedObj).length > 0 ? cleanedObj : null;
    }

    return payload;
  }

  // Checking validation errors and updating automation
  function checkValidationErrors(final_payload) {
    const finalData = cleanPayload(final_payload);

    if (finalData.event && finalData?.events?.properties?.[0] == null) {
      finalData.event.properties = [];
    }
    if (
      finalData.conditions &&
      finalData?.conditions?.properties?.[0] == null
    ) {
      finalData.conditions.properties = [];
    }

    handleAutomationUpdate(finalData);
  }

  // Handling automation update
  async function handleAutomationUpdate(ticket_payload) {
    const data = await patch_data(
      `${API_URL}/convo/automation/${id}/v1/`,
      ticket_payload,
      appContext,
      true
    );

    if (data) {
      navigate("/workflows/automation/dashboard");
    }
  }

  // Handling condition data change
  function handleConditionData(type, idx) {
    let info = conditions;
    info = { ...info, match_type: type };
    setConditions({ ...info });
  }

  function handleClose() {
    navigate("/workflows/automation/dashboard");
  }

  return (
    <div className={styles.automation_wrapper}>
      <form className={styles.container_wrapper} onSubmit={handleSubmit}>
        <div className={styles.header}>
          <span className={styles.header_rule_label}>Edit rule for:</span>
          <h2 className={styles.ticket_heading}>Ticket {payload?.type}</h2>
        </div>
        <div className={styles.header_label}>
          <label>Rule name</label>
          <input
            type="text"
            placeholder="Enter rule name"
            className={styles.header_label_input}
            value={payload?.name}
            name="name"
            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
            required
          />
        </div>{" "}
        <div className={styles.header_label}>
          <label className={styles.header_label_text}>Description</label>
          <input
            type="text"
            placeholder="Enter Description"
            className={styles.header_label_input}
            value={payload?.description}
            name="description"
            onChange={(e) =>
              setPayload({ ...payload, description: e.target.value })
            }
          />
        </div>
        {payload?.type === "updation" && (
          <div className={styles.header_label}>
            <label>Involves any of these events:</label>{" "}
            {countEvents?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <EventList
                    handleEventData={handleEventData}
                    handleAddEvent={handleAddEvent}
                    event={event}
                    setEvent={setEvent}
                    countIndex={idx}
                    error={error}
                    automationData={automationData}
                    edit={true}
                  />
                </React.Fragment>
              );
            })}
          </div>
        )}
        <div className={styles.header_label}>
          <label>Involves any of these Conditions:</label>
          {countConditions?.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <ConditionList
                  handleConditionData={handleConditionData}
                  handleAddCondition={handleAddCondition}
                  conditions={conditions}
                  setConditions={setConditions}
                  countIndex={idx}
                  automationData={automationData}
                  edit={true}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.header_label}>
          <label>Perform these actions:</label>
          <div className={styles.condition_container}>
            {actions?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Actions
                    item={item}
                    actions={actions}
                    idx={idx}
                    setActions={setActions}
                    automationData={automationData}
                    edit={false}
                  />
                </React.Fragment>
              );
            })}
            <div className={styles.condition_item3}>
              <button
                className={styles.new_condition_add}
                onClick={handleAddAction}
                type="button"
              >
                <span className={styles.plus_icon}>+</span>
                Add new action
              </button>
            </div>
          </div>
        </div>
        <div className={styles.border_bottom_line}></div>
        <div className={styles.save_btn}>
          <button className={styles.preview_btn}>Preview and save</button>
          <button
            className={styles.cancel_btn}
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </form>
      <AutomationSidebar />
    </div>
  );
}

export default EditRule;
