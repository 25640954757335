import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import styles from "../css/style.module.css";
import { ReactComponent as DownloadIcon } from "../../../assets/Dialler/Download.svg";

function DownloadCSV({ data, templateType }) {
  const [existingHeaders, setExistingHeaders] = useState([]);

  useEffect(() => {
    fetch("/ticket/whatsapp.csv")
      .then((response) => response.text())
      .then((csvContent) => {
        const lines = csvContent.split("\n");
        if (lines.length > 0) {
          const firstLine = lines[0].split(",");
          setExistingHeaders(firstLine);
        }
      })
      .catch((error) => console.error("Error fetching CSV file:", error));
  }, []);

  const generateHeaders = (count) => {
    let headers = [...existingHeaders];

    if (templateType && templateType !== undefined && templateType !== "text") {
      headers.push(templateType);
    }

    for (let i = 1; i <= count; i++) {
      headers.push(`var_${i}`);
    }

    return headers;
  };

  const csvData = {
    headers: generateHeaders(data?.variable_ct || 0),
    data: [data],
  };

  return (
    <CSVLink
      data={csvData.data}
      headers={csvData.headers}
      filename={`${data?.name}.csv`}
      className={"btn"}
      style={{ width: "100%", display: "flex", gap: "10px" }}
    >
      <DownloadIcon /> Download CSV
    </CSVLink>
  );
}

export default DownloadCSV;
