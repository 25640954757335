import React from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import { customStyles } from "../../styles";

function PropertyIssueRender({
  ticketFields,
  item,
  setFieldType,
  choices,
  handleTypeChange,
  renderComponentSwitch,
}) {
  return (
    <>
      <Select
        styles={customStyles}
        options={ticketFields
          ?.filter((info) => info.parent_field == "")
          ?.map((info) => {
            return { ...info, label: `set ${info.label} as `, value: info.key };
          })}
        placeholder="Choose Property"
        className={styles.condition_select1}
        onChange={(e) => {
          setFieldType(e?.field_type);
          handleTypeChange("key", e?.value, e?.field_type);
        }}
        value={ticketFields?.filter(
          (info) => info.key == item?.properties?.key
        )}
      />

      {item?.properties?.key && (
        <div className={styles.condition_select4}>
          {renderComponentSwitch(
            item?.properties,
            handleTypeChange,
            choices,
            "issue"
          )}
        </div>
      )}
    </>
  );
}

export default PropertyIssueRender;
