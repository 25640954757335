import React from "react";
import styles from "./style.module.css";
import SelectBreak from "../SelectBreak";

function Navbar({ context }) {
  let { options, selectedOption, handleSelectChange, users } = context;
  return (
    <nav className={styles.navbar}>
      <div className={styles.subnav}>
        <SelectBreak
          data={users}
          options={options}
          selectedOption={selectedOption}
          handleSelectChange={handleSelectChange}
        />
      </div>
    </nav>
  );
}

export default Navbar;
