import React from "react";
import styles from "../css/style.module.css";

function BreaksSidebar() {
  return (
    <div className={styles.automation_sidebar}>
      <h3 className={styles.sidebar_header}>
        <span> Rules that manage agent breaks</span>
      </h3>

      <div className={styles.sidebar_container}>
        <p className={styles.sidebar_desc}>
          Break management rules allow you to set up your agent breaks by
          performing a predefined set of actions during specified times.
        </p>
        <div class="text--italic">
          <p className={styles.sidebar_desc}>
            Example: For ensuring agent breaks, you can{" "}
            <span class="text--bold">automatically:</span>
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Assign</span> break time to the
            respective agent
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Notify</span> other agents and
            supervisors
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Set</span> agent status to "On Break"
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Log</span> break details for reporting
            purposes.
          </p>
        </div>
        <p className={styles.sidebar_desc}>
          Remember, the order of the ‘Break Management’ rules are important. For
          each break period, the first matching rule will get executed. You can
          reorder the list of rules to prioritize specific breaks.
        </p>
      </div>
    </div>
  );
}

export default BreaksSidebar;
