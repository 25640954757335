import React, { useContext, useState } from "react";
import styles from "../css/style.module.css";
import { AppContext } from "../../../../../App";

function BreakAdd({
  item,
  setBreakData,
  ticketEditData,
  idx,
  callbackfn,
  breaks,
}) {
  const appContext = useContext(AppContext);
  const [data, setData] = useState("");

  //handles form input values
  function handleChange(e) {
    setData(e.target.value);
  }

  function checkValid(info) {
    let check = breaks?.find((val) => val == info);
    return !check && check === undefined;
  }

  //handles new item added
  function handleSubmit(e) {
    e.preventDefault();
    if (!checkValid(data)) {
      appContext.setAlert("Name should be unique", "alert_error");
      return;
    }
    let payload = [...breaks, data];
    item.isNew = false;
    setBreakData({ ...item });
    callbackfn(payload);
  }

  //handles the delete of ticket item
  function handleDelete() {
    item.isNew = false;
    setBreakData({ ...item });
  }

  return (
    <form className={styles.ticket_wrapper} onSubmit={handleSubmit}>
      <div className={styles.ticket_box}>
        <div className={styles.item_flex_box}>
          <span className={styles.icon_style}>N</span>
          <span>New Break</span>
        </div>
        <div className={styles.ticket_container}>
          <div className={styles.ticket_item}>
            <div className={styles.item}>
              <input
                type="text"
                className={styles.item_input}
                required
                value={data}
                name="label"
                onChange={handleChange}
              />
            </div>
            {/* <div className={styles.item}>
              <label className={styles.item_label}>Key</label>
              <input
                type="text"
                className={styles.item_input}
                required
                value={data?.key}
                name="key"
                onChange={handleChange}
              />
            </div> */}
          </div>
        </div>

        <div className={styles.ticket_btns}>
          <button
            className={styles.cancel_btn}
            type="button"
            onClick={handleDelete}
          >
            Cancel
          </button>
          <button className={styles.submit_btn}>Save Field</button>
        </div>
      </div>
    </form>
  );
}

export default BreakAdd;
