import React, { useEffect } from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import { customStyles } from "../../styles";

function PropertySubIssueRender({
  issueData,
  actions,
  setActions,
  item,
  choices,
  id,
  renderComponentSwitch,
  ticketFields,
  fieldDict,
}) {
  function handleSubIssueAppend(label, value) {
    let actionList = actions?.map((data) => {
      if (issueData.uid == data.uid) {
        item[label] = value;
        if (label == "key" && !item?.value) {
          item.value = "";
        } else if (label == "value" && !value) {
          item.property = null;
        } else if (label == "value" && value) {
          item.property = {};
        }

        data.properties = {
          ...data?.properties,
          property: item,
        };

        return data;
      }
      return data;
    });

    setActions([...actionList]);
  }

  useEffect(() => {
    const ticket_data =
      ticketFields
        ?.find((info) => info.key == issueData?.properties?.key)
        ?.choices?.find((info) => info.label == issueData?.properties?.value)
        ?.choices?.[0] || {};
    if (ticket_data?.key) {
      handleSubIssueAppend("key", ticket_data?.key);
    }
  }, [issueData.property, id]);
  return (
    <>
      {item?.key && (
        <Select
          styles={customStyles}
          options={{ label: item.key, value: item.key }}
          placeholder="key"
          className={styles.condition_select1}
          value={{ label: fieldDict?.[item?.key]?.label, value: item.key }}
          isDisabled={true}
        />
      )}
      {item?.key && (
        <div className={styles.condition_select4}>
          {renderComponentSwitch(
            item,
            handleSubIssueAppend,
            choices,
            "sub_issue"
          )}
        </div>
      )}
    </>
  );
}

export default PropertySubIssueRender;
