import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../css/role.module.css";
import { post_data } from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import { AppContext } from "../../../../../App";
import { TeamContext } from "../../../../../Routes/AdminRoutes/Components/TeamsRoute";
import PermissionsList from "./PermissionsList";

function AddRole() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { details } = teamContext || {};
  const { permissions } = details || {};
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

  let [role, setRole] = useState({
    name: "",
    description: "",
    permissions: selectedPermissionIds,
  });

  function handleSubmit(e) {
    e.preventDefault();
    let body = { ...role, permissions: selectedPermissionIds };
    post_data(`${API_URL}/convo/users/role/v1/`, body, appContext, true).then(
      (data) => data && handleClose()
    );
  }

  function handleClose() {
    navigate("/teams/roles");
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.edit_heading}>New Role</h2>
      <form className={styles.edit_form} onSubmit={handleSubmit}>
        <div className={styles.input_container}>
          <label className={styles.label}>Role Name</label>
          <input
            type="text"
            placeholder="Enter name"
            value={role?.name}
            className={styles.input}
            onChange={(e) => setRole({ ...role, name: e.target.value })}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Description</label>
          <input
            type="text"
            placeholder="Enter Description"
            className={styles.input}
            value={role?.description}
            onChange={function (e) {
              setRole({ ...role, description: e.target.value });
            }}
            required
          />
        </div>

        <div
          className={`${styles.input_container} ${styles.permission_container}`}
        >
          <label className={styles.label}>Permissions</label>
          <PermissionsList
            data={permissions}
            selectedPermissionIds={selectedPermissionIds}
            setSelectedPermissionIds={setSelectedPermissionIds}
          />
        </div>

        <div className={styles.btn_div}>
          <button className="dark-btn" type="submit">
            Add Role
          </button>
          <button type="button" className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddRole;
