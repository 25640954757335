import React from "react";
import { AppContext } from "../../App";
import { useState, useContext, useEffect } from "react";
import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import styles from "./css/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Note from "./components/Note";
import Navbar from "./components/Navbar";
import UpdateFilter from "./components/UpdateFilter";
import UpdateDescription from "./components/UpdateDescription";
import Activity from "./components/TicketActivity/Activity";
import UserActivity from "./components/UserActivity";
import InfiniteScroll from "react-infinite-scroll-component";
import Conversation from "./components/Conversation/index";
import TicketActivity from "./components/TicketActivity";
import { BsCalendar2Date } from "react-icons/bs";
import { ReactComponent as Profile } from "../../assets/Admin/Profile.svg";

function TicketDetails() {
  let [loader, setLoader] = useState(true);
  let [data, setData] = useState({});
  let [notes, setNotes] = useState([]);
  let [activity, setActivity] = useState([]);
  let [showActivity, setShowActivity] = useState(false);
  let [user, setUser] = useState(0);
  let [conversationId, setConversationId] = useState(0);
  let [msg, setMsg] = useState([]);
  let [cannedResponses, setCannedResponses] = useState([]);
  let [showConversation, setShowConversation] = useState(false);
  let [supervisorData, setSupervisorData] = useState([]);
  let [page, setPage] = useState(1);
  let [next, setNext] = useState(false);

  const appContext = useContext(AppContext);
  const { ticket_id } = useParams();
  let navigate = useNavigate();

  useEffect(
    function () {
      appContext.setTitle(`Ticket Details | ${ticket_id}`);
      setShowActivity(false);
      get_ticket_details();
      get_canned_responses();
    },
    [appContext.reload, ticket_id, navigate]
  );

  useEffect(() => {
    if (showActivity) {
      get_ticket_activity();
    }
  }, [showActivity]);

  useEffect(
    function () {
      get_notes_details();
    },
    [appContext.reload, ticket_id, appContext.note, navigate]
  );
  function get_ticket_details() {
    get_data(`${API_URL}/convo/ticket/${ticket_id}/v1/`, appContext).then(
      function (data) {
        if (data) {
          console.log(data?.data, "ticketr");
          setUser(data?.data?.consumer);
          setData(data.data);
          let conversationId = data?.data?.conversation_id;
          if (conversationId !== 0) {
            // setShowConversation(true);
            // get_conversation(data.data.phone, conversationId);
          } else {
            setShowConversation(false);
            setConversationId(0);
          }
          setLoader(false);
        }
      }
    );
  }
  function get_ticket_activity() {
    get_data(
      `${API_URL}/convo/ticket/activity/${ticket_id}/v1/?page=1`,
      appContext
    ).then(function (data) {
      if (data) {
        setActivity(data?.data);
        setNext(data?.has_next);
        setPage(data?.page);
      }
    });
  }
  function get_notes_details() {
    get_data(
      `${API_URL}/convo/ticket/notes/list/${ticket_id}/v1/`,
      appContext
    ).then(function (data) {
      if (data) {
        setNotes(data.data);
      }
    });
  }
  function get_conversation(phone, conversation_id) {
    // get_data(
    //   `${API_URL}/chat/v1/?conversation_id=${conversation_id}&phone=${phone}&overflow=${false}`,
    //   appContext
    // ).then(function (data) {
    //   if (data) {
    //     setMsg(data.data);
    //     setConversationId(conversation_id);
    //   }
    // });
  }
  function get_canned_responses() {
    get_data(
      `${API_URL}/convo/solution/canned/response/list/v1/?ecosystem=T`,
      appContext
    ).then(function (data) {
      if (data) {
        setCannedResponses(data?.data);
      }
    });
  }

  const ticketFields = appContext.ticketFields;

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <div className={styles.container}>
      <Navbar
        ticket_id={ticket_id}
        group_id={data?.group_id}
        showActivity={showActivity}
        setShowActivity={setShowActivity}
        cannedResponses={cannedResponses}
        ticket={data}
        user_id={user}
        phone={data?.phone}
      />
      <div className={styles.ticket_container}>
        <div className={styles.ticket}>
          <h3 className={styles.heading}>
            {data.phone} | {data.subject}
          </h3>
          <div className={styles.agent}>
            {data?.agent_name ? (
              <div className={styles.agent_profile}>
                <Profile />
                {data?.agent_name}
              </div>
            ) : null}
            {data?.agent_name ? <>|</> : null}

            <div className={styles.agent_profile}>
              <span>
                <BsCalendar2Date />
              </span>
              <span>{data?.created_at}</span>
            </div>
          </div>
          {!showActivity ? (
            <div className={styles.description}>
              <UpdateDescription
                data={data?.description}
                ticket_id={ticket_id}
              />
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
          ) : null}
          {/* <hr /> */}
          {showConversation ? (
            <>
              <Conversation msg={msg} />{" "}
              <a
                href={`https://chat.crofarm.com/conversation/details/${conversationId}`}
                aria-label="redirect"
                target={"_blank"}
                className={styles.center_div}
              >
                <button>Click here to view full chat on Chat Connect</button>
              </a>
            </>
          ) : (
            ""
          )}

          {showActivity ? (
            <div
              id="scrollableDiv"
              className={styles.ticket_acitivity_container}
            >
              {activity?.length > 0 ? (
                <p className={`${styles.ticket_activity_label} `}>
                  Ticket Activity
                </p>
              ) : null}
              <InfiniteScroll
                dataLength={activity?.length || 0}
                pageStart={1}
                scrollableTarget="scrollableDiv"
                next={function () {
                  get_data(
                    `${API_URL}/convo/ticket/activity/${ticket_id}/v1/?page=${page}`,
                    appContext
                  ).then(function (data) {
                    if (data) {
                      setActivity(activity?.concat(data.data));
                      setNext(data?.has_next);
                      setPage(data?.page);
                    }
                  });
                }}
                hasMore={next}
                loader={<h4 style={{ textAlign: "center" }}>Loading ... </h4>}
                endMessage={
                  activity.length > 0 ? (
                    <p style={{ textAlign: "center", marginTop: "2vh" }}>
                      <b>Yay! You have seen all users !!</b>
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                {activity?.length > 0 ? (
                  <TicketActivity data={activity} />
                ) : (
                  <h4 className={styles.no_found}>No activity found !!</h4>
                )}
              </InfiniteScroll>
            </div>
          ) : notes.length > 0 ? (
            notes.map(function (item, idx) {
              return (
                <Note
                  ticket_id={ticket_id}
                  data={item}
                  key={idx}
                  cannedResponses={cannedResponses}
                />
              );
            })
          ) : (
            <h3 className="text-center" style={{ margin: "1vh 1vw" }}>
              No note found !!
            </h3>
          )}
        </div>
        <div className={styles.filter}>
          <UpdateFilter
            ticket_data={data}
            ticket_id={ticket_id}
            filters={data}
            ticketFields={ticketFields}
          />
        </div>
        <div className={styles.filter}>
          <UserActivity user_id={user || 1} ticket_id={ticket_id} data={data} />
        </div>
      </div>
    </div>
  );
}

export default TicketDetails;
