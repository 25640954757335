import React, { useContext, useState, useEffect } from "react";
import styles from "./css/style.module.css";
import { NavLink } from "react-router-dom";
import { TeamContext } from "../../../../Routes/AdminRoutes/Components/TeamsRoute";
import { AppContext } from "../../../../App";
import RoleList from "./components/RoleList";

function Roles() {
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { details } = teamContext || {};
  const { roles } = details || {};

  let [loader, setLoader] = useState(true);

  useEffect(
    function () {
      appContext.setTitle("Roles");
      if (details && details?.roles !== undefined) {
        setLoader(false);
      }
    },
    [appContext.reload]
  );
  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <div className={styles.btncontainer}>
        <h2>Agent Roles</h2>
        <NavLink className="dark-btn" to="/teams/roles/add">
          New Role
        </NavLink>
      </div>
      <div className={styles.container}>
        {roles?.map(function (item, idx) {
          return <RoleList item={item} idx={idx} />;
        })}
      </div>
    </>
  );
}

export default Roles;
