import React, { useState } from "react";
import styles from "./Card.module.css";

function Card(props) {
  const [isEmpty, setIsEmpty] = useState(true);

  return (
    <div className={isEmpty ? styles.card_empty : styles.card}>
      <div className={styles.name}>{props?.name}</div>
    </div>
  );
}

export default Card;
