import React, { useContext, useEffect, useState } from "react";
import styles from "./css/style.module.css";
import { ReactComponent as Folder } from "../../assets/clusters/Folder.svg";
import { ReactComponent as Arrow } from "../../assets/clusters/Arrow.svg";
import { ReactComponent as UpArrow } from "../../assets/clusters/UpArrow.svg";

import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import { AppContext } from "../../App";
import GroupList from "./components/GroupList";
import AgentList from "./components/AgentList";

function SmartAssign() {
  const appContext = useContext(AppContext);
  let [toggle, setToggle] = useState(true);
  let [group, setGroup] = useState(-1);
  let [allGroups, setAllGroups] = useState([]);
  let [agents, setAgents] = useState([]);
  let [loader, setLoader] = useState(false);
  let [breakOptions, setBreakOptions] = useState([]);

  function handleToggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    appContext.setPage("Smart Assign");
    appContext.setTitle("Smart Assign");
    get_group_details();
    get_agent_details(group);

    setBreakOptions(convertToOptions(appContext?.ticketFields?.config?.breaks));
  }, [appContext.reload, appContext.ticketFields]);

  function convertToOptions(data) {
    return data?.map((item) => {
      const value = item.replace(" ", "");
      return { label: item, value };
    });
  }

  useEffect(() => {
    get_agent_details(group);
  }, [group]);

  function get_group_details() {
    get_data(`${API_URL}/convo/users/group/list/v1/`, appContext).then(
      (data) => {
        if (data) {
          setAllGroups(data?.data);
          if (group == -1) {
            setGroup(data?.data[0]?.id);
          }
          setLoader(false);
        }
      }
    );
  }

  function get_agent_details(id) {
    setGroup(id);
    if (id === -1 || !id) {
      return;
    }
    get_data(`${API_URL}/convo/users/group/agents/${id}/v1/`, appContext).then(
      (data) => {
        if (data) {
          setAgents(data.data);
          setLoader(false);
        }
      }
    );
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <header className={styles.header}>
        <div className={styles.heading_col_1}>
          <p className={styles.heading}>Groups</p>
        </div>
        <div className={styles.heading_col_2}>
          <p className={styles.heading}>Agent({agents?.length})</p>
          <p className={styles.heading_email}>Email</p>
          <p className={styles.heading}>Updated At</p>
          <p
            className={styles.heading}
            style={{ visibility: "hidden", flex: "0.5" }}
          >
            toggle
          </p>
        </div>
      </header>
      <div className={styles.app}>
        <section className={styles.col_1}>
          <div className={`${styles.folder} ${toggle ? styles.expanded : ""}`}>
            <p>
              <Folder />
              <span>General</span>
            </p>
            <p onClick={handleToggle}>{toggle ? <UpArrow /> : <Arrow />}</p>
          </div>
          <div className={styles.list_wrapper}>
            {toggle &&
              allGroups?.map((item, idx) => (
                <GroupList
                  key={idx}
                  item={item}
                  setGroup={setGroup}
                  group={group}
                />
              ))}
          </div>
        </section>

        <section className={styles.col_2}>
          <div className={styles.article_wrapper}>
            {agents?.length ? (
              agents?.map((item, idx) => (
                <AgentList
                  key={idx}
                  item={item}
                  idx={idx}
                  breakOptions={breakOptions}
                />
              ))
            ) : (
              <div className={styles.no_agent}>
                <h1>No Data Found</h1>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default SmartAssign;
