import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import styles from "./style.module.css";
import { checkClick } from "../../cruxreactlib/utils/utility";
import { ReactComponent as DownArrow } from "../../assets/Admin/DownArrow.svg";
import {
  get_data,
  post_data_without_reload,
} from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import { AppContext } from "../../App";

function ToggleButton({ item, isAgent, agent_id, breakOptions }) {
  let [isChecked, setChecked] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [selectedValue, setSelectedValue] = useState(null);
  const appContext = useContext(AppContext);
  const switchRef = useRef(null);

  window.addEventListener("click", function (event) {
    checkClick(event, switchRef, setIsOpen);
  });

  useEffect(
    function () {
      if (agent_id && agent_id !== undefined) {
        get_agent_details();
      } else {
        setChecked(item?.is_available);
        setSelectedValue(item?.agent_break);
      }
    },
    [agent_id, item, appContext.reload]
  );

  function handleUpdate() {
    setIsOpen(true);
  }

  function handleChange(selectedOptions) {
    setSelectedValue(selectedOptions?.label);
    let url = isAgent
      ? `${API_URL}/convo/users/smart/assign/v1/`
      : `${API_URL}/convo/users/smart/assign/${item?.id}/v1/`;
    post_data_without_reload(
      `${url}`,
      {
        break: selectedOptions?.label,
        is_available: !isChecked,
      },
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setIsOpen(false);
        setChecked(!isChecked);
      }
    });
  }

  function get_agent_details() {
    get_data(`${API_URL}/convo/users/agent/${agent_id}/v1/`, appContext).then(
      function (data) {
        if (data) {
          setChecked(data?.data?.is_available);
          setSelectedValue(data?.data?.agent_break);
        }
      }
    );
  }

  return (
    <div
      className={`${styles.incentive_info} ${styles.toggle}`}
      ref={switchRef}
    >
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={isChecked}
          className={styles.hide}
          onChange={handleUpdate}
        />
        <span className={`${styles.slider} ${styles.round}`}>
          {isChecked && "ON"}
        </span>
      </label>
      {isOpen && (
        <div className={styles.optionsContainer}>
          <h6 className={styles.text}>
            Select {isChecked ? "Pause" : "Active"} Reason <DownArrow />
          </h6>
          <Select
            options={breakOptions}
            onChange={handleChange}
            value={breakOptions?.find((item) => item.label === selectedValue)}
          />
        </div>
      )}
    </div>
  );
}

export default ToggleButton;
