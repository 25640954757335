// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_ticket_container__1cE8R {
  display: flex;
  background-color: #f4f5f7;
  /* padding: 0vh 1vw; */
  /* min-height: 90vh; */
  /* height: 68dvh; */
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  /* .ticket_container {
    flex-direction: column;
  } */
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/Workflows/TicketDashboard/css/dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;;KAEG;AACL","sourcesContent":[".ticket_container {\n  display: flex;\n  background-color: #f4f5f7;\n  /* padding: 0vh 1vw; */\n  /* min-height: 90vh; */\n  /* height: 68dvh; */\n  overflow: hidden;\n}\n\n@media screen and (max-width: 900px) {\n  /* .ticket_container {\n    flex-direction: column;\n  } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ticket_container": `dashboard_ticket_container__1cE8R`
};
export default ___CSS_LOADER_EXPORT___;
