import React, { useState, useEffect } from "react";
import Select from "react-select";
import styles from "./style.module.css";

function SelectBreak({ options, selectedOption, handleSelectChange, data }) {
  let [agentCount, setAgentCount] = useState(0);
  useEffect(() => {
    let count = data?.filter((item) => {
      return item.agent_break == selectedOption;
    }).length;

    setAgentCount(count);
  }, [selectedOption]);

  return (
    <div className={styles.select_container}>
      <Select
        options={options}
        value={options?.filter((item) => item.label == selectedOption)}
        onChange={handleSelectChange}
        placeholder="Select Status"
        className={styles.select_option}
        isClearable
      />{" "}
      {selectedOption && (
        <label className={styles.agent_count}>({agentCount})</label>
      )}
    </div>
  );
}

export default SelectBreak;
