import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import Navbar from "./components/Navbar";
import styles from "./css/style.module.css";
import User from "./components/User";
import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import { ReactComponent as NoFound } from "../../assets/NoFound.svg";
function UsersBreak() {
  const appContext = useContext(AppContext);

  let [query, setQuery] = useState("");
  let [loader, setLoader] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const context = {
    query,
    setQuery,
    selectedOption,
    handleSelectChange,
    options,
    users: filteredData.map(function (item, idx) {
      return item;
    }),
  };

  useEffect(
    function () {
      appContext.setTitle("Users Break");
      appContext.setPage("Users Break");
      get_all_users();
    },
    [appContext.reload]
  );

  useEffect(() => {
    let break_data = appContext?.ticketFields?.config?.breaks || [];
    break_data = break_data?.map((item) => {
      return { label: item, value: item };
    });
    setOptions(break_data);
  }, [appContext.ticketFields]);

  function get_all_users() {
    get_data(`${API_URL}/convo/users/agent/list/v1/`).then((data) => {
      if (data) {
        setUsers(data?.data);
        setFilteredData(data?.data);
        setLoader(false);
      }
    });
  }

  function handleSelectChange(selected) {
    setSelectedOption(selected?.value || "");
    if (selected) {
      const filtered = users?.filter(
        (item) => item.agent_break === selected?.value
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(users);
    }
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <Navbar context={context} />
      <div className={`"item-row-container" ${styles.container}`}>
        <div className={styles.header_wrapper}>
          <div className={styles.header}>
            <p className={styles.heading}>Name</p>
            <p className={styles.heading}>Email</p>
            <p className={styles.heading}>Status </p>
            <p className={styles.heading}>Phone</p>{" "}
          </div>
        </div>
        <div className={styles.user_container} id="scrollableDiv">
          <div className={styles.user_wrapper_container}>
            {filteredData?.length > 0 ? (
              filteredData?.map((item, idx) => (
                <User data={item} key={idx} options={options} />
              ))
            ) : (
              <h1 className={styles.no_user}>
                <NoFound />
                <p className={styles.no_user_text}>No Users found</p>
              </h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersBreak;
