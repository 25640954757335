import React, {
  Suspense,
  useContext,
  createContext,
  useEffect,
  useState,
} from "react";
import Alert from "../../../cruxreactlib/utils/Alert";
import { AppContext } from "../../../App";
import { isAgentLoggedIn } from "../../../cruxreactlib/auth";
import { Navigate, Outlet } from "react-router-dom";
import TeamsHeader from "../../../components/TeamsHeader";
import { API_URL } from "../../../config";
import { get_data } from "../../../cruxreactlib/networkhandler";

const TeamContext = createContext();

function TeamsRoute() {
  const appContext = useContext(AppContext);
  let [constants, setConstants] = useState({ ecosystem: {}, assignment: {} });
  let [details, setDetails] = useState({
    groups: [],
    agents: [],
    roles: [],
    permissions: [],
  });

  useEffect(
    function () {
      get_convo_constants();
      get_all_agents();
      get_all_groups();
      get_all_roles();
      get_all_permissions();
    },
    [appContext.reload]
  );

  function get_convo_constants() {
    get_data(`${API_URL}/convo/users/constants/v1/`, appContext).then(function (
      data
    ) {
      if (data) {
        setConstants({
          ecosystem: data?.ecosystems,
          assignment: data?.assignments,
        });
      }
    });
  }
  function get_all_groups() {
    get_data(`${API_URL}/convo/users/group/list/v1/`, appContext).then(
      function (data) {
        setDetails((prevDetails) => ({ ...prevDetails, groups: data?.data }));
      }
    );
  }

  function get_all_agents() {
    get_data(`${API_URL}/convo/users/agent/list/v1/`, appContext).then(
      function (data) {
        if (data) {
          setDetails((prevDetails) => ({ ...prevDetails, agents: data?.data }));
        }
      }
    );
  }

  function get_all_roles() {
    get_data(`${API_URL}/convo/users/role/list/v1/`, appContext).then(function (
      data
    ) {
      if (data) {
        setDetails((prevDetails) => ({ ...prevDetails, roles: data?.data }));
      }
    });
  }

  function get_all_permissions() {
    get_data(`${API_URL}/convo/users/permission/policy/v1/`, appContext).then(
      function (data) {
        if (data) {
          setDetails((prevDetails) => ({
            ...prevDetails,
            permissions: data?.data,
          }));
        }
      }
    );
  }

  let value = {
    constants,
    setConstants,
    details,
    setDetails,
  };

  return isAgentLoggedIn() ? (
    <TeamContext.Provider value={value}>
      <div className="teams_height">
        <TeamsHeader
          showContent={appContext.showContent}
          setShowContent={appContext.setShowContent}
          setSearchType={appContext.setSearchType}
          searchType={appContext.searchType}
        />
        <Suspense
          fallback={
            <div className="loader_container">
              <div className="loader"></div>
            </div>
          }
        >
          <div className="route_height">
            <Outlet />
          </div>
        </Suspense>
        <Alert
          className={appContext.alert_class}
          response={appContext.response}
          setClass={appContext.setClass}
        />
      </div>
    </TeamContext.Provider>
  ) : (
    <Navigate to="/validate" replace={true} />
  );
}

export { TeamContext };
export default TeamsRoute;
