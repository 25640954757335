const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "4px",
    padding: "0px 1px", // Adjust the padding here (top/bottom padding 0px, left/right padding 8px)
    backgroundColor: "#ffffff",
    backgroundImage: "linear-gradient(to bottom, #fff, #f3f5f7)",
    border: state.isFocused ? "1px solid #66afe9" : "1px solid #ccc",
    boxShadow: state.isFocused ? "0 0 0 1px #66afe9" : "none",
    transition: "border-color 0.2s ease",
    "&:hover": {
      border: state.isFocused ? "1px solid #66afe9" : "1px solid #ccc",
    },
    fontFamily: "Arial, sans-serif", // Set font family
    fontSize: "14px", // Set font size
    color: "#333", // Set text color
    background: "#ffffff",
    textTransform: "capitalize", // Capitalize text,
    border: "none",
    outline: "none",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "#000", // Change the color to match your design
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%", // Set dropdown width to 100%,
    left: "4px",
    zIndex: 4,
  }),
};

export { customStyles };
