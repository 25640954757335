import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../../../../App";
import { API_URL } from "../../../../../../config";
import { delete_data } from "../../../../../../cruxreactlib/networkhandler";
import styles from "../css/style.module.css";
import { ReactComponent as Edit } from "../../../../../../assets/Admin/Edit.svg";
import { ReactComponent as Delete } from "../../../../../../assets/Admin/Delete.svg";
import Popup from "../../../../../../cruxreactlib/utils/Popup/index";
function CannedResponse({ data }) {
  const appContext = useContext(AppContext);

  let [close, setClose] = useState(false);
  let [randomColor, setRandomColor] = useState("#ffffff");
  let [show, setShow] = useState(false);

  useEffect(() => {
    const getRandomLightColor = () => {
      const getComponent = () => Math.floor(Math.random() * 128) + 128;
      const rgb = [getComponent(), getComponent(), getComponent()];
      return `#${rgb
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;
    };
    setRandomColor(getRandomLightColor());
  }, [data]);

  useEffect(
    function () {
      setClose(false);
      setShow(false);
    },
    [close]
  );
  function handleDelete(e) {
    e.preventDefault();
    delete_data(
      `${API_URL}/convo/solution/canned/response/${data.id}/v1/`,
      appContext,
      true
    ).then((data) => {
      setClose(true);
      setShow(false);
    });
  }
  function handleMouseEnter() {
    setShow(true);
  }
  function handleMouseLeave() {
    setShow(false);
  }

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.col}>
        <p className={styles.icon_div} style={{ backgroundColor: randomColor }}>
          {data?.title?.charAt(0)}
        </p>

        <p className={styles.text}>{data?.title}</p>
      </div>
      <div className={styles.col}>
        <p className={styles.text}>{data?.date ? data?.date : "---"}</p>
      </div>
      <div className={styles.col}>
        <p className={styles.text}>{data?.shortcut ? data?.shortcut : "---"}</p>
      </div>{" "}
      <div className={styles.col}>
        <p className={styles.text}>
          {data?.ecosystem ? data?.ecosystem : "---"}
        </p>
      </div>
      <div
        className={styles.col}
        style={{ visibility: show ? "visible" : "hidden" }}
      >
        <NavLink
          className={styles.btn}
          to={{
            pathname: `/workflows/canned/response/edit/${data?.id}`,
          }}
        >
          <Edit />
        </NavLink>
        <Popup btnName={<Delete />} btnStyling={styles.btn} closeState={close}>
          <h1 className={styles.popup_heading}>Delete Canned Response</h1>
          <form className={styles.form} onSubmit={handleDelete}>
            <h2>Are you sure you want to delete the Canned Response?</h2>
            <div
              className={`${styles.input_container} ${styles.submit_container}`}
              style={{ zIndex: 0 }}
            >
              <input className="dark-btn" type="submit" />
            </div>
          </form>
        </Popup>
      </div>
    </div>
  );
}

export default CannedResponse;
