// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__pY9bq {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  border-radius: 10px;
  margin: 1vh 1vw;
  color: #b9b9b9;
  background: #f4f4f4;
}
.style_customer_details__DMD6k {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* flex: 3; */
  color: #364f6b;
}

.style_customer_info__nn5Dq {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0.5vh 0.5vw;
  margin: 1vh 1vw;
}

.style_heading__AzeJK {
  color: #5d5d5d;
}
.style_popup_btn__nnqcA {
  margin: 0.5vh 1vw;
  padding: 0.5vh 0.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  /* z-index: 5; */
  background-color: #3a7638;
  color: white;
  border: none;
  font-size: 1rem;
  justify-content: center;
  background-color: #f3f5f7;
  color: var(--primary-color);
  border: 1px solid #cfd7df;
  background-image: linear-gradient(to bottom, #fff, #f3f5f7);
}
.style_note_info__B7TRr {
  padding-top: 0;
  margin-top: 0;
  color: #5d5d5d;
  word-break: break-word;
}
.style_created__VQpVi {
  font-size: 10px;
  color: #b9b9b9;
}
`, "",{"version":3,"sources":["webpack://./src/modules/TicketDetails/components/Note/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,yBAAyB;EACzB,2BAA2B;EAC3B,yBAAyB;EACzB,2DAA2D;AAC7D;AACA;EACE,cAAc;EACd,aAAa;EACb,cAAc;EACd,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  font-weight: 700;\n  border-radius: 10px;\n  margin: 1vh 1vw;\n  color: #b9b9b9;\n  background: #f4f4f4;\n}\n.customer_details {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  /* flex: 3; */\n  color: #364f6b;\n}\n\n.customer_info {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  padding: 0.5vh 0.5vw;\n  margin: 1vh 1vw;\n}\n\n.heading {\n  color: #5d5d5d;\n}\n.popup_btn {\n  margin: 0.5vh 1vw;\n  padding: 0.5vh 0.5vw;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 5px;\n  /* z-index: 5; */\n  background-color: #3a7638;\n  color: white;\n  border: none;\n  font-size: 1rem;\n  justify-content: center;\n  background-color: #f3f5f7;\n  color: var(--primary-color);\n  border: 1px solid #cfd7df;\n  background-image: linear-gradient(to bottom, #fff, #f3f5f7);\n}\n.note_info {\n  padding-top: 0;\n  margin-top: 0;\n  color: #5d5d5d;\n  word-break: break-word;\n}\n.created {\n  font-size: 10px;\n  color: #b9b9b9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__pY9bq`,
	"customer_details": `style_customer_details__DMD6k`,
	"customer_info": `style_customer_info__nn5Dq`,
	"heading": `style_heading__AzeJK`,
	"popup_btn": `style_popup_btn__nnqcA`,
	"note_info": `style_note_info__B7TRr`,
	"created": `style_created__VQpVi`
};
export default ___CSS_LOADER_EXPORT___;
