// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentions {
  width: 100%;
  border: 0;
  /* border: 1px solid #cfd7df; */
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  /* border: 1px solid black; */
}

.mentions__mention {
  background-color: #cee4ff;
  color: rgb(24, 50, 71);
}

.mentions__mention {
  color: rebeccapurple;
}

.mentions__input {
  width: 100%;
  border: 1px solid transparent !important;
  outline: none;
}

.custom-select,
.custom-select-container,
.custom-select-menu,
.custom-select-option {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/AIResponses/MentionArea /css/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,+BAA+B;EAC/B,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,wCAAwC;EACxC,aAAa;AACf;;AAEA;;;;EAIE,WAAW;AACb","sourcesContent":[".mentions {\n  width: 100%;\n  border: 0;\n  /* border: 1px solid #cfd7df; */\n  border-radius: 4px;\n  background-color: #fff;\n  outline: none;\n  /* border: 1px solid black; */\n}\n\n.mentions__mention {\n  background-color: #cee4ff;\n  color: rgb(24, 50, 71);\n}\n\n.mentions__mention {\n  color: rebeccapurple;\n}\n\n.mentions__input {\n  width: 100%;\n  border: 1px solid transparent !important;\n  outline: none;\n}\n\n.custom-select,\n.custom-select-container,\n.custom-select-menu,\n.custom-select-option {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
