import React, { useContext, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { AppContext } from "../../App";
import "./css/style.css";
import styles from "./css/style.module.css";
import { post_data_without_reload } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import { IoReload } from "react-icons/io5";

function MentionArea({
  text,
  setText,
  placeholder = "",
  isCondition,
  isKey = true,
  style,
  disabled,
  submit,
  data,
}) {
  let cannedResponses = data;
  const [loader, setLoader] = useState(false);
  const appContext = useContext(AppContext);
  const [message, setMessage] = useState({
    messages: [
      {
        role: "user",
        content:
          "Chatting to Otipy (Ecommerce platform for fruits & vegetables) Consumer",
      },
    ],
  });

  const renderSuggestion = (suggestion, search, highlightedDisplay) => {
    return (
      <div className={styles.custom_suggestion}>
        <p className={styles.display_text}>{suggestion["display"]}</p>
        <p className={styles.id_text}>{suggestion["id"]}</p>
      </div>
    );
  };
  const mentionsInputStyle = {
    background:
      placeholder ===
      "Private notes are not visible to you and your team. Press Shift + Enter to add a newline"
        ? "rgb(254, 241, 225)"
        : "",
  };
  const handleChange = (e, newValue, newPlainTextValue) => {
    if (e.key === "Enter" && e.shiftKey) {
      setText(newPlainTextValue + "\n");
    } else {
      setText(newPlainTextValue);
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submit();
    }
  };

  function handleAiCall(new_description, reload) {
    setLoader(true);
    let messageData = { messages: [message.messages[0]] };
    messageData.messages.push({
      role: reload ? "system" : "user",
      content: new_description,
    });
    if (reload)
      messageData.messages.push({ role: "user", content: "another one" });
    setMessage(messageData);
    post_data_without_reload(
      `${API_URL}/convo/ticket/ai/v1/`,
      messageData,
      appContext,
      false
    ).then((data) => {
      if (data) {
        setText(data?.data?.reply);
        setLoader(false);
      }
    });
  }

  function handleSelect(id, display) {
    handleAiCall(id, false);
    return "";
  }
  return (
    <div
      style={style}
      className={`${styles.input} ${
        isCondition && isCondition !== undefined
          ? styles.conditionClassName
          : ""
      }`}
    >
      {loader ? (
        <div className={styles.mention_loader}>
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <MentionsInput
            value={text}
            placeholder={placeholder}
            a11ySuggestionsListLabel={"Suggested mentions"}
            onKeyDown={handleEnter}
            onChange={handleChange}
            className="mentions"
            // suggestionsPortalHost={container}
            forceSuggestionsAboveCursor={true}
            disabled={disabled}
            style={mentionsInputStyle}
          >
            <Mention
              trigger="/"
              data={cannedResponses.map(function (item, idx) {
                return {
                  id: item.description,
                  display: `(${item.shortcut}) ${item.title} `,
                };
              })}
              markup="/[__display__](id:__id__)"
              onAdd={handleSelect}
              renderSuggestion={renderSuggestion}
            />
          </MentionsInput>
          <span
            className={styles.reload_icon}
            onClick={() => handleAiCall(text, true)}
          >
            <IoReload />
          </span>
        </>
      )}
    </div>
  );
}

export default MentionArea;
