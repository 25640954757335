import React from "react";
import { NavLink } from "react-router-dom";
import styles from "../css/style.module.css";

function RoleList({ item, idx }) {
  return (
    <div className={styles.wrapper} key={idx}>
      <NavLink to={`/teams/roles/edit/${item?.id}`} className={styles.item_row}>
        <div className="item-col">
          <div className={styles.col_text_bold}>{item?.name}</div>
          <div className={styles.col_text_small}>{item?.description}</div>
        </div>
      </NavLink>
    </div>
  );
}

export default RoleList;
