import React, { useState } from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import DynamicPayload from "../../../Dynamic/DynamicPayload";
import URl from "./URl";
import { customStyles } from "../../styles";

function Webhook({ ticketOptions, item, actions, setActions, automationData }) {
  let [showHeader, setShowHeader] = useState(false);
  let [showBody, setShowBody] = useState(false);
  let [showParams, setShowParams] = useState(false);
  function handleTicketChange(label, value) {
    let actionList = actions?.map((data) => {
      if (item.uid == data.uid) {
        if (label == "method") {
          return {
            ...data,
            webhook: {
              ...data?.webhook,
              [label]: value,
              headers: "{}",
              body: "{}",
              params: "{}",
            },
          };
        } else {
          return { ...data, webhook: { ...data?.webhook, [label]: value } };
        }
      }
      return data;
    });
    setActions([...actionList]);
  }

  return (
    <div className={styles.webhook_wrapper}>
      <div className={styles.webhook_item}>
        <span className={styles.hook_label}>Request Type</span>
        <div style={{ width: "100%" }}>
          <Select
            styles={customStyles}
            options={ticketOptions}
            placeholder="Method"
            value={ticketOptions?.filter(
              (info) => info.value == item?.webhook?.method
            )}
            onChange={(e) => handleTicketChange("method", e.value)}
            required
          />
        </div>
      </div>

      <URl
        item={item}
        handleTicketChange={handleTicketChange}
        styles={styles}
        automationData={automationData}
      />

      <div className={styles.webhook_item}>
        {/* <span className={styles.hook_label}>Header</span> */}
        <DynamicPayload
          handleTicketChange={handleTicketChange}
          displayText="Header"
          label="headers"
          item={item}
          showbtn={showHeader}
          setShowBtn={setShowHeader}
          setHideBtn1={setShowBody}
          setHideBtn2={setShowParams}
          automationData={automationData}
        />
      </div>
      {item?.webhook?.method &&
        item?.webhook?.method != "get" &&
        item?.webhook?.method != "delete" && (
          <div className={styles.webhook_item}>
            {/* <span className={styles.hook_label}>Body</span>{" "} */}
            <DynamicPayload
              handleTicketChange={handleTicketChange}
              displayText="Body"
              label="body"
              item={item}
              showbtn={showBody}
              setShowBtn={setShowBody}
              setHideBtn1={setShowParams}
              setHideBtn2={setShowHeader}
              automationData={automationData}
            />
          </div>
        )}
      {item?.webhook?.method &&
        item?.webhook?.method != "put" &&
        item?.webhook?.method != "post" && (
          <div className={styles.webhook_item}>
            {/* <span className={styles.hook_label}>Params</span>{" "} */}
            <DynamicPayload
              handleTicketChange={handleTicketChange}
              displayText="Params"
              label="params"
              item={item}
              showbtn={showParams}
              setShowBtn={setShowParams}
              setHideBtn1={setShowBody}
              setHideBtn2={setShowHeader}
              automationData={automationData}
            />
          </div>
        )}
    </div>
  );
}

export default Webhook;
