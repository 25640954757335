import React, { useEffect, useState } from "react";
import styles from "../AdminHeader/adminHeader.module.css";
import { useNavigate } from "react-router-dom";
import { ICON_URL } from "../../config";
import TabBar from "../../cruxreactlib/utils/TabBar";
function WorkFlowHeader({ showContent, setShowContent }) {
  const [searchType, setSearchType] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setSearchType("");
  }, []);

  useEffect(() => {
    routeNavigation(searchType);
  }, [searchType]);

  function routeNavigation(type) {
    if (type == "ticket_fields") {
      navigate("/workflows/ticket/dashboard");
    } else if (type == "automation") {
      navigate("/workflows/automation/dashboard");
      setShowContent(true);
    } else if (type == "ai_response") {
      navigate("/workflows/ai/response");
      setShowContent(true);
    } else if (type == "canned_response") {
      navigate("/workflows/canned/response");
      setShowContent(true);
    } else if (type == "sla_policy") {
      navigate("/workflows/sla/policy");
      setShowContent(true);
    }
  }

  function handleClick() {
    setSearchType("ticket_fields");
    setShowContent(true);
  }

  return (
    <div
      className={styles.team_container}
      style={{
        display: showContent && "none",
      }}
    >
      <p className={styles.team_label}>Workflow</p>

      <div className={styles.tab_layout}>
        <TabBar
          tabs={[
            {
              title: "Ticket Fields",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/ticket-fields-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Customize your ticket type to categorize, prioritize, and route tickets efficiently.",
              execute: { func: handleClick, value: "ticket_fields" },
              callbackfn: routeNavigation,
            },
            {
              title: "Automation",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/automations-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Eliminate repetitive tasks such as categorization and routing by creating rules",
              execute: { func: setSearchType, value: "automation" },
              callbackfn: routeNavigation,
            },

            {
              title: "Sla Policy",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/activity-export-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Set up targets for agents to guarantee timely responses and resolutions to customers.",

              execute: { func: setSearchType, value: "sla_policy" },
              callbackfn: routeNavigation,
            },
            {
              title: "Canned Response",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/helpdesk-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Pre-create replies to quickly insert them in responses to customers",

              execute: { func: setSearchType, value: "canned_response" },
              callbackfn: routeNavigation,
            },
          ]}
          sub_title={true}
          styles={styles}
          searchType={searchType}
        />
      </div>
    </div>
  );
}

export default WorkFlowHeader;
