import React, { useState } from "react";
import styles from "../css/style.module.css";
import { ICON_URL } from "../../../config";
function GroupList({ setGroup, item, group }) {
  return (
    <div
      className={`${styles.list} ${group === item?.id ? styles.selected : ""}`}
      key={item?.id}
    >
      <p className={styles.align} onClick={() => setGroup(item?.id)}>
        <img
          src={`${ICON_URL}/production/a/assets/images/new-admin/groups-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`}
        />
        {item?.name}
      </p>
    </div>
  );
}

export default GroupList;
