import React, { useEffect, useState } from "react";
import styles from "./popup.module.css";
import PopupList from "./PopupList";
import { ReactComponent as Search } from "../../../../../assets/Automation/search.svg";
import { ReactComponent as Cross } from "../../../../../assets/Automation/Cross.svg";

const Popup = ({ handleAppend, setShow, automationData }) => {
  const [popupData, setPopupData] = useState([]);
  useEffect(() => {
    let data = [];

    automationData?.ticketFields?.map((item) => {
      if (item?.nested_fields?.length == 0) {
        data.push({
          label: `ticket.${item.key}`,
          value: `ticket.${item.key}`,
          type: item?.field_type,
        });
      } else {
        item?.nested_fields?.map((info) => {
          data.push({
            label: `ticket.${item.key}.${info}`,
            value: `ticket.${item.key}.${info}`,
            type: item?.field_type,
          });
        });
      }
    });

    setPopupData(data);
  }, [automationData]);

  let [query, setQuery] = useState("");

  function handleClick(item, type) {
    handleAppend(`{{${item}}}`, type);
  }
  return (
    <div className={styles.popup_container}>
      <h3 className={styles.popup_header}>
        Placeholder{" "}
        {/* <span style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
          <Close />
        </span> */}
        <Cross style={{ cursor: "pointer" }} onClick={() => setShow(false)} />
      </h3>

      <div className={styles.search_box}>
        {/* <span className={styles.search_icon}>
          <Search />
        </span> */}
        <Search />
        <input
          type="text"
          placeholder="Search for placeholder"
          className={styles.placeholder_input}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className={styles.popup_body}>
        {query !== ""
          ? popupData
              ?.filter((item) =>
                item?.label?.toLowerCase()?.includes(query?.toLowerCase())
              )
              ?.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <PopupList item={item} handleClick={handleClick} />
                  </React.Fragment>
                );
              })
          : popupData?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <PopupList item={item} handleClick={handleClick} />
                </React.Fragment>
              );
            })}
      </div>
    </div>
  );
};

export default Popup;
