import React, { useContext, useEffect, useState } from "react";
import styles from "../../css/popup.module.css";
import Popup from "../../../../cruxreactlib/utils/Popup";
import { AppContext } from "../../../../App";
import DownloadCsv from "../DownloadCsv";
import CsvUpload from "../CsvUpload";

function BulkSend({ template_name, data }) {
  const appContext = useContext(AppContext);
  const [close, setClose] = useState(false);

  useEffect(
    function () {
      setClose(false);
    },
    [close]
  );

  function handleTestSubmit(e) {
    e.preventDefault();
  }

  return (
    <Popup btnName={"Bulk Send"} btnStyling="btn" closeState={close}>
      <form onSubmit={handleTestSubmit}>
        <div className={styles.test_template_header}>
          <h2>Bulk Send</h2>
          <span>({template_name})</span>
        </div>
        <div
          className={styles.test_template_body}
          style={{ padding: "1vmax 0" }}
        >
          <div
            className={styles.info}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <DownloadCsv data={data} templateType={data?.type} />
            <CsvUpload
              count={data?.variable_ct}
              templateType={data?.type}
              templateId={data?.id}
              setClose={setClose}
            />
          </div>
        </div>
      </form>
    </Popup>
  );
}

export default BulkSend;
