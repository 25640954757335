import React, { useState, useEffect } from "react";
import styles from "../../../css/style.module.css";
import Text from "../../FieldComponents/Text";
import PropertyIssueRender from "./PropertyIssueRender";
import PropertySubIssueRender from "./PropertySubIssueRender";
import PropertyFurtherRender from "./PropertyFurtherRender";
import SingleSelectText from "../../FieldComponents/SingleSelectText";
import BooleanInput from "../../FieldComponents/Boolean";

function Property({ item, actions, setActions, automationData }) {
  // Constants for specific values
  let otherConstant = [
    {
      label: "None",
      value: "",
    },
  ];

  // State variables
  const [fieldType, setFieldType] = useState("");
  const [constantsMapping, setConstantsMapping] = useState({});
  const [ticketFields, setTicketFields] = useState([]);
  const [issueId, setIssueId] = useState(0);
  const [subIssueId, setSubIssueId] = useState(0);
  const [issueChoices, setIssueChoices] = useState([]);
  const [subIssueChoices, setSubIssueChoices] = useState([]);
  const [furtherChoices, setFurtherChoices] = useState([]);
  const [loader, setLoader] = useState(true);
  const [fieldDict, setFieldDict] = useState({});

  useEffect(() => {
    // Set constants and ticket fields from automation data
    setConstantsMapping(automationData?.constants);
    setTicketFields(automationData?.ticketFields);
    setFieldDict(automationData?.fieldDict);

    const automation_data = automationData?.ticketFields?.find(
      (info) => info.key == item?.properties?.key
    );

    setFieldType(automation_data?.field_type || "");
    if (automation_data) {
      let d = automation_data?.choices || [];
      d = d?.map((info) => ({ ...info, value: info?.value || info?.label }));
      setIssueChoices([...otherConstant, ...d]);
    }

    const issue_id =
      automation_data?.choices?.find(
        (info) => info.label == item?.properties?.value
      )?.id || 0;

    const subissue_id = automation_data?.choices
      ?.find((info) => info.label == item?.properties?.value)
      ?.choices?.find(
        (info) => info.label == item?.properties?.property?.value
      )?.id;

    setIssueId(issue_id);
    setSubIssueId(subissue_id);

    setLoader(false);
  }, [automationData, item.properties]);

  useEffect(() => {
    // Set sub issue choices and further choices based on selected values
    const automation_data = automationData?.ticketFields?.find(
      (info) => info.key == item?.properties?.key
    );
    let subData =
      automation_data?.choices?.find((info) => info.id == issueId)?.choices ||
      [];
    subData = subData?.map((info) => ({
      ...info,
      value: info?.value || info?.label,
    }));

    let fbData = subData?.find((info) => info.id == subIssueId)?.choices || [];
    fbData = fbData?.map((info) => ({
      ...info,
      value: info?.value || info?.label,
    }));

    setSubIssueChoices([...otherConstant, ...subData]);
    setFurtherChoices([...otherConstant, ...fbData]);
  }, [issueId, subIssueId]);

  // Handle the change of property type
  function handleTypeChange(label, value, field_type) {
    let actionList = actions?.map((data) => {
      if (item.uid == data.uid) {
        if (label == "key") {
          data.properties = { [label]: value };
        } else {
          data.properties = { ...data?.properties, [label]: value };
        }

        if (label == "key" && field_type == "dependent") {
          data.properties = { ...data?.properties, value: "" };
        }
        if (label == "value" && value && field_type == "dependent") {
          data.properties.property = {};
        } else if (label == "value" && !value && field_type == "dependent") {
          data.properties.property = null;
        }
        return data;
      }
      return data;
    });

    setActions([...actionList]);
  }

  // Render different components based on field type
  function renderComponentSwitch(item, fxn, choices, key) {
    if (fieldType == "dependent") {
      return (
        <SingleSelectText
          value={item?.value}
          callbackfn={fxn}
          choices={choices}
          val={key}
          fieldType={fieldType}
          setIssueId={setIssueId}
          setSubIssueId={setSubIssueId}
        />
      );
    } else if (fieldType == "boolean") {
      return (
        <BooleanInput
          value={item?.value}
          callbackfn={fxn}
          fieldType={fieldType}
        />
      );
    } else if (fieldType == "integer") {
      return (
        <Text
          value={item?.value}
          callbackfn={fxn}
          number={true}
          fieldType={fieldType}
        />
      );
    } else if (fieldType == "dropdown") {
      return (
        <SingleSelectText
          value={item?.value || []}
          callbackfn={fxn}
          choices={choices}
          key={key}
          fieldType={fieldType}
        />
      );
    } else {
      return (
        <Text value={item?.value} callbackfn={fxn} fieldType={fieldType} />
      );
    }
  }

  // Check if the value is valid
  function checkValidValue(val) {
    if (!val) return false;
    if (Array.isArray(val)) {
      return val.length > 0;
    }
    return val !== "";
  }

  return (
    <div className={styles.action_flex2}>
      <div>
        <div className={styles.action_flex}>
          {/* Render PropertyIssueRender component */}
          <PropertyIssueRender
            actions={actions}
            ticketFields={ticketFields}
            item={item}
            setFieldType={setFieldType}
            choices={issueChoices}
            handleTypeChange={handleTypeChange}
            renderComponentSwitch={renderComponentSwitch}
          />
        </div>
      </div>
      <div>
        {/* Render PropertySubIssueRender component */}
        {item?.properties?.property &&
          fieldType == "dependent" &&
          checkValidValue(item?.properties?.value) && (
            <div className={styles.action_flex}>
              <PropertySubIssueRender
                actions={actions}
                setActions={setActions}
                ticketFields={ticketFields}
                issueData={item}
                item={item?.properties?.property}
                setFieldType={setFieldType}
                choices={subIssueChoices}
                handleTypeChange={handleTypeChange}
                renderComponentSwitch={renderComponentSwitch}
                id={issueId}
                fieldDict={fieldDict}
              />
            </div>
          )}
      </div>
      <div>
        {/* Render PropertyFurtherRender component */}
        {item?.properties?.property?.property &&
          fieldType == "dependent" &&
          checkValidValue(item?.properties?.property?.value) && (
            <div className={styles.action_flex}>
              <PropertyFurtherRender
                actions={actions}
                setActions={setActions}
                ticketFields={ticketFields}
                issueData={item}
                subIssueData={item?.properties?.property}
                item={item?.properties?.property?.property}
                setFieldType={setFieldType}
                choices={furtherChoices}
                handleTypeChange={handleTypeChange}
                renderComponentSwitch={renderComponentSwitch}
                id={subIssueId}
                fieldDict={fieldDict}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default Property;
