// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***role*****/
.style_container__6EclK {
  display: flex;
  flex-direction: column;
  margin: 1vh 2vw;
  gap: 15px;
  padding: 0.3vh 0.5vw;
  height: 85vh;
  overflow: auto;
}

.style_container__6EclK::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.style_btncontainer__xNZrB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 2vw;
}

/*****role list****/
.style_wrapper__nt9Wj {
  border-radius: 4px;
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgb(24 50 71 / 30%), 0 2px 6px 0 rgb(22 29 37 / 5%);
  transition: transform 0.4s ease-in-out;
  display: flex;
  flex-direction: row;
  padding: 1vh 1vw;
}
.style_item_row__5G184 {
  flex: 5 1;
  display: flex;
  flex-direction: row;
}
.style_item_row__5G184 * {
  flex: 1 1;
}
.style_col_text_bold__OWgrC {
  color: #183247;
  font-weight: 500;
  word-break: break-word;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  font-size: 14px;
  text-transform: capitalize;
}
.style_col_text_small__48NFl {
  color: #475867;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/Teams/Roles/css/style.module.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,SAAS;EACT,oBAAoB;EACpB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa,EAAE,iDAAiD;AAClE;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA,mBAAmB;AACnB;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,sBAAsB;EACtB,yEAAyE;EACzE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,SAAO;EACP,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,SAAO;AACT;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;EACrB,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":["/***role*****/\n.container {\n  display: flex;\n  flex-direction: column;\n  margin: 1vh 2vw;\n  gap: 15px;\n  padding: 0.3vh 0.5vw;\n  height: 85vh;\n  overflow: auto;\n}\n\n.container::-webkit-scrollbar {\n  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */\n}\n\n.btncontainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1vh 2vw;\n}\n\n/*****role list****/\n.wrapper {\n  border-radius: 4px;\n  border: 2px solid transparent;\n  background-color: #fff;\n  box-shadow: 0 0 1px 0 rgb(24 50 71 / 30%), 0 2px 6px 0 rgb(22 29 37 / 5%);\n  transition: transform 0.4s ease-in-out;\n  display: flex;\n  flex-direction: row;\n  padding: 1vh 1vw;\n}\n.item_row {\n  flex: 5;\n  display: flex;\n  flex-direction: row;\n}\n.item_row * {\n  flex: 1;\n}\n.col_text_bold {\n  color: #183247;\n  font-weight: 500;\n  word-break: break-word;\n  -ms-word-wrap: break-word;\n  word-wrap: break-word;\n  font-size: 14px;\n  text-transform: capitalize;\n}\n.col_text_small {\n  color: #475867;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__6EclK`,
	"btncontainer": `style_btncontainer__xNZrB`,
	"wrapper": `style_wrapper__nt9Wj`,
	"item_row": `style_item_row__5G184`,
	"col_text_bold": `style_col_text_bold__OWgrC`,
	"col_text_small": `style_col_text_small__48NFl`
};
export default ___CSS_LOADER_EXPORT___;
