import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import styles from "./css/style.module.css";
import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import TemplateList from "./components/Template/TemplateList";
import AddTemplate from "./components/Template/AddTemplate";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as NoFound } from "../../assets/NoFound.svg";

function Whatsapp() {
  const appContext = useContext(AppContext);
  let [loader, setLoader] = useState(false);
  let [templates, setTemplates] = useState([]);
  let [next, setNext] = useState(false);
  let [page, setPage] = useState(1);

  useEffect(() => {
    appContext.setPage("Whatsapp");
    appContext.setTitle("Whatsapp");
    get_templates_data();
  }, [appContext.reload]);

  async function get_templates_data() {
    const data = await get_data(
      `${API_URL}/convo/hook/template/v1/?page=1`,
      appContext
    );
    setTemplates(data?.data);
    setPage(data?.page);
    setNext(data?.has_next);
    setLoader(false);
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <div className={styles.header}>
        <h2>Whatsapp</h2>
        <div className={styles.upload_container}>
          <AddTemplate />
        </div>
      </div>

      <InfiniteScroll
        dataLength={templates?.length}
        pageStart={1}
        scrollableTarget="scrollableDiv"
        next={function () {
          get_data(
            `${API_URL}/convo/hook/template/v1/?page=${page}`,
            appContext
          ).then(function (data) {
            if (data) {
              setTemplates(templates.concat(data.data));
              setNext(data?.has_next);
              setPage(data?.page);
            }
          });
        }}
        hasMore={next}
        loader={<h4 style={{ textAlign: "center" }}>Loading ... </h4>}
        endMessage={
          templates?.length > 0 ? (
            <p style={{ textAlign: "center", marginTop: "1vh" }}>
              <b>Yay! You have seen all templates !!</b>
            </p>
          ) : (
            <></>
          )
        }
      >
        <div className={styles.template_wrapper}>
          {templates?.length > 0 ? (
            templates?.map((item, idx) => (
              <TemplateList item={item} key={idx} />
            ))
          ) : (
            <h1 className={styles.no_user}>
              <NoFound />
              <p className={styles.no_user_text}>No Templates found</p>
            </h1>
          )}
        </div>
      </InfiniteScroll>
    </>
  );
}

export default Whatsapp;
