import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import styles from "./styles.module.css";
import Activity from "./Activity";

function TicketActivity({ data }) {
  let [activityData, setActivityData] = useState([]);
  function handleActivityData(data) {
    let payload = {};
    data?.map((item) => {
      let { date, ...info } = item;
      if (payload[item.date]) {
        payload[item.date] = [...payload[item.date], info];
      } else {
        payload[item.date] = [info];
      }
    });

    let result = [];
    Object.keys(payload)?.map((item) => {
      result.push({ title: item, info: payload[item] });
    });

    return result;
  }

  useEffect(() => {
    let info = handleActivityData(data);
    setActivityData(info);
  }, [data]);

  const [itemHeights, setItemHeights] = useState([]);

  useEffect(() => {
    // Calculate heights for each item based on content or any other criteria
    const heights = activityData.map((item) => calculateHeight(item));
    setItemHeights(heights);
  }, [activityData]); // Make sure to include any dependencies needed for calculating the heights

  // Function to calculate height for each item
  const calculateHeight = (item) => {
    // Implement your logic to calculate height based on item content
    // Example: counting number of lines and multiplying by line height
    const lineCount = item.info.length; // Assuming info is an array
    const lineHeight = 20; // Example line height in pixels
    return lineCount * lineHeight; // Adjust as needed
  };

  return (
    <div className={styles.activity_container}>
      {activityData?.length && (
        <Chrono
          items={activityData}
          mode="VERTICAL"
          slideShow
          borderLessCards={true}
          allowDynamicUpdate={true}
          slideItemDuration={4000}
          scrollable={{ scrollbar: false }}
          cardHeight={(index) => itemHeights[index]}
          focusActiveItemOnLoad={false}
          activeItemIndex={0}
          showEndButton={false} // Hide the "Load More" button
        >
          {activityData?.map((item, index) => {
            return (
              <div key={index} className={styles.container}>
                <div className={styles.customer_details}>
                  {item?.info?.map((val, subIndex) => (
                    <Activity idx={`${index}-${subIndex}`} val={val} />
                  ))}
                </div>
              </div>
            );
          })}
        </Chrono>
      )}
    </div>
  );
}

export default TicketActivity;
