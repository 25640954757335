import React from "react";

function Label(props) {
  function handleClick() {
    if (props.onClick) {
      props.onClick();
    }
  }
  return (
    <div className={props.className} onClick={handleClick}>
      {props.icon}
      <h3>{props.name}</h3>
    </div>
  );
}

export default Label;
