import React from "react";
import styles from "../../css/sla.module.css";
import SlaInput from "./components/SlaInput";

function SlaTarget({ payload, setPayload }) {
  return (
    <div className={styles.sla_container}>
      <p className={styles.sla_label}>Set SLA target as:</p>
      <div className={styles.sla_wrapper}>
        <div className={styles.sla_header}>
          <label className={styles.sla_header_label}>
            First response time (min)
          </label>
          <label className={styles.sla_header_label}>
            Resolution time (min)
          </label>
        </div>
        <div className={styles.sla_item}>
          <SlaInput
            idx={1}
            payload={payload}
            setPayload={setPayload}
            name="first_response_time"
            value={payload?.first_response_time}
          />
          <SlaInput
            idx={2}
            payload={payload}
            setPayload={setPayload}
            name="resolution_time"
            value={payload?.resolution_time}
          />
        </div>
      </div>
    </div>
  );
}

export default SlaTarget;
