// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_ticket_container__zgULN {
  display: flex;
  align-items: center;
  background-color: #ebeff3;
  /* padding: 0vh 1vw; */
  /* min-height: 90vh; */
  /* height: 68dvh; */
  overflow: hidden;
  flex: 0.7 1;
}

.dashboard_break_wrapper__1z65R {
  display: flex;
  justify-content: space-between;
  height: 93vh;
}

.dashboard_add_btn__UWJT- {
  background-color: #264966;
  border: 1px solid var(--primary-color);
  background-image: linear-gradient(to bottom, #264966, var(--primary-color));
  color: #fff;
  padding: 0.5vh 0.5vw;
  border: none;
  outline: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard_break_sidebar__G8hun {
  flex: 0.2 1;
  height: 90vh;
}

@media screen and (max-width: 1300px) {
  /* .ticket_container {
    flex-direction: column;
  } */

  .dashboard_break_sidebar__G8hun {
    flex: 0.25 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/Teams/Breaks/css/dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,WAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,sCAAsC;EACtC,2EAA2E;EAC3E,WAAW;EACX,oBAAoB;EACpB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAS;EACT,YAAY;AACd;;AAEA;EACE;;KAEG;;EAEH;IACE,YAAU;EACZ;AACF","sourcesContent":[".ticket_container {\n  display: flex;\n  align-items: center;\n  background-color: #ebeff3;\n  /* padding: 0vh 1vw; */\n  /* min-height: 90vh; */\n  /* height: 68dvh; */\n  overflow: hidden;\n  flex: 0.7;\n}\n\n.break_wrapper {\n  display: flex;\n  justify-content: space-between;\n  height: 93vh;\n}\n\n.add_btn {\n  background-color: #264966;\n  border: 1px solid var(--primary-color);\n  background-image: linear-gradient(to bottom, #264966, var(--primary-color));\n  color: #fff;\n  padding: 0.5vh 0.5vw;\n  border: none;\n  outline: none;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.break_sidebar {\n  flex: 0.2;\n  height: 90vh;\n}\n\n@media screen and (max-width: 1300px) {\n  /* .ticket_container {\n    flex-direction: column;\n  } */\n\n  .break_sidebar {\n    flex: 0.25;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ticket_container": `dashboard_ticket_container__zgULN`,
	"break_wrapper": `dashboard_break_wrapper__1z65R`,
	"add_btn": `dashboard_add_btn__UWJT-`,
	"break_sidebar": `dashboard_break_sidebar__G8hun`
};
export default ___CSS_LOADER_EXPORT___;
