import React, { useContext } from "react";
import styles from "./style.module.css";
import { AppContext } from "../../../../../../App";
import { NavLink } from "react-router-dom";

function Navbar({ query, setQuery }) {
  const appContext = useContext(AppContext);

  return (
    <nav className={styles.navbar}>
      <div className={styles.subnav}>
        <NavLink
          className="dark-btn"
          to={{
            pathname: `/teams/group/add`,
          }}
        >
          New Group
        </NavLink>
      </div>

      <div className={styles.subnav}>
        <input
          className={styles.input}
          type="text"
          value={query}
          onChange={function (e) {
            setQuery(e.target.value);
          }}
          placeholder="Search by group name"
        />
      </div>
    </nav>
  );
}

export default Navbar;
