import React from "react";
import styles from "./style.module.css";
import Search from "../Search/index";
import { NavLink } from "react-router-dom";

function Navbar(props) {
  return (
    <nav className={styles.navbar}>
      <div className={styles.subnav} style={{ visibility: "hidden" }}>
        <NavLink
          className="dark-btn"
          to={{
            pathname: `/teams/agents/add`,
          }}
        >
          New Agent
        </NavLink>
      </div>
      <div className={styles.subnav}>
        <Search context={props.context} />
      </div>
    </nav>
  );
}

export default Navbar;
