// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_drawer__3TyJY {
  background: #fff;
  padding: 2vh 0.5vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border-right: 3px solid gray; */
}
.style_drawer__3TyJY::-webkit-scrollbar {
  width: 0;
}

.style_drawer__3TyJY a {
  text-decoration: none;
}

.style_drawer__3TyJY svg {
  font-size: 1.25rem;
}

.style_drawer_tab__qW\\+bN {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  /* color: #686868; */
  /* font-weight: 700; */
  width: 100%;
  padding: 1vh 0.5vw;
}

.style_drawer_tab__qW\\+bN:hover {
  background: #f5f7f9;
}
.style_active__\\+wR0X {
  background-color: #f1f5f5;
  /* color: white; */
}
.style_drawer_tab__qW\\+bN span {
  margin-left: 0.5vw;
}
.style_img__vsItT {
  height: 7.5vh;
  width: auto;
  margin: 5vh auto;
}
.style_inactive__mywW8 {
  width: 80px;
}

.style_flex__5I9DV {
  display: flex;
  gap: 10px;
}

.style_title_style__Stjf1 {
  text-transform: capitalize;
  font-weight: bolder !important;
  font-size: 15px;
}

.style_flex_col__8MeZa {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.style_sub_title_style__TGRGS {
  font-size: 12px;
  color: #475867;
}
/* @media screen and (max-width: 1300px) {
  .team_label {
    font-size: 17px !important;
  }
  .title_style {
    font-size: 13px !important;
  }
  .sub_title_style {
    font-size: 10px !important;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/components/AdminDrawer/style.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kCAAkC;AACpC;AACA;EACE,QAAQ;AACV;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;AACA;;;;;;;;;;GAUG","sourcesContent":[".drawer {\n  background: #fff;\n  padding: 2vh 0.5vw;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  /* border-right: 3px solid gray; */\n}\n.drawer::-webkit-scrollbar {\n  width: 0;\n}\n\n.drawer a {\n  text-decoration: none;\n}\n\n.drawer svg {\n  font-size: 1.25rem;\n}\n\n.drawer_tab {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 10px;\n  /* color: #686868; */\n  /* font-weight: 700; */\n  width: 100%;\n  padding: 1vh 0.5vw;\n}\n\n.drawer_tab:hover {\n  background: #f5f7f9;\n}\n.active {\n  background-color: #f1f5f5;\n  /* color: white; */\n}\n.drawer_tab span {\n  margin-left: 0.5vw;\n}\n.img {\n  height: 7.5vh;\n  width: auto;\n  margin: 5vh auto;\n}\n.inactive {\n  width: 80px;\n}\n\n.flex {\n  display: flex;\n  gap: 10px;\n}\n\n.title_style {\n  text-transform: capitalize;\n  font-weight: bolder !important;\n  font-size: 15px;\n}\n\n.flex_col {\n  display: flex;\n  flex-direction: column;\n  gap: 3px;\n}\n\n.sub_title_style {\n  font-size: 12px;\n  color: #475867;\n}\n/* @media screen and (max-width: 1300px) {\n  .team_label {\n    font-size: 17px !important;\n  }\n  .title_style {\n    font-size: 13px !important;\n  }\n  .sub_title_style {\n    font-size: 10px !important;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `style_drawer__3TyJY`,
	"drawer_tab": `style_drawer_tab__qW+bN`,
	"active": `style_active__+wR0X`,
	"img": `style_img__vsItT`,
	"inactive": `style_inactive__mywW8`,
	"flex": `style_flex__5I9DV`,
	"title_style": `style_title_style__Stjf1`,
	"flex_col": `style_flex_col__8MeZa`,
	"sub_title_style": `style_sub_title_style__TGRGS`
};
export default ___CSS_LOADER_EXPORT___;
