import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../css/style.module.css";
import {
  get_data,
  patch_data,
} from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import { AppContext } from "../../../../../App";
import { TeamContext } from "../../../../../Routes/AdminRoutes/Components/TeamsRoute";
import Select from "react-select";
import {
  getEcosystemOptions,
  getAssignmentOptions,
  getHoursOptions,
} from "./option";

function EditGroup() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { group_id } = useParams();

  let [hours, setHours] = useState([]);
  let [originalData, setOriginalData] = useState({});
  let [updateAgent, setUpdateAgent] = useState({
    name: "",
    working_hours: "",
    assignment: "",
    ecosystem: "",
  });

  useEffect(() => {
    get_data(`${API_URL}/convo/users/group/${group_id}/v1/`, appContext).then(
      function (data) {
        if (data) {
          setOriginalData({
            name: data?.data?.name,
            ecosystem: data?.data?.ecosystem,
            assignment: data?.data?.assignment,
            working_hours: data?.data?.working_hours,
          });
          setUpdateAgent({
            name: data?.data?.name,
            ecosystem: data?.data?.ecosystem,
            assignment: data?.data?.assignment,
            working_hours: data?.data?.working_hours,
          });
        }
      }
    );
  }, [group_id, appContext]);

  useEffect(() => {
    get_data(`${API_URL}/convo/users/working/hour/list/v1/`, appContext).then(
      function (data) {
        setHours(data?.data);
      }
    );
  }, [appContext.reload]);

  function handleUpdate(e) {
    e.preventDefault();
    const changes = Object.keys(updateAgent).filter(
      (key) => updateAgent[key] !== originalData[key]
    );
    if (changes?.length > 0) {
      const patchData = {};
      changes.forEach((key) => {
        patchData[key] = updateAgent[key];
      });

      patch_data(
        `${API_URL}/convo/users/group/${group_id}/v1/`,
        patchData,
        appContext,
        true
      ).then((patchedData) => {
        if (patchedData) {
          navigate("/teams/group");
        }
      });
    } else {
      appContext.setAlert("No changes found.", "alert_error");
    }
  }

  function handleChange(e) {
    setUpdateAgent({
      ...updateAgent,
      [e.target.name]: e.target.value,
    });
  }

  const optionsEcosystem = getEcosystemOptions(teamContext);
  const optionsAssignment = getAssignmentOptions(teamContext);
  const optionsArray = getHoursOptions(hours);

  function handleClose() {
    navigate("/teams/group");
  }

  function handleSelectChange(property) {
    return function (selectedOption) {
      setUpdateAgent({
        ...updateAgent,
        [property]: selectedOption.value,
      });
    };
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.edit_heading}>Edit Group</h2>
      <form className={styles.edit_form} onSubmit={handleUpdate}>
        <div className={styles.input_container}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            placeholder="Enter name"
            className={styles.input}
            name="name"
            value={updateAgent?.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Business Hours</label>
          <Select
            options={optionsArray}
            value={optionsArray?.find(
              (option) => option?.value === updateAgent?.working_hours
            )}
            onChange={handleSelectChange("working_hours")}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Assignment</label>
          <Select
            options={optionsAssignment}
            value={optionsAssignment?.find(
              (option) => option.value === updateAgent?.assignment
            )}
            onChange={handleSelectChange("assignment")}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Ecosystem</label>
          <Select
            options={optionsEcosystem}
            value={optionsEcosystem.find(
              (option) => option.value === updateAgent?.ecosystem
            )}
            onChange={handleSelectChange("ecosystem")}
            required
          />
        </div>

        <div className={styles.btn_div}>
          <button className="dark-btn" type="submit">
            Update Group
          </button>
          <button type="button" className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditGroup;
