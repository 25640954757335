import React from "react";
import styles from "../../css/field.module.css";
function TextField({ item, filter, setFilter, ticketFields, create, phone }) {
  function handleChange(e) {
    let val = e.target.value;
    let name = e.target.name;

    if (isNaN(val) && val != "" && phone) {
      if (val?.length) {
        setFilter({ ...filter, [name]: val.slice(0, val.length - 1) });
      } else {
        setFilter({ ...filter, [name]: "" });
      }

      return;
    }
    if (phone && val?.length > 10) {
      return;
    }

    setFilter({ ...filter, [name]: val });
  }
  return (
    <div className={styles.filter}>
      <div>
        <label className={styles.label}>
          {ticketFields?.field_dict?.[item?.key]?.label || "--"}
        </label>
      </div>
      <div>
        <input
          className={styles.input}
          type="text"
          placeholder={`Enter ${
            ticketFields?.field_dict?.[item?.key]?.label || "--"
          }`}
          name={item?.key}
          value={filter?.[item?.key]}
          onChange={handleChange}
          disabled={item?.can_edit === false && !create}
          required={item?.can_edit === false}
        />
      </div>
    </div>
  );
}

export default TextField;
