import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "../../../css/style.module.css";
import { customStyles } from "../../styles";

function EventFurtherRender({
  issueItem,
  subIssueItem,
  item,
  event,
  setEvent,
  idx,
  subOldChoices,
  subNewChoices,
}) {
  let otherConstant = [
    {
      label: "Any Value",
      value: "_=_=_=",
    },
    {
      label: "None",
      value: "",
    },
  ];
  const [furtherOldChoices, setFurtherOldChoices] = useState([]);
  const [furtherNewChoices, setFurtherNewChoices] = useState([]);

  function handleTypeChange(label, value) {
    item[label] = value;
    item.property = null;
    subIssueItem.property = item;
    issueItem.property = subIssueItem;

    let conditionData = event;
    conditionData.properties[idx] = issueItem;
    event = conditionData;
    setEvent({ ...event });
  }

  function handleOldChoice() {
    let sub_choices =
      subOldChoices?.find((info) => info?.label == subIssueItem?.old_value)
        ?.choices || [];
    sub_choices = sub_choices?.map((info) => ({
      ...info,
      value: info?.value || info?.label,
    }));
    setFurtherOldChoices([...otherConstant, ...sub_choices]);
  }

  function handleNewChoice() {
    let sub_choices =
      subNewChoices?.find((info) => info?.label == subIssueItem?.new_value)
        ?.choices || [];
    sub_choices = sub_choices?.map((info) => ({
      ...info,
      value: info?.value || info?.label,
    }));
    setFurtherNewChoices([...otherConstant, ...sub_choices]);
  }
  useEffect(() => {
    handleOldChoice();
    handleNewChoice();
  }, [subIssueItem, subOldChoices, subNewChoices]);
  return (
    <div className={styles.condition_item2}>
      {!subIssueItem?.old_value || subIssueItem?.old_value == "_=_=_=" ? (
        <div className={styles.from_flex}></div>
      ) : (
        <div className={styles.from_flex}>
          from
          <Select
            styles={customStyles}
            options={furtherOldChoices}
            isClearable={true}
            placeholder="old_value"
            className={styles.condition_select2}
            value={furtherOldChoices?.filter(
              (info) => info.value == item?.old_value
            )}
            onChange={(e) => {
              handleTypeChange(
                "old_value",
                e?.value || "",
                furtherOldChoices?.find((info) => info.value == e?.value)
              );
            }}
          />
        </div>
      )}
      {!subIssueItem?.new_value || subIssueItem?.new_value == "_=_=_=" ? (
        <div className={styles.from_flex}></div>
      ) : (
        <div className={styles.from_flex}>
          to
          <Select
            styles={customStyles}
            options={furtherNewChoices}
            isClearable={true}
            placeholder="new_value"
            className={styles.condition_select2}
            value={furtherNewChoices?.filter(
              (info) => info.value == item?.new_value
            )}
            onChange={(e) =>
              handleTypeChange(
                "new_value",
                e?.value || "",
                furtherOldChoices?.find((info) => info.value == e?.value)
              )
            }
          />
        </div>
      )}
    </div>
  );
}

export default EventFurtherRender;
