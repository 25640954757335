import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "../../../css/style.module.css";
import { customStyles } from "../../styles";

function EventSubIssueRender({
  issueItem,
  item,
  issueChoices,
  event,
  setEvent,
  idx,
  subOldChoices,
  subNewChoices,
  setSubOldChoices,
  setSubNewChoices,
}) {
  let otherConstant = [
    {
      label: "Any Value",
      value: "_=_=_=",
    },
    {
      label: "None",
      value: "",
    },
  ];

  function handleTypeChange(label, value, key) {
    let data = { ...item, [label]: value };

    if (
      (!data.old_value || data.old_value == "_=_=_=") &&
      (!data.new_value || data.new_value == "_=_=_=")
    ) {
      data.property = null;
    }

    if (label == "old_value" || label == "new_value") {
      if (!data?.property) {
        if (data?.old_value && data?.old_value != "_=_=_=") {
          if (!data?.property) {
            data.property = {};
          }
          data.property.old_value = "_=_=_=";
        }
        if (data?.new_value && data?.new_value != "_=_=_=") {
          if (!data?.property) {
            data.property = {};
          }
          data.property.new_value = "_=_=_=";
        }

        if (data?.property?.new_value || data?.property?.old_value) {
          if (!data?.property) {
            data.property = {};
          }
          data.property.key = key;
        }
      } else {
        if (!data?.property?.old_value && !data?.property?.new_value) {
          data.property = null;
        } else {
          data.property = { ...data.property, [label]: "_=_=_=" };
        }
      }
    }

    issueItem.property = data;

    let conditionData = event;
    conditionData.properties[idx] = issueItem;
    event = conditionData;
    setEvent({ ...event });
  }

  function handleOldChoice() {
    let sub_choices =
      issueChoices?.find((info) => info?.label == issueItem?.old_value)
        ?.choices || [];
    sub_choices = sub_choices?.map((info) => ({
      ...info,
      value: info?.value || info?.label,
    }));
    setSubOldChoices([...otherConstant, ...sub_choices]);
  }

  function handleNewChoice() {
    let sub_choices =
      issueChoices?.find((info) => info?.label == issueItem?.new_value)
        ?.choices || [];
    sub_choices = sub_choices?.map((info) => ({
      ...info,
      value: info?.value || info?.label,
    }));
    setSubNewChoices([...otherConstant, ...sub_choices]);
  }
  useEffect(() => {
    handleOldChoice();
    handleNewChoice();
  }, [issueChoices, issueItem]);
  return (
    <div className={styles.condition_item2}>
      {!issueItem?.old_value || issueItem?.old_value == "_=_=_=" ? (
        <div className={styles.from_flex}></div>
      ) : (
        <div className={styles.from_flex}>
          from
          <Select
            styles={customStyles}
            options={subOldChoices}
            isClearable={true}
            placeholder="old_value"
            className={styles.condition_select2}
            value={subOldChoices?.filter(
              (info) => info.value == item?.old_value
            )}
            onChange={(e) => {
              handleTypeChange(
                "old_value",
                e?.value || "",
                issueChoices
                  ?.find((info) => info?.choices?.length)
                  ?.choices?.find((info) => info?.choices?.length)?.choices?.[0]
                  ?.key
              );
            }}
          />
        </div>
      )}
      {!issueItem?.new_value || issueItem?.new_value == "_=_=_=" ? (
        <div className={styles.from_flex}></div>
      ) : (
        <div className={styles.from_flex}>
          to
          <Select
            styles={customStyles}
            options={subNewChoices}
            isClearable={true}
            placeholder="new_value"
            className={styles.condition_select2}
            value={subNewChoices?.filter(
              (info) => info.value == item?.new_value
            )}
            onChange={(e) =>
              handleTypeChange(
                "new_value",
                e?.value || "",
                issueChoices
                  ?.find((info) => info?.choices?.length)
                  ?.choices?.find((info) => info?.choices?.length)?.choices?.[0]
                  ?.key
              )
            }
          />
        </div>
      )}
    </div>
  );
}

export default EventSubIssueRender;
