// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}
.row {
  display: flex;
  flex-direction: row;
}

.col_4 {
  flex: 6 1;
}
.sticky {
  position: sticky;
  top: 0;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
}
.pipe {
  border-left: 2px solid #9a9a9a;
}
.pointer {
  cursor: pointer;
}
.logout_btn {
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.route_height {
  flex-grow: 1;
  overflow: auto;
}

@media (max-width: 900px) {
  * {
    font-size: 10px !important;
  }
}
/**************************************************************/
:root {
  --primary-color: #12344d;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV;uCACqC;AACvC;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;AACA;EACE,gBAAgB;EAChB,MAAM;EACN,sCAAsC;AACxC;AACA;EACE,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;AACA,+DAA+D;AAC/D;EACE,wBAAwB;AAC1B","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto,\n    \"Helvetica Neue\", Arial, sans-serif;\n}\n.row {\n  display: flex;\n  flex-direction: row;\n}\n\n.col_4 {\n  flex: 6;\n}\n.sticky {\n  position: sticky;\n  top: 0;\n  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);\n}\n.pipe {\n  border-left: 2px solid #9a9a9a;\n}\n.pointer {\n  cursor: pointer;\n}\n.logout_btn {\n  cursor: pointer;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n}\n\n.route_height {\n  flex-grow: 1;\n  overflow: auto;\n}\n\n@media (max-width: 900px) {\n  * {\n    font-size: 10px !important;\n  }\n}\n/**************************************************************/\n:root {\n  --primary-color: #12344d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
