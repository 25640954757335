import React, { Suspense, useContext } from "react";
import { isAgentLoggedIn } from "../../cruxreactlib/auth";
import AdminDrawer from "../../components/AdminDrawer";
import { Navigate, Outlet } from "react-router-dom";
import styles from "../../cruxreactlib/common.module.css";
import { AppContext } from "../../App";

function AdminPanelRoutes() {
  const appContext = useContext(AppContext);
  return isAgentLoggedIn() ? (
    <div
      className={styles.admin_panel_flex}
      style={{
        height: appContext.showContent ? "" : `calc(100vh - 7.5vh)`,
      }}
    >
      <AdminDrawer
        className={styles.admin_col_1}
        showContent={appContext.showContent}
      />
      <div className={styles.admin_col_4}>
        <Suspense
          fallback={
            <div className="loader_container">
              <div className="loader"></div>
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </div>
  ) : (
    <Navigate to="/validate" replace={true} />
  );
}

export default AdminPanelRoutes;
