import React from "react";
import { AppContext } from "../../../../../App";
import { useState, useContext, useEffect } from "react";
import {
  get_data,
  patch_data,
} from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import styles from "./css/style.module.css";
import { useNavigate, useParams } from "react-router-dom";

function EditCannedResponse() {
  let [loader, setLoader] = useState(false);
  let [cannedResponse, setCannedResponse] = useState({});
  const appContext = useContext(AppContext);
  const { canned_response_id } = useParams();
  const navigate = useNavigate();

  useEffect(
    function () {
      // const isValidInteger = /^\d+$/.test(canned_response_id);
      // if (!isValidInteger) {
      //   navigate("/home");
      //   return;
      // }
      appContext.setPage("edit canned response");
      get_canned_response_details();
    },
    [appContext.reload, canned_response_id, navigate]
  );

  function get_canned_response_details() {
    get_data(
      `${API_URL}/convo/solution/canned/response/${canned_response_id}/v1/`,
      appContext
    ).then(function (data) {
      if (data) {
        appContext.setTitle(data.data.title);
        setCannedResponse(data.data);
        setLoader(false);
      }
    });
  }

  function convertDateFormat(dateString) {
    const dateParts = dateString.split("/");
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2].substring(2);
    return year + month + day;
  }

  function handleSubmit(e) {
    // let converted_date = convertDateFormat(cannedResponse.n_slot_id);
    let body = {
      description: cannedResponse.description,
      title: cannedResponse.title,
      n_slot_id: cannedResponse.n_slot_id,
    };

    if (cannedResponse.shortcut) {
      body.shortcut = cannedResponse.shortcut;
    }

    if (cannedResponse.description === "") {
      appContext.setAlert(
        "Please provide valid canned response",
        "alert_error"
      );
      return;
    }
    patch_data(
      `${API_URL}/convo/solution/canned/response/${canned_response_id}/v1/`,
      body,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        navigate(`/workflows/canned/response`);
      }
    });
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <div className="item-row-container">
      <div className={styles.article_edit} style={{ margin: "1.5vh 1vw" }}>
        <h3 className={styles.edit_label}>Edit Canned Response:</h3>
        <textarea
          value={cannedResponse.description}
          onChange={(event) => {
            setCannedResponse({
              ...cannedResponse,
              description: event.target.value,
            });
          }}
          rows={17}
          className={styles.textarea}
        />
        <div className={styles.shortcut_div}>
          <h3>Edit Shortcut:</h3>
          <input
            type="text"
            value={cannedResponse.shortcut}
            onChange={(event) => {
              setCannedResponse({
                ...cannedResponse,
                shortcut: event.target.value,
              });
            }}
            className={styles.input}
          />
        </div>

        <div className="btn-container">
          <button className={`btn ${styles.btn}`} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditCannedResponse;
