import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { ReactComponent as Edit } from "../../../assets/Admin/Edit.svg";
import { ReactComponent as Email } from "../../../assets/Admin/Email.svg";
import { ReactComponent as Phone } from "../../../assets/Admin/Phone.svg";
import { ReactComponent as FB } from "../../../assets/Admin/facebook.svg";
import { ReactComponent as Twitter } from "../../../assets/Admin/twitter.svg";

import styles from "../css/style.module.css";
import { NavLink, useNavigate } from "react-router-dom";

function User({ data }) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  let [close, setClose] = useState(false);
  let [randomColor, setRandomColor] = useState("#ffffff");
  let [show, setShow] = useState(false);

  useEffect(() => {
    const getRandomLightColor = () => {
      const getComponent = () => Math.floor(Math.random() * 128) + 128;
      const rgb = [getComponent(), getComponent(), getComponent()];
      return `#${rgb
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;
    };
    setRandomColor(getRandomLightColor());
  }, [data]);

  useEffect(
    function () {
      setClose(false);
      setShow(false);
    },
    [close]
  );

  function handleMouseEnter() {
    setShow(true);
  }
  function handleMouseLeave() {
    setShow(false);
  }

  function handleClick() {
    navigate(`/users/details/${data.id}`);
  }

  return (
    <div
      className={styles.user_wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.wrapper} onClick={handleClick}>
        <div className={styles.col}>
          <p
            className={styles.icon_div}
            style={{ backgroundColor: randomColor }}
          >
            {data?.name?.charAt(0)}
          </p>

          <p className={styles.text}>{data?.name || "--"}</p>
        </div>
        <div className={styles.col}>
          <Email />
          <p className={styles.text}>{data?.email || "--"}</p>
        </div>
        <div className={styles.col}>
          <FB />
          <p className={styles.text}>{data?.facebook_id || "--"}</p>
        </div>
        <div className={styles.col}>
          <Twitter />
          <p className={styles.text}>{data?.twitter_id || "--"}</p>
        </div>
        <div className={styles.col}>
          <Phone />
          <p className={styles.text}>{data?.phone || "--"}</p>
        </div>
      </div>

      <div
        className={styles.col}
        style={{ visibility: show ? "visible" : "hidden", flex: 0.1 }}
      >
        <NavLink
          className={styles.btn}
          to={{
            pathname: `/users/edit/${data?.id}`,
          }}
        >
          <Edit />
        </NavLink>
      </div>
    </div>
  );
}

export default User;
