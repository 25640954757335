import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { API_URL } from "../../../config";
import { delete_data } from "../../../cruxreactlib/networkhandler";
import Popup from "../../../cruxreactlib/utils/Popup/index";
import styles from "../css/popup.module.css";
import { ReactComponent as DeleteIcon } from "../../../assets/clusters/Delete.svg";

function DeleteCluster({ title, cluster, setShow }) {
  let [close, setClose] = useState(false);

  useEffect(
    function () {
      if (close) {
        setShow(false);
      }
      setClose(false);
    },
    [close]
  );

  const appContext = useContext(AppContext);

  function handleSubmit(e) {
    e.preventDefault();
    delete_data(
      `${API_URL}/convo/solution/cluster/${cluster}/v1/`,
      appContext,
      true
    ).then((data) => {
      setClose(true);
      setShow(false);
    });
  }

  return (
    <Popup
      btnName={<DeleteIcon />}
      btnStyling={styles.delete}
      closeState={close}
      setCloseState={setClose}
    >
      <h1 className={styles.heading}>Delete Cluster</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h3 className="center">
          Are you sure to delete this cluster: {title} ?
        </h3>
        <div className={styles.input_container}>&nbsp;</div>
        <div className={styles.input_container}>&nbsp;</div>
        <div className={styles.input_container}>&nbsp;</div>
        <div className={styles.input_container}>&nbsp;</div>
        <div className={`${styles.input_container} ${styles.submit_container}`}>
          <input className={styles.submit} type="submit" />
        </div>
      </form>
    </Popup>
  );
}

export default DeleteCluster;
