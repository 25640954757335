import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./css/style.module.css";
import { ReactComponent as Folder } from "../../assets/clusters/Folder.svg";
import { ReactComponent as Arrow } from "../../assets/clusters/Arrow.svg";
import { ReactComponent as UpArrow } from "../../assets/clusters/UpArrow.svg";

import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import { AppContext } from "../../App";
import Article from "./components/Article";
import CreateCluster from "./components/CreateCluster";
import CreateArticle from "./components/CreateArticle";
import ClusterList from "./components/ClusterList";
import { checkClick } from "../../cruxreactlib/utils/utility";

function Clusters() {
  const appContext = useContext(AppContext);
  let [toggle, setToggle] = useState(true);
  let [cluster, setCluster] = useState(-1);
  let [allClusters, setAllClusters] = useState([]);
  let [articles, setArticles] = useState([]);
  let [loader, setLoader] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  const switchRef = useRef(null);
  const handleNewButtonClick = () => {
    setIsOpen(!isOpen);
  };

  function handleToggle() {
    setToggle(!toggle);
  }

  useEffect(() => {
    appContext.setPage("cluster");
    appContext.setTitle("All Clusters");
    get_cluster_details();
    get_article_details(cluster);
    setIsOpen(false);
  }, [appContext.reload]);

  useEffect(() => {
    get_article_details(cluster);
  }, [cluster]);

  function get_cluster_details() {
    get_data(`${API_URL}/convo/solution/cluster/v1/`, appContext).then(
      (data) => {
        if (data) {
          setAllClusters(data?.data);
          if (cluster == -1) {
            setCluster(data?.data[0]?.id);
          }
          setLoader(false);
        }
      }
    );
  }

  function get_article_details(id) {
    setCluster(id);
    if (id === -1 || !id) {
      return;
    }
    get_data(
      `${API_URL}/convo/solution/article/list/${id}/v1/`,
      appContext
    ).then((data) => {
      if (data) {
        setArticles(data.data);
        setLoader(false);
      }
    });
  }

  // window.addEventListener("click", function (event) {
  //   checkClick(event, switchRef, setIsOpen);
  // });

  return (
    <>
      <header className={styles.header}>
        <div className={styles.heading_col_1}>
          <p className={styles.heading}>Clusters</p>
          <div className={styles.cluster_cta} ref={switchRef}>
            <button className={styles.button} onClick={handleNewButtonClick}>
              + New
            </button>
            {isOpen && (
              <div className={styles.optionsContainer}>
                <button className={styles.optionButton}>
                  <CreateCluster />
                </button>
                <button className={styles.optionButton}>
                  <CreateArticle
                    cluster={cluster}
                    setCluster={setCluster}
                    allClusters={allClusters}
                    setIsOpen={setIsOpen}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.heading_col_2}>
          <p className={styles.heading}>Articles({articles?.length})</p>
          <p className={styles.heading}>Date</p>
          <p className={styles.heading}>View</p>
          <p className={styles.heading}>Suggested</p>
          <p className={styles.heading}>Helpful</p>{" "}
          <p className={styles.heading}>Del</p>
        </div>
      </header>
      <div className={styles.app}>
        <section className={styles.col_1}>
          <div className={`${styles.folder} ${toggle ? styles.expanded : ""}`}>
            <p>
              <Folder />
              <span>General</span>
            </p>
            <p onClick={handleToggle}>{toggle ? <UpArrow /> : <Arrow />}</p>
          </div>
          <div className={styles.list_wrapper}>
            {toggle &&
              allClusters?.map((item, idx) => (
                <ClusterList
                  key={idx}
                  item={item}
                  setCluster={setCluster}
                  cluster={cluster}
                />
              ))}
          </div>
        </section>

        <section className={styles.col_2}>
          <div className={styles.article_wrapper}>
            {articles?.length ? (
              articles?.map((item, idx) => (
                <Article key={idx} item={item} idx={idx} />
              ))
            ) : (
              <div className={styles.no_agent}>
                <h1>No Data Found</h1>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default Clusters;
