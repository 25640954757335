import React, { useContext, useEffect, useState } from "react";
import styles from "../../css/template.module.css";
import Popup from "../../../../cruxreactlib/utils/Popup";
import { AppContext } from "../../../../App";
import Select from "react-select";
import { patch_data, post_data } from "../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../config";

function AddTemplate({ data, isEdit }) {
  const appContext = useContext(AppContext);
  let [close, setClose] = useState(false);
  let [loader, setLoader] = useState(false);
  let [templateDetails, setTemplateDetails] = useState({
    name: "",
    vf_template_id: "",
    variable_ct: "",
    type: "",
  });

  useEffect(
    function () {
      setClose(false);
    },
    [close]
  );

  useEffect(() => {
    if (isEdit && isEdit !== undefined) {
      setLoader(true);
      setTemplateDetails({
        name: data?.name,
        vf_template_id: data?.vf_template_id,
        variable_ct: data?.variable_ct,
        type: data?.type,
      });
      setLoader(false);
    }
  }, [isEdit, data]);

  function handleChange(e) {
    setClose(false);
    setTemplateDetails({ ...templateDetails, [e.target.name]: e.target.value });
  }

  const handleSelectChange = (selectedOption) => {
    setTemplateDetails((prevState) => ({
      ...prevState,
      type: selectedOption.value,
    }));
  };

  function handleAddTemplate(e) {
    e.preventDefault();

    if (templateDetails?.variable_ct > 50) {
      appContext.setAlert("Not more than 50 variables allowed", "alert_error");
      return;
    }

    let payload = { ...templateDetails };
    if (payload.type === "") {
      delete payload.type;
    }

    if (isEdit) {
      const differences = Object.keys(payload).reduce((acc, key) => {
        if (payload[key] !== data[key]) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});

      if (Object.keys(differences).length === 0) {
        appContext.setAlert("No changes found", "alert_info");
        return;
      }

      patch_data(
        `${API_URL}/convo/hook/template/${data?.id}/v1/`,
        differences,
        appContext,
        true
      ).then((response) => {
        if (response) {
          handleClear();
        }
      });
    } else {
      post_data(
        `${API_URL}/convo/hook/template/v1/`,
        payload,
        appContext,
        true
      ).then((response) => {
        if (response) {
          handleClear();
        }
      });
    }
  }

  function handleClear() {
    setTemplateDetails({
      name: "",
      vf_template_id: "",
      variable_ct: "",
      type: "",
    });

    setClose(true);
  }

  const options = [
    { label: "Text", value: "text" },
    { label: "Image", value: "image" },
    { label: "Video", value: "video" },
    { label: "Document", value: "document" },
  ];

  return (
    <Popup
      btnName={isEdit ? "Edit" : "Add Template"}
      btnStyling="btn"
      closeState={close}
    >
      {loader ? (
        <div className="loader_container">
          <div className="loader"></div>
        </div>
      ) : (
        <form onSubmit={handleAddTemplate}>
          <div className={styles.add_template_header}>
            <h2>{isEdit ? "Edit" : "Add"} Template</h2>
          </div>
          <div className={styles.add_template_body}>
            <div className={styles.info}>
              <div className={styles.div}>
                <p>Template Name</p>
                <input
                  type="text"
                  placeholder="Template Name"
                  className={styles.input}
                  name="name"
                  value={templateDetails?.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.div}>
                <p>Template ID</p>
                <input
                  type="number"
                  placeholder="Template Id"
                  className={styles.input}
                  name="vf_template_id"
                  value={templateDetails?.vf_template_id}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className={styles.info}>
              <div className={styles.div}>
                <p>Variable Count</p>
                <input
                  type="number"
                  placeholder="Variable Count"
                  className={styles.input}
                  name="variable_ct"
                  value={templateDetails?.variable_ct}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.div}>
                <p>Template Type</p>
                <Select
                  options={options}
                  onChange={handleSelectChange}
                  value={options.find(
                    (option) => option.value === templateDetails.type
                  )}
                  placeholder="Select Type"
                />
              </div>
            </div>
            <div className={styles.btn_div}>
              <button type="submit" className="dark-btn">
                {isEdit ? "Confirm" : "Add"}
              </button>
            </div>
          </div>
        </form>
      )}
    </Popup>
  );
}

export default AddTemplate;
