const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "4px",
    backgroundColor: "#f3f5f7",
    backgroundImage: "linear-gradient(to bottom, #fff, #f3f5f7)",

    border: state.isFocused ? "1px solid #66afe9" : "1px solid #ccc",
    boxShadow: state.isFocused ? "0 0 0 1px #66afe9" : "none",
    transition: "border-color 0.2s ease",
    "&:hover": {
      border: state.isFocused ? "1px solid #66afe9" : "1px solid #ccc",
    },
    fontFamily: "Arial, sans-serif", // Set font family
    fontSize: "14px", // Set font size
    color: "#333", // Set text color
    background: "#fff",
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif", // Set font family
    fontSize: "14px", // Set font size
    color: "#333", // Set text color
  }),
};

export { customStyles };
