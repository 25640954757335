import React, { useState } from "react";
import styles from "../css/style.module.css";
import DeleteCluster from "./DeleteCluster";
import { ReactComponent as SubFolder } from "../../../assets/clusters/SubFolder.svg";
function ClusterList({ setCluster, item, cluster }) {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`${styles.list} ${
        cluster === item?.id ? styles.selected : ""
      }`}
      key={item?.id}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <p className={styles.align} onClick={() => setCluster(item?.id)}>
        <SubFolder />
        {item?.title}
      </p>

      {show && (
        <DeleteCluster
          title={item?.title}
          cluster={item?.id}
          setShow={setShow}
        />
      )}
    </div>
  );
}

export default ClusterList;
