import React, { useEffect } from "react";
import Select from "react-select";
import styles from "../../css/components.module.css";
import { customStyles } from "../styles";
function BooleanInput({ value, callbackfn, fieldType }) {
  function handleChange(e) {
    if (e?.value !== undefined) {
      callbackfn("value", e.value, fieldType);
    } else {
      callbackfn("value", "", fieldType);
    }
  }

  const choices = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  return (
    <Select
      styles={customStyles}
      options={choices}
      isClearable={true}
      placeholder="value"
      className={styles.select_field}
      value={choices?.filter((info) => info.value === value)}
      onChange={handleChange}
    />
  );
}

export default BooleanInput;
