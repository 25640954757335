import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import styles from "../css/style.module.css";
import { ReactComponent as DownloadIcon } from "../../../assets/Dialler/Download.svg";

const DownloadCsv = ({ agents }) => {
  const [existingHeaders, setExistingHeaders] = useState([]);

  useEffect(() => {
    // Fetch the existing CSV file content
    fetch("/ticket/dialler.csv")
      .then((response) => response.text())
      .then((csvContent) => {
        // Parse the CSV content to get the headers
        const lines = csvContent.split("\n");
        if (lines.length > 0) {
          const firstLine = lines[0].split(",");
          setExistingHeaders(firstLine);
        }
      })
      .catch((error) => console.error("Error fetching CSV file:", error));
  }, []);

  // Extract unique agent.key values to use as additional headers
  const uniqueHeaders = [...new Set(agents.map((agent) => agent.key))];

  // Headers including existing headers and unique agent.key values
  const headers = [...existingHeaders, ...uniqueHeaders];
  // console.log("he", headers);
  // Mapping agents array to construct CSV data
  const data = [
    // Only one row with headers
    Object.fromEntries(
      ["Field Name", ...uniqueHeaders].map((header) => [header, ""])
    ),
  ];

  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={`/ticket/dialler.csv`}
      className={styles.link}
    >
      <DownloadIcon />
    </CSVLink>
  );
};

export default DownloadCsv;
