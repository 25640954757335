// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_input__TcHX- {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  border: none;
  outline: none;
  padding: 1.3vh 0.4vw;
  border-radius: 10px;
  background-color: white;
}

.style_mention__1jMK5 {
  flex: 1 0 100%;
}
.style_btn__Me8w6 {
  flex: 1 1;
  position: absolute;
  bottom: 3%;
  right: 2%;
}
.style_conditionClassName__JzgFr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  outline: none;
  padding: 6px;
  border-radius: 4px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/modules/AIResponses/MentionArea /css/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,SAAO;EACP,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,iCAAiC;EACjC,gBAAgB;AAClB","sourcesContent":[".input {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  position: relative;\n  border: none;\n  outline: none;\n  padding: 1.3vh 0.4vw;\n  border-radius: 10px;\n  background-color: white;\n}\n\n.mention {\n  flex: 1 0 100%;\n}\n.btn {\n  flex: 1;\n  position: absolute;\n  bottom: 3%;\n  right: 2%;\n}\n.conditionClassName {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  position: relative;\n  outline: none;\n  padding: 6px;\n  border-radius: 4px;\n  background-color: hsl(0, 0%, 100%);\n  border: 1px solid hsl(0, 0%, 80%);\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `style_input__TcHX-`,
	"mention": `style_mention__1jMK5`,
	"btn": `style_btn__Me8w6`,
	"conditionClassName": `style_conditionClassName__JzgFr`
};
export default ___CSS_LOADER_EXPORT___;
