import React from "react";
import styles from "../css/style.module.css";

function AutomationSidebar() {
  return (
    <div className={styles.automation_sidebar}>
      <h3 className={styles.sidebar_header}>
        <span> Rules that run on ticket creation</span>
      </h3>

      <div className={styles.sidebar_container}>
        <p className={styles.sidebar_desc}>
          Ticket creation rules allow you to setup your support workflows by
          performing a predefined set of actions on newly created tickets.
        </p>
        <div class="text--italic">
          <p className={styles.sidebar_desc}>
            Example: For all emails related to refunds, you can{" "}
            <span class="text--bold">automatically:</span>
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Route</span> it to your Billing group
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Notify</span> your agent, and customer
            via email
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Set ticket properties</span> like
            Priority to Medium, and even
          </p>
          <p className={styles.sidebar_desc}>
            <span class="text--bold">- Trigger a webhook</span> to update the
            details in your billing software.
          </p>
        </div>
        <p className={styles.sidebar_desc}>
          Remember, the order of the ‘Ticket Creation’ rules are important. For
          each incoming ticket, the first matching rule will get executed, and
          stop. You can reorder the list of rules to have the most important
          rules on top.
        </p>
      </div>
    </div>
  );
}

export default AutomationSidebar;
