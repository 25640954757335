export const convertToObjectOptions = (originalObject) => {
  return Object.keys(originalObject).map((key) => ({
    value: originalObject[key],
    label: key,
  }));
};

export const getEcosystemOptions = (teamContext) =>
  convertToObjectOptions(teamContext?.constants?.ecosystem);

export const getAssignmentOptions = (teamContext) =>
  convertToObjectOptions(teamContext?.constants?.assignment);

export const getHoursOptions = (hours) =>
  hours?.map((item) => ({
    value: item?.id,
    label: item?.name || "Default",
  }));
