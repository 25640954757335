// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_chartBox__hYZde {
  background-color: rgb(255, 255, 255);
  color: rgb(18, 31, 67);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px,
    rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
  padding-bottom: 0;
  width: 49%;
}
.style_chart_header__9uHi- {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  /* background-color: red; */
}
.style_chart_header__9uHi- div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.style_chart_header__9uHi- div:nth-child(1) p {
  font-size: 16px;
  font-weight: 600;
}

.style_chart_dropdown__UYxKI {
  display: flex;
  gap: 10px;
}
.style_chart_dropdown__UYxKI div {
  border: 1px solid lightgray;
  border-radius: 4px;
}
.style_chart_dropdown__UYxKI div select {
  border: 0px;
  outline: 0px;
  font-size: 13px;
  font-weight: 500;
  width: 100px;
  border-radius: 4px;
  color: black;
  font-family: Montserrat, sans-serif;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/modules/RTM/components/Graphs/css/style.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,6DAA6D;EAC7D,sBAAsB;EACtB,gBAAgB;EAChB;;4CAE0C;EAC1C,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,mCAAmC;EACnC,uBAAuB;AACzB","sourcesContent":[".chartBox {\n  background-color: rgb(255, 255, 255);\n  color: rgb(18, 31, 67);\n  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  background-image: none;\n  overflow: hidden;\n  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px,\n    rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,\n    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;\n  border-radius: 8px;\n  padding: 24px;\n  height: 100%;\n  padding-bottom: 0;\n  width: 49%;\n}\n.chart_header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 2vh;\n  /* background-color: red; */\n}\n.chart_header div:nth-child(1) {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.chart_header div:nth-child(1) p {\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.chart_dropdown {\n  display: flex;\n  gap: 10px;\n}\n.chart_dropdown div {\n  border: 1px solid lightgray;\n  border-radius: 4px;\n}\n.chart_dropdown div select {\n  border: 0px;\n  outline: 0px;\n  font-size: 13px;\n  font-weight: 500;\n  width: 100px;\n  border-radius: 4px;\n  color: black;\n  font-family: Montserrat, sans-serif;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartBox": `style_chartBox__hYZde`,
	"chart_header": `style_chart_header__9uHi-`,
	"chart_dropdown": `style_chart_dropdown__UYxKI`
};
export default ___CSS_LOADER_EXPORT___;
