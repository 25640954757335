import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logout } from "../../assets/drawer/icon/Logout.svg";
import Label from "../../cruxreactlib/utils/Label";
import { ReactComponent as RightArrow } from "../../assets/Admin/RightArrow.svg";
import { navigateLogin } from "../../cruxreactlib/networkhandler";

function AdminHeader({ setShowContent, setSearchType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean); // Remove empty segments
    setCategory(pathSegments[0] || "");
    let subPath = pathSegments.slice(1).join(" ");
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (lastSegment.match(/^[0-9a-fA-F]{24}$/)) {
      // If the last segment is a MongoDB ObjectId-like string (24 characters hex)
      pathSegments[pathSegments.length - 1] = ""; // Replace with an empty string
    }
    setSubcategory(pathSegments.slice(1).join(" "));
    setSearchType("");
    if (subPath) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [location]);

  function handleClick() {
    setShowContent(false);
    setSearchType("");
    navigate("/teams");
  }

  function handleCategory() {
    setShowContent(false);
    setSearchType("");
    navigate(`/${category}`);
  }

  function handleLogout() {
    localStorage.clear();
    navigateLogin();
  }

  return (
    <header className={styles.header}>
      <div className={styles.subheader}>
        <Label
          name="Admin"
          className={styles.ticket_info}
          onClick={handleClick}
        />
        {category && (
          <>
            <RightArrow />
            <p className={styles.category_current} onClick={handleCategory}>
              {category}
            </p>
          </>
        )}
        {subcategory && (
          <>
            <RightArrow />
            <p className={styles.current}>{subcategory}</p>
          </>
        )}
      </div>

      <span className="logout_btn" onClick={handleLogout}>
        <Logout />
      </span>
    </header>
  );
}

export default AdminHeader;
