import React, { useEffect, useState } from "react";
import styles from "../AdminHeader/adminHeader.module.css";
import { useNavigate } from "react-router-dom";
import { ICON_URL } from "../../config";
import TabBar from "../../cruxreactlib/utils/TabBar";
function TeamsHeader({
  showContent,
  setShowContent,
  searchType,
  setSearchType,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    setSearchType("");
  }, []);

  useEffect(() => {
    routeNavigation(searchType);
  }, [searchType]);

  function routeNavigation(type) {
    if (type == "agents") {
      navigate("/teams/agents");
    } else if (type == "group") {
      navigate("/teams/group");
      setShowContent(true);
    } else if (type == "business_hour") {
      navigate("/teams/business-hour");
      setShowContent(true);
    } else if (type == "roles") {
      navigate("/teams/roles");
      setShowContent(true);
    } else if (type == "breaks") {
      navigate("/teams/breaks");
      setShowContent(true);
    }
  }

  function handleClick() {
    setSearchType("agents");
    setShowContent(true);
  }

  return (
    <div
      className={styles.team_container}
      style={{
        display: showContent && "none",
      }}
    >
      <p className={styles.team_label}>Team</p>

      <div className={styles.tab_layout}>
        <TabBar
          tabs={[
            {
              title: "Agents",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/agents-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Define agents' scope of work, type, language, and other details.",
              execute: { func: handleClick, value: "agents" },
              callbackfn: routeNavigation,
            },
            {
              title: "Group",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/groups-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Organize agents and receive notifications on unattended tickets. ",
              execute: { func: setSearchType, value: "group" },
              callbackfn: routeNavigation,
            },
            {
              title: "Business Hour",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/business-hours-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`,
              sub_title:
                "Define working hours and holidays to set expectations with customers",
              execute: { func: setSearchType, value: "business_hour" },
              callbackfn: routeNavigation,
            },
            {
              title: "Roles",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/roles-92da2ff51600a79b1e9a1524d0ed94f87545e7838239fced80bb77e4ba1aaec9.svg`,
              sub_title:
                "Provide and restrict fine-grained levels of access and privileges for agents.",
              execute: { func: setSearchType, value: "roles" },
              callbackfn: routeNavigation,
            },
            {
              title: "Breaks",
              icon: `${ICON_URL}/production/a/assets/images/new-admin/helpdesk-bc2bc9837bdebf2087cd563cf4340027cda296386573da0174d344b79be013c5.svg`,
              sub_title:
                "Provide and restrict fine-grained levels of breaks and privileges for agents.",
              execute: { func: setSearchType, value: "breaks" },
              callbackfn: routeNavigation,
            },
          ]}
          sub_title={true}
          searchType={searchType}
          styles={styles}
        />
      </div>
    </div>
  );
}

export default TeamsHeader;
