import React from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import { customStyles } from "../../styles";

function ConditionIssueRender({
  ticketFields,
  item,
  setFieldType,
  handleTypeChange,
  choices,
  setChoices,
  constantsMapping,
  fieldType,
  renderComponentSwitch,
}) {
  return (
    <>
      <div className={styles.arrow_wrapper}>
        <Select
          styles={customStyles}
          options={ticketFields
            ?.filter((info) => info.parent_field == "")
            ?.map((info) => {
              return { ...info, label: `if ${info.label}`, value: info.key };
            })}
          placeholder="Choose Condition"
          className={styles.condition_select1}
          onChange={(e) => {
            item.operator = "";
            setFieldType(e.field_type);
            handleTypeChange("key", e.value);
            setChoices(e?.choices || []);
          }}
          value={ticketFields?.filter((info) => info.key == item?.key)}
        />

        <Select
          styles={customStyles}
          options={constantsMapping?.operator_choices?.filter((info) => {
            return constantsMapping?.field_operator_mapping?.[
              fieldType
            ]?.includes(info.value);
          })}
          placeholder="Operator"
          className={styles.condition_select2}
          value={constantsMapping?.operator_choices?.filter(
            (info) => info.value == item?.operator
          )}
          onChange={(e) => {
            item.value = [];
            handleTypeChange("operator", e.value);
          }}
        />
      </div>

      {item?.operator !== "" ? (
        <div className={styles.condition_item2}>
          {renderComponentSwitch(item, handleTypeChange, choices, "issue")}
        </div>
      ) : null}
    </>
  );
}

export default ConditionIssueRender;
