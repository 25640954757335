import React from "react";
import styles from "../css/style.module.css";
function SlaSidebar() {
  return (
    <div className={styles.automation_sidebar}>
      <h3 className={styles.sidebar_header}>
        <span>SLA policy</span>
      </h3>

      <div className={styles.sidebar_container}>
        <p className={styles.sidebar_desc}>
          A service level agreement (SLA) policy lets you set standards of
          performance for your support team. You can set SLA policies for the
          time within which agents should respond to, and resolve tickets based
          on ticket priorities. You can choose whether you want each SLA rule to
          be calculated over calendar hours or your business hours. Your SLA
          Policies will be used in Freshdesk to determine the “Due By” time for
          each ticket.
        </p>
        <div class="text--italic">
          <h4 className={styles.sidebar_header}>
            <span> Using Multiple SLA Policies</span>
          </h4>

          <p className={styles.sidebar_desc}>
            You can have multiple SLA policies, triggered by specific conditions
            like the customer who requested the ticket, the group it is assigned
            to, its source and/or product.
          </p>

          <h5 className={styles.sidebar_header}>
            <span> The order of your SLA policies is important.</span>
          </h5>

          <p className={styles.sidebar_desc}>
            The first SLA Policy that matches all conditions for a ticket will
            be applied to it, so remember to order your important rules closer
            to the top
          </p>

          <h4 className={styles.sidebar_header}>
            <span>SLA reminders</span>
          </h4>

          <p className={styles.sidebar_desc}>
            You can set up reminders to make sure that agents are notified about
            the approaching due by time for tickets. The reminders can be for
            ticket response and resolution.
          </p>

          <p className={styles.sidebar_desc}>
            The reminder email can contain contextual information like the URL
            of the ticket and ticket priority and can be configured in Admin
            Email Notifications.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SlaSidebar;
