import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { get_data, post_data } from "../../../cruxreactlib/networkhandler";
import Select from "react-select";
import styles from "../css/popup.module.css";
import Popup from "../../../cruxreactlib/utils/Popup/index";
import { API_URL } from "../../../config";
import MentionArea from "../../../components/MentionArea/index";
function AiNote({}) {
  let [note, setNote] = useState();
  let [close, setClose] = useState(false);
  const [cannedResponses, setCannedResponses] = useState([]);
  const appContext = useContext(AppContext);
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
  };

  useEffect(
    function () {
      setClose(false);
      get_canned_responses();
    },
    [appContext.reload]
  );

  function get_canned_responses() {
    get_data(
      `${API_URL}/convo/solution/canned/response/list/v1/?ecosystem=T`,
      appContext
    ).then(function (data) {
      if (data) {
        setCannedResponses(data?.data);
      }
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setClose(true);
    post_data(`${API_URL}/convo/ticket/notes/v1/`, note, appContext, true).then(
      function (data) {
        if (data) {
          setClose(true);
        }
      }
    );
  }

  return (
    <Popup btnName={"Ai Note"} btnStyling="btn" closeState={close}>
      <h1 className={styles.heading}>Ai Note</h1>
      <form
        className={`${styles.form} ${styles.ai_form}`}
        onSubmit={handleSubmit}
      >
        <MentionArea
          text={note}
          setText={setNote}
          data={cannedResponses}
          disabled={false}
        />

        <div className={`${styles.input_container} ${styles.submit_container}`}>
          <input className="dark-btn" type="submit" />
        </div>
      </form>
    </Popup>
  );
}

export default AiNote;
