import React, { useContext, useState, useEffect } from "react";
import IssueLayout from "../Components/IssueLayout/index";
import { AppContext } from "../../../../../App";
function IssueContainer({
  choices,
  setChoices,
  check,
  level1,
  level2,
  level3,
  disable,
  checkIndexed,
  label1,
  label2,
  label3,
}) {
  const appContext = useContext(AppContext);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    appContext.setTitle("Ticket Fields");
    // setChoices(data);
  }, [appContext.reload]);

  const issueContext = {
    choices,
    setChoices,
    check,
    level1,
    level2,
    level3,
    disable,
    checkIndexed,
    label1,
    label2,
    label3,
  };
  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <IssueLayout issueContext={issueContext} />
  );
}

export default IssueContainer;
