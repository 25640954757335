import React, { useEffect } from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import { customStyles } from "../../styles";
function ConditionSubIssueRender({
  issueData,
  idx,
  conditions,
  setConditions,
  item,
  choices,
  constantsMapping,
  fieldType,
  renderComponentSwitch,
  fieldDict,
  ticketFields,
}) {
  function handleSubIssueAppend(label, value) {
    if (label == "operator" && value == "equal") {
      item.value = ["_=_=_="];
    }

    item[label] = value;
    if (label == "value") {
      if (value?.[0] && value?.[0] != "_=_=_=") {
        item.property = {};
      } else {
        item.property = null;
      }
    }

    issueData.property = { ...item };
    let conditionData = conditions;
    conditionData.properties[idx] = issueData;
    conditions = conditionData;
    setConditions({ ...conditions });
  }

  useEffect(() => {
    const ticket_data =
      ticketFields
        ?.find((info) => info.key == issueData?.key)
        ?.choices?.find((info) => info.label == issueData?.value?.[0])
        ?.choices?.[0] || {};

    handleSubIssueAppend("key", ticket_data?.key);
  }, [issueData, fieldDict]);
  return (
    item?.key && (
      <>
        <div className={styles.arrow_wrapper2}>
          <Select
            styles={customStyles}
            options={{ label: item.key, value: item.key }}
            placeholder="key"
            className={styles.condition_select1_disabled}
            value={{ label: fieldDict?.[item.key]?.label, value: item.key }}
            isDisabled={true}
          />

          <Select
            styles={customStyles}
            options={constantsMapping?.operator_choices?.filter((info) => {
              return constantsMapping?.field_operator_mapping?.[
                fieldType
              ]?.includes(info.value);
            })}
            placeholder="Operator"
            className={styles.condition_select2}
            value={constantsMapping?.operator_choices?.filter(
              (info) => info.value == item?.operator
            )}
            onChange={(e) => {
              item.value = [];
              handleSubIssueAppend("operator", e.value);
            }}
          />
        </div>

        {item?.operator ? (
          <div className={styles.condition_item2}>
            {renderComponentSwitch(
              item,
              handleSubIssueAppend,
              choices,
              "sub_issue"
            )}
          </div>
        ) : null}
      </>
    )
  );
}

export default ConditionSubIssueRender;
