import React, { useContext, useEffect, useState } from "react";
import styles from "../css/style.module.css";
import { patch_data } from "../../../cruxreactlib/networkhandler";
import { API_URL, ICON_URL } from "../../../config";
import { AppContext } from "../../../App";
import ToggleButton from "../../../components/ToggleButton";

function AgentList({ item, idx, breakOptions }) {
  const appContext = useContext(AppContext);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(item?.is_available);
  }, [item]);

  function updateAgent(e) {
    setActive(e.target.checked);
    e.preventDefault();
    patch_data(
      `${API_URL}/convo/users/agent/${item.id}/v1/`,
      { data: { is_available: e.target.checked } },
      appContext,
      true
    );
  }
  return (
    <>
      <div className={styles.article_list} key={idx}>
        <p className={styles.article_long_text}>
          <img
            src={`${ICON_URL}/production/a/assets/images/new-admin/agents-d57b27355779929be90613ee0065a89204701426b120b566450cfffc7c806394.svg`}
          />
          {item?.name || "--"}
        </p>
        <p className={styles.article_text_email}>{item?.email || "--"}</p>
        <p className={styles.article_text}>{item?.updated_at || "--"}</p>{" "}
        <div className={styles.article_text_switch}>
          {/* <div className={`${styles.incentive_info} ${styles.toggle}`}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={isActive}
                className={styles.hide}
                onChange={updateAgent}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div> */}
          <ToggleButton item={item} breakOptions={breakOptions} />
        </div>
      </div>
    </>
  );
}

export default AgentList;
