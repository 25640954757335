import React, { useEffect, useRef, useState } from "react";
import Popup from "../../../Dynamic/Popup";

function URl({ item, handleTicketChange, styles, automationData }) {
  const inputRef = useRef();
  const [payload, setPayload] = useState(item?.webhook?.url);
  const [showbtn, setShowBtn] = useState(false);

  useEffect(() => {
    setPayload(item?.webhook?.url);
  }, [item]);

  const handleAppend = (appendedText, type) => {
    const textarea = inputRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;

    const prefix = payload?.substring(0, startPos) || "";
    const suffix = payload?.substring(endPos) || "";
    // const appendedText = "yourAppendedText"; // Replace this with the text you want to append

    setPayload(prefix + `${appendedText}` + suffix);

    handleTicketChange("url", prefix + `${appendedText}` + suffix);

    // Move the cursor to the end of the appended text
    textarea.setSelectionRange(
      startPos + appendedText.length,
      startPos + appendedText.length
    );
    textarea.focus();
  };

  const handleDynamicVariables = () => {
    setShowBtn(!showbtn);
  };
  return (
    <div className={styles.url_item}>
      <div className={styles.webhook_item}>
        <span className={styles.hook_label}>URL</span>
        <div className={styles.hook_input}>
          <input
            type="text"
            placeholder="Enter Url"
            value={payload}
            className={styles.url_input}
            required
            ref={inputRef}
            onChange={(e) => handleTicketChange("url", e.target.value)}
          />
        </div>

        <button
          onClick={handleDynamicVariables}
          type="button"
          className={styles.plus_popup}
        >
          {!showbtn ? "+" : "-"}
        </button>
      </div>
      <div>
        {showbtn && (
          <Popup
            handleAppend={handleAppend}
            setShow={setShowBtn}
            automationData={automationData}
          />
        )}
      </div>
    </div>
  );
}

export default URl;
