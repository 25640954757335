import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../App";
import { API_URL } from "../../../../../config";
import { delete_data } from "../../../../../cruxreactlib/networkhandler";
import { ReactComponent as Edit } from "../../../../../assets/Admin/Edit.svg";
import { ReactComponent as Delete } from "../../../../../assets/Admin/Delete.svg";
import Popup from "../../../../../cruxreactlib/utils/Popup/index";
import styles from "../css/style.module.css";
import { NavLink } from "react-router-dom";
import { TeamContext } from "../../../../../Routes/AdminRoutes/Components/TeamsRoute";

function NewAgent({ data }) {
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);

  let [close, setClose] = useState(false);
  let [randomColor, setRandomColor] = useState("#ffffff");
  let [show, setShow] = useState(false);

  useEffect(() => {
    const getRandomLightColor = () => {
      const getComponent = () => Math.floor(Math.random() * 128) + 128;
      const rgb = [getComponent(), getComponent(), getComponent()];
      return `#${rgb
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;
    };
    setRandomColor(getRandomLightColor());
  }, [data]);

  useEffect(
    function () {
      setClose(false);
      setShow(false);
    },
    [close]
  );

  function handleMouseEnter() {
    setShow(true);
  }
  function handleMouseLeave() {
    setShow(false);
  }
  function handleDelete(e) {
    e.preventDefault();
    delete_data(
      `${API_URL}/convo/users/agent/${data?.id}/v1/`,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setShow(false);
      }
    });
  }

  function getValue(value) {
    const ecosystems = teamContext?.constants?.ecosystem;
    const key = Object.keys(ecosystems).find((k) => ecosystems[k] === value);
    return key || "---";
  }

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.col}>
        <p className={styles.icon_div} style={{ backgroundColor: randomColor }}>
          {data?.name?.charAt(0)}
        </p>
        <div>
          <p className={styles.text}>{data?.name}</p>
          <p className={styles.small_text}>{data?.email}</p>
        </div>
      </div>
      <div className={styles.col}>
        <p className={styles.text}>
          {data?.concurrency ? data?.concurrency : "---"}
        </p>
      </div>
      <div className={styles.col}>
        <p className={styles.text}>
          {data?.roles?.length > 0 ? (
            <>
              {data?.roles?.slice(0, 2).join(", ")}
              {data?.roles?.length > 2 && (
                <span
                  title={data?.roles?.slice(2).join(", ")}
                  className={styles.round_text}
                >
                  +{data?.roles?.length - 2}
                </span>
              )}
            </>
          ) : (
            <>---</>
          )}
        </p>
      </div>
      <div className={styles.col}>
        <p className={styles.text}>
          {data?.groups?.length > 0 ? (
            <>
              {data?.groups?.slice(0, 2).join(", ")}
              {data?.groups?.length > 2 && (
                <span
                  title={data?.groups?.slice(2).join(", ")}
                  className={styles.round_text}
                >
                  +{data?.groups?.length - 2}
                </span>
              )}
            </>
          ) : (
            <>---</>
          )}
        </p>
      </div>
      <div className={styles.col}>
        <p className={styles.text}>{getValue(data?.active_ecosystem)}</p>
      </div>
      <div
        className={styles.col}
        style={{ visibility: show ? "visible" : "hidden" }}
      >
        <NavLink
          className={styles.btn}
          to={{
            pathname: `/teams/agents/edit/${data?.id}`,
          }}
        >
          <Edit />
        </NavLink>
        <Popup btnName={<Delete />} btnStyling={styles.btn} closeState={close}>
          <h1 className={styles.popup_heading}>Delete Agent</h1>
          <form className={styles.form} onSubmit={handleDelete}>
            <h2>Are you sure you want to delete the agent?</h2>
            <div
              className={`${styles.input_container} ${styles.submit_container}`}
              style={{ zIndex: 0 }}
            >
              <input className="dark-btn" type="submit" />
            </div>
          </form>
        </Popup>
      </div>
    </div>
  );
}

export default NewAgent;
