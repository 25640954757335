import React, { useEffect, useState, useContext } from "react";
import styles from "../css/style.module.css";
import { ReactComponent as TicketIcon } from "../../../../../../assets/Automation/ticketicon.svg";
import { IoMdTrash } from "react-icons/io";
import { AppContext } from "../../../../../../App";
import IssueContainer from "../../Components/IssueContainer";
import { post_data } from "../../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../../config";
function TicketEdit({
  item,
  idx,
  ticketEditData,
  disable,
  fieldDict,
  isNew,
  openTicket,
  setOpenTicket,
}) {
  const appContext = useContext(AppContext);
  const [data, setData] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [choices, setChoices] = useState([]);
  let [data_sub, setDataSub] = useState({});
  let [data_fb, setDataFB] = useState({});
  let [checkIndexed, setIndexed] = useState("");

  useEffect(() => {
    let { choices, ...info } = item;
    if (isNew) {
      setOpen(false);
    } else if (idx != openTicket) {
      setOpen(false);
    }
    setIndexed(item?.is_indexed);
    setData(info);
    handleSubData();
    handleFbData();
    setChoices(choices || []);
  }, [item, isNew, ticketEditData, openTicket]);

  function handleSubData() {
    let { choices, ...info } = item;
    let subInfo =
      choices?.find((info_data) => info_data?.choices?.length) || {};
    let key = subInfo?.choices?.[0]?.key;
    let label = fieldDict?.[subInfo?.choices?.[0]?.key]?.label;
    setDataSub({ key: key, label: label, field_type: "dependent" });
  }

  function handleFbData() {
    let { choices, ...info } = item;
    let subInfo =
      choices?.find((info_data) => info_data?.choices?.length) || {};
    let fbInfo =
      subInfo?.choices?.find((info_data) => info_data?.choices?.length) || {};
    let key = fbInfo?.choices?.[0]?.key;
    let label = fieldDict?.[fbInfo?.choices?.[0]?.key]?.label;
    setDataFB({ key: key, label: label, field_type: "dependent" });
  }

  useEffect(() => {
    if (data.label && data.key) {
      setDataSub({
        ...data_sub,
        parent_field: data.key,
        field_type: "dependent",
        can_edit: data?.can_edit,
        default: data?.default,
        is_indexed: data?.is_indexed,
        is_required: data?.is_required,
        nested_fields: data?.nested_fields,
      });
    }
  }, [data]);

  useEffect(() => {
    if (data_sub.label && data_sub.key) {
      setDataFB({
        ...data_fb,
        parent_field: data_sub.key,
        field_type: "dependent",
        can_edit: data?.can_edit,
        default: data?.default,
        is_indexed: data?.is_indexed,
        is_required: data?.is_required,
        nested_fields: data?.nested_fields,
      });
    }
  }, [data_sub]);

  //handles form input values
  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  function handleChange2(e) {
    setDataSub({
      ...data_sub,
      [e.target.name]: e.target.value,
      parent_field: data?.key,
    });
  }

  function handleChange3(e) {
    setDataFB({
      ...data_fb,
      [e.target.name]: e.target.value,
      parent_field: data_sub?.key,
    });
  }

  function checkSubChoices(info) {
    return info?.[0]?.choices?.length > 0;
  }

  function checkFurtherChoices(info) {
    if (data_fb?.label && data_fb?.key) {
      return info?.[0]?.choices?.[0]?.choices?.length > 0;
    }
    return true;
  }

  //handles new item added
  function handleSubmit(e) {
    e.preventDefault();

    for (let i = 0; i < ticketEditData?.length; i++) {
      let ticket_info = ticketEditData[i];
      if (i != idx) {
        if (
          data?.field_type == "dependent" &&
          ticket_info?.parent_field == ""
        ) {
          if (
            ticket_info?.label == data.label ||
            ticket_info?.key == data.key ||
            ticket_info?.label == data_sub.label ||
            ticket_info?.key == data_sub.key ||
            ticket_info?.label == data_fb.label ||
            ticket_info?.key == data_fb.key
          ) {
            appContext.setAlert("Ticket Field is also present", "alert_error");
            return;
          }
        } else {
          if (ticket_info.label == data.label || ticket_info.key == data.key) {
            appContext.setAlert("Ticket Field is also present", "alert_error");
            return;
          }
        }
      }
    }

    let ticketData = [];
    ticketEditData?.map((info, index) => {
      let { choices, ...fieldData } = info;
      if (info?.label && info?.key) {
        ticketData.push(fieldData);
      }
    });

    ticketData = ticketData?.map((info) => {
      if (info?.key == data?.key) {
        return data;
      } else if (info?.key == data_sub?.key) {
        return data_sub;
      } else if (info?.key == data_fb?.key) {
        return data_fb;
      } else {
        return info;
      }
    });

    let ticket_payload = {
      ticket_fields: ticketData,
    };

    if (data?.field_type == "dependent" || data?.field_type == "dropdown") {
      ticket_payload.options = { key: data.key, choices: choices };
    }

    if (data?.field_type == "dependent") {
      if (
        choices?.length > 0 &&
        checkSubChoices(choices) &&
        checkFurtherChoices(choices)
      ) {
        postTicketData(ticket_payload);
      } else {
        appContext.setAlert(
          "Please fill all levels of atleast one choice",
          "alert_error"
        );
        return;
      }
    } else {
      postTicketData(ticket_payload);
    }
  }

  async function postTicketData(ticket_payload) {
    const data = await post_data(
      `${API_URL}/convo/config/ticket/v1/`,
      ticket_payload,
      appContext,
      true
    );
    if (data) {
      setOpen(false);
    }
  }

  function checkIsValidField(ticketData, info) {
    if (info.parent_field == "") {
      return true;
    }

    for (let i = 0; i < ticketData?.length; i++) {
      let ticket_info = ticketData[i];
      if (ticket_info?.key == info?.parent_field) {
        return true;
      }
    }

    return false;
  }

  //handles the delete of ticket item
  function handleDelete() {
    if (disable) {
      return;
    }

    let ticketData = [];
    let ticketFieldData = ticketEditData?.filter((item, index) => {
      return index != idx;
    });

    ticketEditData?.map((info, index) => {
      if (index != idx) {
        let { choices, ...fieldData } = info;
        let checkValid = checkIsValidField(ticketFieldData, info);
        if (checkValid) {
          ticketData.push(fieldData);
        } else {
          ticketFieldData = ticketFieldData?.filter((val) => {
            return val?.key != info?.key;
          });
        }
      }
    });

    let ticket_payload = {
      ticket_fields: ticketData,
    };

    postTicketData(ticket_payload);
  }

  function handleIndexChange(val) {
    setIndexed(val);
  }

  return (
    <form className={styles.ticket_wrapper} onSubmit={handleSubmit}>
      <div className={styles.ticket_box}>
        <div className={`${styles.item_flex} pointer`}>
          <div
            className={styles.item_flex_box}
            onClick={() => {
              if (isNew) {
                setOpen(false);
              } else {
                setOpen(!isOpen);
              }

              setOpenTicket(idx);
            }}
          >
            <span className={styles.ticket_icon}>
              <TicketIcon />
            </span>
            <span className={styles.icon_style}>{data?.field_type?.[0]}</span>
            <span className={styles.label_length}>{data?.label}</span>
          </div>
          <span className={styles.delete_btn} onClick={handleDelete}>
            <IoMdTrash />
          </span>
        </div>

        {isOpen && idx == openTicket && (
          <>
            <div className={styles.ticket_container}>
              <div className={styles.radio_container}>
                <label className={styles.radio_label}>Indexed Field</label>
                <div className={styles.radio_btn}>
                  <div className={styles.radio_item}>
                    <input
                      type="radio"
                      checked={checkIndexed}
                      id="yes"
                      className={styles.radio_input}
                      disabled={checkIndexed !== ""}
                      onChange={() => handleIndexChange(true)}
                    />
                    <label htmlFor="yes"> Yes</label>
                  </div>
                  <div className={styles.radio_item}>
                    <input
                      type="radio"
                      checked={checkIndexed === false}
                      id="no"
                      className={styles.radio_input}
                      disabled={checkIndexed !== ""}
                      onChange={() => handleIndexChange(false)}
                    />
                    <label htmlFor="no"> No</label>
                  </div>
                </div>
              </div>
              {item?.field_type != "dependent" ? (
                <div className={styles.ticket_item}>
                  <div className={styles.item}>
                    <label className={styles.item_label}>Label</label>
                    <input
                      type="text"
                      className={styles.item_input}
                      required
                      value={data?.label}
                      name="label"
                      disabled={disable}
                      onChange={handleChange}
                      maxLength={checkIndexed ? 256 : undefined}
                    />
                  </div>
                  <div className={styles.item}>
                    <label className={styles.item_label}>Key</label>
                    <input
                      type="text"
                      className={styles.item_input}
                      required
                      value={data?.key}
                      name="key"
                      onChange={handleChange}
                      disabled={true}
                    />
                  </div>{" "}
                </div>
              ) : (
                <div className={styles.ticket_item}>
                  <div className={styles.item}>
                    <label className={styles.item_label}>
                      Label for Level 1
                    </label>
                    <input
                      type="text"
                      className={styles.item_input}
                      required
                      value={data?.label}
                      name="label"
                      onChange={handleChange}
                      disabled={disable}
                      maxLength={checkIndexed ? 256 : undefined}
                    />
                  </div>
                  <div className={styles.item}>
                    <label className={styles.item_label}>Key for Level 1</label>
                    <input
                      type="text"
                      className={styles.item_input}
                      required
                      value={data?.key}
                      name="key"
                      onChange={handleChange}
                      disabled={true}
                    />
                  </div>

                  {data_sub?.parent_field &&
                    data_sub?.label != undefined &&
                    data_sub?.key != undefined && (
                      <>
                        <div className={styles.item}>
                          <label className={styles.item_label}>
                            Label for Level 2
                          </label>
                          <input
                            type="text"
                            className={styles.item_input}
                            required
                            value={data_sub?.label}
                            name="label"
                            onChange={handleChange2}
                            disabled={disable}
                            maxLength={checkIndexed ? 256 : undefined}
                          />
                        </div>
                        <div className={styles.item}>
                          <label className={styles.item_label}>
                            Key for Label 2
                          </label>
                          <input
                            type="text"
                            className={styles.item_input}
                            required
                            value={data_sub?.key}
                            name="key"
                            onChange={handleChange2}
                            disabled={true}
                          />
                        </div>
                      </>
                    )}

                  {data_fb?.parent_field &&
                    data_fb?.label != undefined &&
                    data_fb?.key != undefined && (
                      <>
                        <div className={styles.item}>
                          <label className={styles.item_label}>
                            Label for Label 3
                          </label>
                          <input
                            type="text"
                            className={styles.item_input}
                            value={data_fb?.label}
                            name="label"
                            onChange={handleChange3}
                            required={data_fb?.key}
                            disabled={disable}
                            maxLength={checkIndexed ? 256 : undefined}
                          />
                        </div>
                        <div className={styles.item}>
                          <label className={styles.item_label}>
                            Key for Label 3
                          </label>
                          <input
                            type="text"
                            className={styles.item_input}
                            value={data_fb?.key}
                            name="key"
                            onChange={handleChange3}
                            disabled={true}
                          />
                        </div>{" "}
                      </>
                    )}
                </div>
              )}

              {(data?.field_type == "dependent" ||
                data?.field_type == "dropdown") &&
                !disable && (
                  <IssueContainer
                    choices={choices}
                    setChoices={setChoices}
                    check={data?.field_type != "dropdown"}
                    disable={disable}
                    label1={data.label}
                    label2={data_sub.label}
                    label3={data_fb.label}
                    level1={data.key}
                    level2={data_sub.key}
                    level3={data_fb.key}
                    checkIndexed={checkIndexed}
                  />
                )}
            </div>

            <div className={styles.ticket_btns}>
              <button
                className={styles.cancel_btn}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              {!disable ? (
                <button className={styles.submit_btn}>Save Field</button>
              ) : null}
            </div>
          </>
        )}
      </div>
    </form>
  );
}

export default TicketEdit;
