import React, { useEffect } from "react";
import styles from "../../../css/style.module.css";
import Select from "react-select";
import { customStyles } from "../../styles";

function PropertyFurtherRender({
  issueData,
  subIssueData,
  actions,
  setActions,
  item,
  id,
  choices,
  renderComponentSwitch,
  ticketFields,
  fieldDict,
}) {
  function handleFurtherAppend(label, value) {
    let actionList = actions?.map((data) => {
      if (issueData.uid == data.uid) {
        item[label] = value;
        if (label == "key" && !item?.value) {
          item.value = "";
        } else if (label == "value" && !value) {
          item.property = null;
        }
        subIssueData.property = item;
        issueData.properties = {
          ...issueData?.properties,
          property: subIssueData,
        };
        return issueData;
      }
      return data;
    });

    setActions([...actionList]);
  }

  useEffect(() => {
    const ticket_data =
      ticketFields
        ?.find((info) => info.key == issueData?.properties?.key)
        ?.choices?.find((info) => info.label == issueData?.properties?.value)
        ?.choices?.find((info) => info.label == subIssueData?.value)
        ?.choices[0] || {};

    if (ticket_data?.key) {
      handleFurtherAppend("key", ticket_data?.key);
    }
  }, [subIssueData.property, id]);
  return (
    <>
      {" "}
      {item?.key && (
        <Select
          styles={customStyles}
          options={{ label: item.key, value: item.key }}
          placeholder="key"
          className={styles.condition_select1}
          value={{ label: fieldDict?.[item?.key]?.label, value: item.key }}
          isDisabled={true}
        />
      )}
      {item?.key && (
        <div className={styles.condition_select4}>
          {renderComponentSwitch(
            item,
            handleFurtherAppend,
            choices,
            "further_breakup"
          )}
        </div>
      )}
    </>
  );
}

export default PropertyFurtherRender;
