import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import styles from "./css/style.module.css";
import Select from "react-select";
import { get_data, post_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";
import { ReactComponent as DeleteIcon } from "../../assets/Admin/Delete.svg";
import CsvUpload from "./components/CsvUpload";
import DownloadCsv from "./components/DownloadCsv";

function Dialler() {
  const appContext = useContext(AppContext);
  let [fieldOptions, setFieldOptions] = useState([]);
  let [loader, setLoader] = useState(false);
  let [agents, setAgents] = useState([
    {
      key: "",
      value: "",
    },
  ]);

  useEffect(() => {
    appContext.setPage("Dialler");
    appContext.setTitle("Dialler");
    get_all_headers();
    get_mappings_data();
  }, [appContext.reload]);

  async function get_all_headers() {
    const data = await get_data(`${API_URL}/convo/config/v1/`, appContext);
    let newOptions = data?.config?.ticket_fields.filter(
      (item) => item?.default === false
    );
    setFieldOptions(newOptions);
    setLoader(false);
  }

  async function get_mappings_data() {
    const data = await get_data(
      `${API_URL}/convo/ticket/dialler/mapping/v1/`,
      appContext
    );
    if (data?.data?.length === 0) {
      setAgents([{ key: "", value: "" }]);
    } else {
      setAgents(data?.data);
    }
    setLoader(false);
  }

  function handleAddRow() {
    const isValidPreviousRow =
      agents[agents.length - 1]?.key && agents[agents.length - 1]?.value;

    if (isValidPreviousRow) {
      setAgents((prevAgents) => [
        ...prevAgents,
        {
          key: "",
          value: "",
        },
      ]);
    } else {
      appContext.setAlert(
        "Previous row must have both field name and field value.",
        "alert_error"
      );
    }
  }

  function handleRemoveRow(index) {
    setAgents((prevAgents) => {
      const newAgents = [...prevAgents];
      newAgents.splice(index, 1);
      return newAgents;
    });
  }

  function handleChange(index, field, value) {
    setAgents((prevAgents) => {
      const newAgents = [...prevAgents];
      newAgents[index][field] = value;
      return newAgents;
    });
  }

  function filterFieldOptions(rowIndex) {
    const occupiedValues = new Set();
    for (let i = 0; i < agents.length; i++) {
      if (i !== rowIndex) {
        occupiedValues.add(agents[i]?.value);
      }
    }
    return (
      fieldOptions?.filter((option) => !occupiedValues.has(option?.key)) || []
    );
  }
  function handleClose() {
    appContext.setReload(!appContext.reload);
  }

  function handleSubmit(e) {
    e.preventDefault();

    const hasEmptyField = agents?.some(
      (agent) => agent.key === "" || agent.value === ""
    );

    if (hasEmptyField) {
      appContext.setAlert(
        "Field name and value cannot be empty.",
        "alert_error"
      );
      return;
    }

    const hasDuplicateFieldNames = agents.some(
      (agent, index) => agents.findIndex((a) => a.key === agent.key) !== index
    );

    if (hasDuplicateFieldNames) {
      appContext.setAlert("Field names must be unique.", "alert_error");
      return;
    }

    let body = { data: agents };
    post_data(
      `${API_URL}/convo/ticket/dialler/mapping/v1/`,
      body,
      appContext,
      true
    );
  }
  const handleInputChange = (index, field, value) => {
    if (!/\s/.test(value)) {
      handleChange(index, field, value);
    } else {
      console.log("Spaces are not allowed in the input field");
    }
  };
  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <div className={styles.header}>
        <h2>Dialler</h2>
        <div className={styles.upload_container}>
          <DownloadCsv agents={agents} /> <CsvUpload />
        </div>
      </div>

      <div className={styles.container}>
        <form className={styles.edit_form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            {agents?.map((agent, index) => (
              <div className={styles.sub_row} key={index}>
                <div className={styles.input_container}>
                  <label className={styles.label}>Field Name</label>
                  <input
                    type="text"
                    placeholder="Enter field name"
                    value={agent?.key}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange(index, "key", e.target.value)
                    }
                  />
                </div>
                <div className={styles.input_container}>
                  <label className={styles.label}>Field Value</label>
                  <Select
                    className={`${styles.select} ${styles.multiselect}`}
                    options={fieldOptions
                      ?.filter((option) => {
                        return !agents.some(
                          (agent) => agent.value === option.key
                        );
                      })
                      .map((option) => ({
                        label: option.label,
                        value: option.key,
                      }))}
                    value={fieldOptions?.find(
                      (option) => option.key === agent?.value
                    )}
                    onChange={(selectedOption) =>
                      handleChange(index, "value", selectedOption?.value)
                    }
                  />
                </div>

                {agents.length > 1 && (
                  <button
                    type="button"
                    className={styles.delete_btn}
                    onClick={() => handleRemoveRow(index)}
                  >
                    <DeleteIcon />
                  </button>
                )}
              </div>
            ))}
            <div className={styles.btn_row_div}>
              <button className="btn" onClick={handleAddRow} type="button">
                + Add Row
              </button>
            </div>
          </div>

          <div className={styles.btn_div}>
            <button className="dark-btn" type="submit">
              Create
            </button>
            <button type="button" className="btn" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Dialler;
