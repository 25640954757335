import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import HeaderRoutes from "./HeaderRoutes";
import Login from "../modules/Login/index";
import PrivateRoutes from "./PrivateRoutes";
import TicketDetails from "../modules/TicketDetails";
import RTM from "../modules/RTM";
import Group from "../modules/Admin/Teams/Group/index";
import Clusters from "../modules/Clusters";
import EditGroup from "../modules/Admin/Teams/Group/components/EditGroup";
import NewGroup from "../modules/Admin/Teams/Group/components/NewGroup";
import CannedResponses from "../modules/Admin/Workflows/CannedResponses/CannedResponses/index";
import EditCannedResponse from "../modules//Admin/Workflows/CannedResponses/EditCannedResponse";
import NewCannedResponse from "../modules//Admin/Workflows/CannedResponses/NewCannedResponse";
import BusinessHour from "../modules/Admin/Teams/BusinessHour/BusinessHour";
import SmartAssign from "../modules/SmartAssign";
import EditBusinessHour from "../modules/Admin/Teams/BusinessHour/EditBusinessHour";
import NewBusinessHour from "../modules/Admin/Teams/BusinessHour/NewBusinessHour";
import AgentRoles from "../modules/Admin/Teams/AgentRoles";
import EditAgent from "../modules/Admin/Teams/AgentRoles/components/EditAgent";
import NewAgent from "../modules/Admin/Teams/AgentRoles/components/AddAgent";

import Roles from "../modules/Admin/Teams/Roles";
import AddRole from "../modules/Admin/Teams/Roles/components/AddRole";
import EditRole from "../modules/Admin/Teams/Roles/components/EditRole";

import AdminRoutes from "./AdminRoutes";
import SupervisorDashboard from "../modules/Admin/Analytics/SupervisorDashboard";
import AgentDashboard from "../modules/Admin/Analytics/AgentDashboard";
import AgentTicketDetails from "../modules/AgentTicketDetails";
import RiskDashboard from "../modules/RiskDashboard";
import AutomationDashboard from "../modules/Admin/Workflows/AutomationDashboard";
import NewRule from "../modules/Admin/Workflows/AutomationDashboard/NewRule";
import EditRule from "../modules/Admin/Workflows/AutomationDashboard/EditRule";
import TicketDashboard from "../modules/Admin/Workflows/TicketDashboard";
import AIResponse from "../modules/AIResponses";
import AdminPanelRoutes from "./AdminRoutes/AdminPanelRoutes";
import AdminDetailRoutes from "./AdminRoutes/AdminDetailRoutes";
import TeamsRoute from "./AdminRoutes/Components/TeamsRoute";
import WorkFlowRoute from "./AdminRoutes/Components/WorkflowRoute";
import AnalyticsRoute from "./AdminRoutes/Components/AnalyticsRoute";
import CreateTicket from "../modules/CreateTicket";
import Dialler from "../modules/Dialler";
import Users from "../modules/Users";
import EditUser from "../modules/Users/components/EditUser";
import EditCluster from "../modules/Clusters/components/EditCluster";
import UserDetails from "../modules/Users/UserDetails/index";

import DiallerLogs from "../modules/DiallerLogs";

import UsersBreak from "../modules/UsersBreak";
import Breaks from "../modules/Admin/Teams/Breaks";
import SlaPolicy from "../modules/Admin/Workflows/SlaPolicy";
import NewSla from "../modules/Admin/Workflows/SlaPolicy/NewSla";
import EditSla from "../modules/Admin/Workflows/SlaPolicy/EditSla";
import Whatsapp from "../modules/Whatsapp";

const Home = lazy(() => import("../modules/Tickets"));

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      {/* Home Routes */}
      <Route path="/" element={<PrivateRoutes />}>
        <Route path="/" element={<HeaderRoutes />}>
          <Route path="/" element={<Navigate to={"/home"} replace={true} />} />
          <Route path="/home" element={<Home />} />
          <Route path="/rtm" element={<RTM />} />
          <Route path="/create-ticket" element={<CreateTicket />} />
          <Route path="/whatsapp" element={<Whatsapp />} />


          <Route path="/diallers" element={<Dialler />} />
          <Route path="/dialler/logs" element={<DiallerLogs />} />

          <Route path="/users" element={<Users />} />
          <Route path="/users/edit/:user_id" element={<EditUser />} />

          <Route path="/users/details/:user_id" element={<UserDetails />} />
          <Route path="/user/break" element={<UsersBreak />} />
          <Route path="/clusters" element={<Clusters />} />
          <Route path="/cluster/edit/:article_id" element={<EditCluster />} />
          <Route path="/smart-assign" element={<SmartAssign />} />
          <Route path="/details/:ticket_id" element={<TicketDetails />} />
          <Route path="/*" element={<Navigate to={"/home"} replace={true} />} />
        </Route>
      </Route>
      {/* Admin Routes */}
      <Route path="/" element={<AdminRoutes />}>
        <Route path="/" element={<AdminPanelRoutes />}>
          <Route path="/" element={<AdminDetailRoutes />}>
            {/* Teams Route */}

            <Route path="/teams/*" element={<TeamsRoute />}>
              <Route path="agents" element={<AgentRoles />} />
              <Route path="agents/add" element={<NewAgent />} />
              <Route path="agents/edit/:agent_id" element={<EditAgent />} />

              <Route path="group" element={<Group />} />
              <Route path="group/add" element={<NewGroup />} />
              <Route path="group/edit/:group_id" element={<EditGroup />} />

              <Route path="business-hour" element={<BusinessHour />} />
              <Route
                path="create/business-hour/"
                element={<NewBusinessHour />}
              />
              <Route
                path="business-hour/:business_hour_id"
                element={<EditBusinessHour />}
              />

              <Route path="roles" element={<Roles />} />
              <Route path="roles/add" element={<AddRole />} />
              <Route path="roles/edit/:role_id" element={<EditRole />} />

              <Route path="breaks" element={<Breaks />} />
            </Route>

            {/* Admin Route */}
            <Route path="/admin" element={<Navigate to="/teams" />} />

            {/* Analytics Route */}
            <Route path="/analytics/*" element={<AnalyticsRoute />}>
              <Route
                path="supervisor/dashboard"
                element={<SupervisorDashboard />}
              />
              <Route
                path="agent/dashboard/:agent_id"
                element={<AgentDashboard />}
              />
              <Route
                path="agent/ticket/details/:a_id"
                element={<AgentTicketDetails />}
              />
              <Route path="agent/dashboard" element={<AgentDashboard />} />{" "}
              <Route path="risk/dashboard" element={<RiskDashboard />} />
            </Route>

            {/* workflow Route */}
            <Route path="/workflows/*" element={<WorkFlowRoute />}>
              <Route path="canned/response" element={<CannedResponses />} />
              <Route
                path="canned/response/edit/:canned_response_id"
                element={<EditCannedResponse />}
              />
              <Route
                path="canned/response/new"
                element={<NewCannedResponse />}
              />
              <Route path="ai/response" element={<AIResponse />} />
              <Route path="ticket/dashboard" element={<TicketDashboard />} />
              <Route
                path="automation/dashboard"
                element={<AutomationDashboard />}
              />
              <Route path="automation/newrule/:type" element={<NewRule />} />
              <Route path="automation/editrule/:id" element={<EditRule />} />

              <Route path="sla/policy" element={<SlaPolicy />} />
              <Route path="sla/newpolicy/" element={<NewSla />} />
              <Route path="sla/editpolicy/:id" element={<EditSla />} />
              {/* <Route
                path="automation/editrule/:id/:type"
                element={<EditRule />}
              /> */}
              <Route path="business-hour" element={<BusinessHour />} />
              <Route path="group" element={<Group />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
