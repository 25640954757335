import React, { useEffect, useState } from "react";
import styles from "../../css/field.module.css";
import Select from "react-select";
import { customStyles } from "../../../../cruxreactlib/utils/customStyles";

function DependentField({
  item,
  filter,
  setFilter,
  ticketFields,
  isClearable,
  create,
}) {
  const [issueKey, setIssueKey] = useState("");
  const [subIssueKey, setSubIssueKey] = useState("");
  const [furtherKey, setFurtherKey] = useState("");

  useEffect(() => {
    setIssueKey(item?.choices?.[0]?.key);
    const sub_data = item?.choices?.find((info) => info?.choices?.length);
    setSubIssueKey(sub_data?.choices?.[0]?.key);
    const fb_data = sub_data?.choices?.find((info) => info?.choices?.length);
    setFurtherKey(fb_data?.choices?.[0]?.key);
  }, [item, filter]);

  function hasSubChoices() {
    const data = item?.choices?.find(
      (info) => info.label == filter?.[issueKey]
    )?.choices;
    return data?.length;
  }

  function hasFurtherChoices() {
    const data = item?.choices
      ?.find((info) => info.label == filter?.[issueKey])
      ?.choices?.find((info) => info.label == filter?.[subIssueKey])?.choices;
    return data?.length;
  }

  function getSubLabel() {
    const data = item?.choices?.find(
      (info) => info.label == filter?.[issueKey]
    )?.choices;

    return ticketFields?.field_dict?.[data?.[0]?.key]?.label || "";
  }
  function getFurtherLabel() {
    const data = item?.choices
      ?.find((info) => info.label == filter?.[issueKey])
      ?.choices?.find((info) => info.label == filter?.[subIssueKey])?.choices;

    return ticketFields?.field_dict?.[data?.[0]?.key]?.label || "";
  }

  return (
    <>
      <div className={styles.filter}>
        <label className={styles.label}>{item?.label || "--"}</label>
        <Select
          isClearable={isClearable}
          placeholder={`Select ${item?.label}`}
          styles={customStyles}
          options={
            item?.choices?.map((info) => {
              return { label: info.label, value: info.label };
            }) || []
          }
          isDisabled={item?.can_edit === false && !create}
          value={
            item?.choices?.filter((info) => info.label == filter?.[issueKey]) ||
            []
          }
          onChange={function (e) {
            if (e?.label) {
              setFilter({
                ...filter,
                [issueKey]: e?.label,
                [subIssueKey]: "",
                [furtherKey]: "",
              });
            } else {
              setFilter({
                ...filter,
                [issueKey]: "",
                [subIssueKey]: "",
                [furtherKey]: "",
              });
            }
          }}
        />
      </div>

      {filter?.[issueKey] && hasSubChoices() ? (
        <div className={styles.filter}>
          <label className={styles.label}>{getSubLabel() || "--"}</label>
          <Select
            isClearable={isClearable}
            styles={customStyles}
            placeholder={`Select ${getSubLabel()}`}
            isDisabled={item?.can_edit === false && !create}
            options={
              item?.choices
                ?.find((info) => info.label == filter?.[issueKey])
                ?.choices?.map((info) => {
                  return { label: info.label, value: info.label };
                }) || []
            }
            value={
              item?.choices
                ?.find((info) => info.label == filter?.[issueKey])
                ?.choices?.find(
                  (info) => info.label == filter?.[subIssueKey]
                ) || []
            }
            onChange={function (e) {
              if (e?.label) {
                setFilter({
                  ...filter,
                  [subIssueKey]: e?.label,
                  [furtherKey]: "",
                });
              } else {
                setFilter({
                  ...filter,
                  [subIssueKey]: "",
                  [furtherKey]: "",
                });
              }
            }}
          />
        </div>
      ) : null}

      {filter?.[subIssueKey] && hasFurtherChoices() ? (
        <div className={styles.filter}>
          <label className={styles.label}>{getFurtherLabel() || "--"}</label>
          <Select
            isDisabled={item?.can_edit === false && !create}
            styles={customStyles}
            isClearable={isClearable}
            placeholder={`Select ${getFurtherLabel()}`}
            options={
              item?.choices
                ?.find((info) => info.label == filter?.[issueKey])
                ?.choices?.find((info) => info.label == filter?.[subIssueKey])
                ?.choices?.map((info) => {
                  return { label: info.label, value: info.label };
                }) || []
            }
            value={
              item?.choices
                ?.find((info) => info.label == filter?.[issueKey])
                ?.choices?.find((info) => info.label == filter?.[subIssueKey])
                ?.choices?.find((info) => info.label == filter?.[furtherKey]) ||
              []
            }
            onChange={function (e) {
              if (e?.label) {
                setFilter({
                  ...filter,
                  [furtherKey]: e?.label,
                });
              } else {
                setFilter({
                  ...filter,
                  [furtherKey]: "",
                });
              }
            }}
          />
        </div>
      ) : null}
    </>
  );
}

export default DependentField;
