import React from "react";
import { AppContext } from "../../../../../App";
import { useState, useContext, useEffect } from "react";
import { post_data } from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import styles from "./css/style.module.css";
import { useNavigate } from "react-router-dom";
function NewCannedResponse() {
  let [loader, setLoader] = useState(false);
  let [cannedResponse, setCannedResponse] = useState({});

  let options = [
    {
      label: "Chat Connect",
      value: "C",
    },
    {
      label: "Crux",
      value: "T",
    },
  ];
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(function () {
    appContext.setPage("new canned response");
    appContext.setTitle("New canned response");
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (cannedResponse.description === "") {
      appContext.setAlert(
        "Please provide valid canned response",
        "alert_error"
      );
      return;
    }

    post_data(
      `${API_URL}/convo/solution/canned/response/v1/`,
      cannedResponse,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        navigate(`/workflows/canned/response`);
      }
    });
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <div className="item-row-container">
      <form onSubmit={handleSubmit}>
        <div className={styles.canned_container}>
          <div className={styles.canned_div}>
            <h4 className={styles.canned_header}>Title</h4>
            <input
              type="text"
              value={cannedResponse.title}
              className={styles.input}
              required
              onChange={function (e) {
                setCannedResponse({ ...cannedResponse, title: e.target.value });
              }}
            />
          </div>

          <div className={styles.canned_div}>
            <h4 className={styles.canned_header}>Create Shortcut</h4>
            <input
              type="text"
              value={cannedResponse.shortcut}
              onChange={(event) => {
                setCannedResponse({
                  ...cannedResponse,
                  shortcut: event.target.value,
                });
              }}
              required
              className={styles.input}
            />
          </div>
          <div className={styles.canned_div}>
            <h4 className={styles.canned_header_ecosystem}>Ecosystem</h4>
            <select
              onChange={function (e) {
                setCannedResponse({
                  ...cannedResponse,
                  ecosystem: e.target.value,
                });
              }}
              value={cannedResponse.ecosystem}
              className={styles.input}
              required
            >
              <option value="" unselectable>
                Select an option
              </option>
              {options.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles.article_edit}>
          <h4>Create Canned Response:</h4>
          <textarea
            value={cannedResponse.description}
            onChange={(event) => {
              setCannedResponse({
                ...cannedResponse,
                description: event.target.value,
              });
            }}
            rows={17}
            className={styles.textarea}
            required
          />

          <div className="btn-container">
            <button className={`btn ${styles.btn}`}>Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewCannedResponse;
