import React from "react";
import { AppContext } from "../../../../../App";
import { useState, useContext, useEffect } from "react";
import { get_data } from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import Navbar from "./components/Navbar";
import CannedResponse from "./components/CannedResponse";
import styles from "./css/style.module.css";
function CannedResponses() {
  let [loader, setLoader] = useState(false);

  let [cannedResponses, setCannedResponses] = useState([]);
  let [query, setQuery] = useState("");
  const appContext = useContext(AppContext);

  useEffect(
    function () {
      appContext.setTitle("Canned Responses");
      appContext.setPage("canned responses");
      get_canned_responses();
    },

    [appContext.reload]
  );

  function get_canned_responses() {
    get_data(
      `${API_URL}/convo/solution/canned/response/list/v1/?ecosystem=T`,
      appContext
    ).then(function (data) {
      if (data) {
        setCannedResponses(data.data);
        setLoader(false);
      }
    });
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <Navbar query={query} setQuery={setQuery} />

      <div className={`"item-row-container" ${styles.container}`}>
        <div className={styles.header}>
          <p className={styles.heading}>Title</p>
          <p className={styles.heading}>Date</p>
          <p className={styles.heading}>Shortcut</p>
          <p className={styles.heading}>Ecosystem</p>
          <p className={styles.heading} style={{ visibility: "hidden" }}>
            Seen
          </p>
        </div>

        <div className={styles.canned_container}>
          {cannedResponses?.length ? (
            cannedResponses?.map(function (item, index) {
              if (query) {
                return item?.title
                  ?.toLowerCase()
                  .includes(query.toLowerCase()) ||
                  item?.shortcut
                    ?.toLowerCase()
                    .includes(query.toLowerCase()) ? (
                  <CannedResponse data={item} />
                ) : (
                  <></>
                );
              } else {
                return <CannedResponse data={item} />;
              }
            })
          ) : (
            <h1 className={styles.no_found}>No Data Found</h1>
          )}
        </div>
      </div>
    </>
  );
}

export default CannedResponses;
