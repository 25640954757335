import React from "react";
import Select from "react-select";
import styles from "../../css/components.module.css";
import { customStyles } from "../styles";
function MultiSelect({ value, callbackfn, choices }) {
  function handleChange(e) {
    const val = e.map(function (item, idx) {
      return item.value;
    });
    callbackfn("value", val);
  }
  return (
    <Select
      styles={customStyles}
      isMulti={true}
      isClearable={true}
      options={choices
        ?.filter((info) => info.value != "" && info.value != "_=_=_=")
        ?.map((item) => {
          return { label: item?.label, value: item?.value || item?.label };
        })}
      placeholder="value"
      className={styles.select_field}
      value={choices
        ?.filter((info) => value?.includes(info.value))
        ?.map((item) => {
          return { label: item?.label, value: item?.value || item?.label };
        })}
      onChange={handleChange}
    />
  );
}

export default MultiSelect;
