// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_home__BueUs {
  background: #f3f4f9;
  height: 92vh;
  overflow: auto;
}

.style_rtm_container__T0WXq {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 2vh;
  padding: 2vh 0;
}
.style_filter__o0NHY {
  flex: 1 1;
  position: relative;
  height: 100%;
  max-height: 85vh;
  overflow-y: auto;
}
.style_ticket_container__NrkzJ {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 500px;
  gap: 10px;
  /* padding: 1vh 1vw; */
  margin: 1vh 1vw;
  box-shadow: 0 1px 0 0 #cfd7df;
  border: 1px solid rgb(0, 0, 0, 0.25);
  box-shadow: 1px 0.6px 2px #dee2e6;
}
.style_container__DaCk\\+ {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  background-color: white;
}
.style_heading__0BGmx {
  background-color: #f5f7f9;
  padding: 1vh 1vw;
}
.style_outer_container__DVtg4 {
  margin: 1vh 2vw;
}
.style_metrics__mAopj {
  display: flex;
  background-color: red;
}

.style_chartGraphs__kgaGr {
  display: flex;
  flex: 1 1;
  gap: 10px;
  margin-top: 1vh;
}
.style_metrics_div__Pa6ex {
  width: 49%;
}
.style_metrics_div__Pa6ex h3 {
  font-weight: 600;
}

.style_metrics_container__Me4wd {
  display: flex;
  gap: 10px;
  margin: 1vh 2vw;
}
`, "",{"version":3,"sources":["webpack://./src/modules/RTM/css/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,eAAe;EACf,cAAc;AAChB;AACA;EACE,SAAO;EACP,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,gBAAgB;EAChB,SAAS;EACT,sBAAsB;EACtB,eAAe;EACf,6BAA6B;EAC7B,oCAAoC;EACpC,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,SAAS;EACT,eAAe;AACjB;AACA;EACE,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB","sourcesContent":[".home {\n  background: #f3f4f9;\n  height: 92vh;\n  overflow: auto;\n}\n\n.rtm_container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  margin-top: 2vh;\n  padding: 2vh 0;\n}\n.filter {\n  flex: 1;\n  position: relative;\n  height: 100%;\n  max-height: 85vh;\n  overflow-y: auto;\n}\n.ticket_container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  max-width: 500px;\n  gap: 10px;\n  /* padding: 1vh 1vw; */\n  margin: 1vh 1vw;\n  box-shadow: 0 1px 0 0 #cfd7df;\n  border: 1px solid rgb(0, 0, 0, 0.25);\n  box-shadow: 1px 0.6px 2px #dee2e6;\n}\n.container {\n  display: flex;\n  flex-direction: row;\n  gap: 1vw;\n  background-color: white;\n}\n.heading {\n  background-color: #f5f7f9;\n  padding: 1vh 1vw;\n}\n.outer_container {\n  margin: 1vh 2vw;\n}\n.metrics {\n  display: flex;\n  background-color: red;\n}\n\n.chartGraphs {\n  display: flex;\n  flex: 1;\n  gap: 10px;\n  margin-top: 1vh;\n}\n.metrics_div {\n  width: 49%;\n}\n.metrics_div h3 {\n  font-weight: 600;\n}\n\n.metrics_container {\n  display: flex;\n  gap: 10px;\n  margin: 1vh 2vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `style_home__BueUs`,
	"rtm_container": `style_rtm_container__T0WXq`,
	"filter": `style_filter__o0NHY`,
	"ticket_container": `style_ticket_container__NrkzJ`,
	"container": `style_container__DaCk+`,
	"heading": `style_heading__0BGmx`,
	"outer_container": `style_outer_container__DVtg4`,
	"metrics": `style_metrics__mAopj`,
	"chartGraphs": `style_chartGraphs__kgaGr`,
	"metrics_div": `style_metrics_div__Pa6ex`,
	"metrics_container": `style_metrics_container__Me4wd`
};
export default ___CSS_LOADER_EXPORT___;
