import React, { useEffect, useState, useRef } from "react";
import styles from "../css/style.module.css";
import BreakEdit from "./BreakEdit";
import BreakAdd from "./BreakAdd";
import { post_data } from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";

function Break({ breakData, setBreakData, appContext, callbackfn }) {
  const containerRef = useRef(null);
  const [breaks, setBreaks] = useState([]);
  const [fieldDict, setFieldDict] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // Scroll to the bottom when the component mounts
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [breakData]);

  useEffect(() => {
    setLoader(true);
    getBreakData();
  }, [appContext.reload, appContext.ticketFields]);

  async function getBreakData() {
    setBreaks(appContext.ticketFields?.config?.breaks);
    setFieldDict(appContext.ticketFields?.field_dict);
    setLoader(false);
  }

  function handleSubmit(data) {
    let final_data = { breaks: data };
    post_data(`${API_URL}/convo/config/v1/`, final_data, appContext, true);
  }

  return (
    <div className={styles.container}>
      {loader ? (
        <div className="loader_container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className={styles.header_container}>
            <h3 className={styles.header}>Break Fields</h3>

            <div className={styles.add_break}>
              <button className={styles.add_btn} onClick={() => callbackfn()}>
                <span className={styles.plus}>+</span>
                <span> Add Break</span>
              </button>
            </div>
          </div>

          <div className={styles.break_container} ref={containerRef}>
            {!breakData?.isNew && breaks?.length == 0 && (
              <h2 className={styles.no_break}>No Break Found</h2>
            )}
            {breaks?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <BreakEdit
                    item={item}
                    idx={idx}
                    breaks={breaks}
                    setbreaks={setBreaks}
                    disable={item?.default}
                    fieldDict={fieldDict}
                    isNew={breakData?.isNew}
                    callbackfn={handleSubmit}
                  />
                </React.Fragment>
              );
            })}

            {breakData?.isNew && (
              <BreakAdd
                item={breakData}
                setBreakData={setBreakData}
                breaks={breaks}
                setbreaks={setBreaks}
                callbackfn={handleSubmit}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Break;
