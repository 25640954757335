import React from "react";
import styles from "../css/style.module.css";

function Strip({ item }) {
  const additionalDetails = Object.entries(item)
    .filter(
      ([key, value]) =>
        typeof value === "string" &&
        !["logs", "campaign", "phone", "subject", "n_slot_id"].includes(key)
    )
    .map(([key, value]) => {
      const displayKey = key.replace(/_/g, " ");
      return (
        <div className={styles.flexDiv} key={key}>
          <p className={styles.heading}>{displayKey}</p>
          <p className={styles.text}>{value}</p>
        </div>
      );
    });

  return (
    <div className={`${styles.row} ${styles.flexWrap}`}>
      {additionalDetails}
    </div>
  );
}

export default Strip;
