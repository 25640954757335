import React, { useEffect, useState } from "react";
import styles from "../../css/style.module.css";
import Conditions from "./Components/Conditions";

function ConditionList({
  handleConditionData,
  conditions,
  setConditions,
  handleAddCondition,
  countIndex,
  error,
  slaData,
  edit,
}) {
  // State to manage the selected match type
  const [selectedOption, setSelectedOption] = useState("any");

  useEffect(() => {
    setSelectedOption(conditions?.match_type);
  }, [conditions, slaData]);

  return (
    <div className={styles.condition_container}>
      <div className={styles.condition_item1}>
        {/* Radio buttons for match type selection */}
        <div className={styles.radio_item}>
          <input
            type="radio"
            value="any"
            checked={selectedOption == "any"}
            onChange={() => {
              setSelectedOption("any");
              handleConditionData("any", countIndex);
            }}
            className={styles.pointer}
            id="orr"
          />
          <label htmlFor="orr" className={styles.pointer}>
            Match <span>ANY</span> of the below
          </label>
        </div>
        <div className={styles.radio_item}>
          <input
            type="radio"
            value="all"
            checked={selectedOption == "all"}
            onChange={() => {
              setSelectedOption("all");
              handleConditionData("all", countIndex);
            }}
            className={styles.pointer}
            id="andd"
          />
          <label htmlFor="andd" className={styles.pointer}>
            Match <span>ALL</span> of the below
          </label>
        </div>
      </div>
      <div className={styles.w_full}>
        {/* Render conditions based on properties */}
        {conditions?.properties?.map((item, idx) => (
          <React.Fragment key={idx}>
            <Conditions
              index={countIndex}
              item={item}
              conditions={conditions}
              idx={idx}
              selectedOption={selectedOption}
              setConditions={setConditions}
              automationData={slaData}
              handleAddCondition={handleAddCondition}
              edit={edit}
            />
          </React.Fragment>
        ))}
      </div>
      <div
        className={
          error
            ? `${styles.condition_item3} ${styles.error_condition}`
            : styles.condition_item3
        }
      >
        {/* Button to add a new condition */}
        <button
          className={styles.new_condition_add}
          onClick={() => handleAddCondition(countIndex, selectedOption)}
          type="button"
        >
          <span className={styles.plus_icon}>+</span>
          Add new condition
        </button>
      </div>
    </div>
  );
}

export default ConditionList;
