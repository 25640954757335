import React from "react";
import { useState, useContext, useEffect } from "react";
import { API_URL } from "../../../../config";
import styles from "./css/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { AppContext } from "../../../../App";
import { get_data, patch_data } from "../../../../cruxreactlib/networkhandler";
import { ReactComponent as EditIcon } from "../../../../assets/clusters/Edit.svg";
import { ReactComponent as RightArrow } from "../../../../assets/Admin/RightArrow.svg";

function EditCluster() {
  let [loader, setLoader] = useState(false);
  let [article, setArticle] = useState([]);
  let [show, setShow] = useState(false);
  const appContext = useContext(AppContext);
  const { article_id } = useParams();
  const navigate = useNavigate();

  useEffect(
    function () {
      // const isValidInteger = /^\d+$/.test(article_id);
      // if (!isValidInteger) {
      //   navigate("/home");
      //   return;
      // }
      appContext.setPage("edit article");
      get_article_details();
    },

    [appContext.reload, article_id, navigate]
  );

  function get_article_details() {
    get_data(
      `${API_URL}/convo/solution/article/${article_id}/v1/`,
      appContext
    ).then(function (data) {
      if (data) {
        appContext.setTitle(data.data.title);
        setArticle(data.data);
        setLoader(false);
      }
    });
  }
  function handleSubmit(e) {
    let payload = { article_id: article_id, ...article };
    patch_data(
      `${API_URL}/convo/solution/article/${article_id}/v1/`,
      payload,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setShow(false);
        // navigate(`/article/details/${article_id}`);
      }
    });
  }

  function handleToggle() {
    setShow(!show);
  }

  function handleBack() {
    navigate("/clusters");
  }

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <div className={styles.cluster_wrapper}>
      {" "}
      <div className={styles.subheader}>
        <p className={styles.button_cancel} onClick={handleBack}>
          Clusters
        </p>
        <RightArrow />
        <p className={styles.sub_link}>Edit Article</p>
      </div>
      <div className={`${"item-row-container"} ${styles.wrapper}`}>
        <div className={styles.header}>
          <p className={styles.vew_draft}>Viewing draft edited by you</p>
          <button className={styles.button} onClick={handleToggle}>
            <EditIcon />
            {show ? "Cancel" : "Edit"}
          </button>
        </div>
        {show ? (
          <div className={styles.article_edit}>
            <JoditEditor
              value={article.description}
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => {
                setArticle({ ...article, description: newContent });
              }}
            />
            <div className="btn-container">
              <button className={`btn ${styles.btn}`} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.sub_wrapper}>
            <h1 className={styles.title}>{article?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: article.description }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default EditCluster;
