import React, { useState } from "react";
import EventIssueRender from "./EventIssueRender";
import EventSubIssueRender from "./EventSubIssueRender";
import EventFurtherRender from "./EventFurtherRender";

function IssueComponent({
  item,
  issueChoices,
  handleTypeChange,
  setIssueId,
  event,
  setEvent,
  idx,
  check,
  fieldType,
}) {
  const [subOldChoices, setSubOldChoices] = useState([]);
  const [subNewChoices, setSubNewChoices] = useState([]);

  return (
    <>
      <EventIssueRender
        item={item}
        choices={issueChoices}
        handleTypeChange={handleTypeChange}
        setId={setIssueId}
        fieldType={fieldType}
      />

      {check &&
        ((item?.old_value && item?.old_value !== "_=_=_=") ||
          (item?.new_value && item?.new_value !== "_=_=_=")) && (
          <EventSubIssueRender
            issueItem={item}
            item={item?.property}
            issueChoices={issueChoices}
            event={event}
            setEvent={setEvent}
            idx={idx}
            setSubNewChoices={setSubNewChoices}
            setSubOldChoices={setSubOldChoices}
            subOldChoices={subOldChoices}
            subNewChoices={subNewChoices}
          />
        )}

      {check &&
        ((item?.property?.old_value &&
          item?.property?.old_value !== "_=_=_=") ||
          (item?.property?.new_value &&
            item?.property?.new_value !== "=_=_=")) && (
          <EventFurtherRender
            issueItem={item}
            subIssueItem={item?.property}
            item={item?.property?.property}
            event={event}
            setEvent={setEvent}
            idx={idx}
            subOldChoices={subOldChoices}
            subNewChoices={subNewChoices}
          />
        )}
    </>
  );
}

export default IssueComponent;
