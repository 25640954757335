// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weekly_container__e0XFY {
  padding: 1vh 1vw;
  background: #ebeff3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.weekly_header_label__\\+c2g4 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.weekly_weekly_details_box__HKkSn {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
}

.weekly_chart_box__4FoHn {
  display: flex;
  gap: 20px;
  padding: 1.2vh 1vw;
}
.weekly_chart__gZAmx {
  border-radius: 12px;
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/Analytics/SupervisorDashboard/css/weekly.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,SAAO;AACT","sourcesContent":[".container {\n  padding: 1vh 1vw;\n  background: #ebeff3;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.header_label {\n  color: #000;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.weekly_details_box {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  border-radius: 12px;\n  background: #fff;\n}\n\n.chart_box {\n  display: flex;\n  gap: 20px;\n  padding: 1.2vh 1vw;\n}\n.chart {\n  border-radius: 12px;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `weekly_container__e0XFY`,
	"header_label": `weekly_header_label__+c2g4`,
	"weekly_details_box": `weekly_weekly_details_box__HKkSn`,
	"chart_box": `weekly_chart_box__4FoHn`,
	"chart": `weekly_chart__gZAmx`
};
export default ___CSS_LOADER_EXPORT___;
