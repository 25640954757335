// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_input__0rUVf {
  margin: 0.5vh 0vw;
  padding: 0.5vh 0.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  z-index: 5;
  font-size: 14px;
  justify-content: center;
  background-color: #f3f5f7;
  color: var(--primary-color);
  border: 1px solid #cfd7df;
  background-image: linear-gradient(to bottom, #fff, #f3f5f7);
  cursor: pointer;
  outline: none;
}
.styles_input__0rUVf:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/Teams/AgentRoles/components/Search/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,uBAAuB;EACvB,yBAAyB;EACzB,2BAA2B;EAC3B,yBAAyB;EACzB,2DAA2D;EAC3D,eAAe;EACf,aAAa;AACf;AACA;EACE,aAAa;AACf","sourcesContent":[".input {\n  margin: 0.5vh 0vw;\n  padding: 0.5vh 0.5vw;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 5px;\n  z-index: 5;\n  font-size: 14px;\n  justify-content: center;\n  background-color: #f3f5f7;\n  color: var(--primary-color);\n  border: 1px solid #cfd7df;\n  background-image: linear-gradient(to bottom, #fff, #f3f5f7);\n  cursor: pointer;\n  outline: none;\n}\n.input:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `styles_input__0rUVf`
};
export default ___CSS_LOADER_EXPORT___;
