import React, { useContext, useState, useEffect } from "react";
import styles from "../css/style.module.css";
import { AppContext } from "../../../../../../App";
import IssueContainer from "../../Components/IssueContainer";
import { post_data } from "../../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../../config";
function TicketAdd({
  item,
  setTicketData,
  ticketEditData,
  dbFields,
  containerRef,
}) {
  const appContext = useContext(AppContext);
  let [dbData, setDBData] = useState(dbFields || []);
  let [checkIndexed, setIndexed] = useState("");

  useEffect(() => {
    // Scroll to the bottom when the component mounts
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [checkIndexed]);

  const [data, setData] = useState({
    field_type: item?.type,
    parent_field: "",
  });
  let [data_sub, setDataSub] = useState({
    field_type: item?.type,
    parent_field: "",
  });

  let [data_fb, setDataFB] = useState({
    field_type: item?.type,
    parent_field: "",
  });

  const [isActive, setActive] = useState(false);
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    setDBData(dbFields || []);
  }, [dbFields]);

  useEffect(() => {
    if (data.label && data.key) {
      setDataSub({ ...data_sub, parent_field: data.key });
    }
  }, [data]);

  useEffect(() => {
    if (data_sub.label && data_sub.key) {
      setDataFB({ ...data_fb, parent_field: data_sub.key });
    }
  }, [data_sub]);

  function handleActive() {
    setActive(!isActive);
  }

  function getUniqueKey() {
    let data =
      dbData?.find((info) => {
        if (checkIndexed === false) {
          return !info?.is_indexed && !info?.is_used;
        } else if (checkIndexed) {
          return info?.is_indexed && !info?.is_used;
        }
      })?.key || "";

    let updated_data = dbData?.map((info) => {
      if (info?.key == data) {
        return { ...info, is_used: true };
      }
      return info;
    });

    setDBData([...updated_data]);

    return data;
  }

  //handles form input values
  function handleChange(e) {
    e.preventDefault();
    let info = { ...data, [e.target.name]: e.target.value };

    if (!data.key) {
      let unqkey = getUniqueKey();
      info = { ...info, key: unqkey };
    }
    setData(info);
  }

  function handleChange2(e) {
    e.preventDefault();

    let info = { ...data_sub, [e.target.name]: e.target.value };

    if (!data_sub.key) {
      let unqkey = getUniqueKey();
      info = { ...info, key: unqkey };
    }
    setDataSub(info);
  }

  function checkSubChoices(info) {
    return info?.[0]?.choices?.length > 0;
  }

  function checkFurtherChoices(info) {
    if (data_fb?.key && data_fb?.label) {
      return info?.[0]?.choices?.[0]?.choices?.length > 0;
    }
    return true;
  }
  function handleChange3(e) {
    e.preventDefault();

    let info = { ...data_fb, [e.target.name]: e.target.value };

    if (!data_fb.key) {
      let unqkey = getUniqueKey();
      info = { ...info, key: unqkey };
    }
    setDataFB(info);
  }
  //handles new item added
  function handleSubmit(e) {
    e.preventDefault();

    if (checkIndexed === "") {
      appContext.setAlert("Choose which type of field you want", "alert_error");
      return;
    }

    for (let i = 0; i < ticketEditData?.length; i++) {
      let ticket_info = ticketEditData[i];
      if (data?.field_type == "dependent" && ticket_info?.parent_field == "") {
        if (
          ticket_info?.label == data.label ||
          ticket_info?.key == data.key ||
          ticket_info?.label == data_sub.label ||
          ticket_info?.key == data_sub.key ||
          ticket_info?.label == data_fb.label ||
          ticket_info?.key == data_fb.key
        ) {
          appContext.setAlert("Ticket Field is also present", "alert_error");
          return;
        }
      } else {
        if (ticket_info.label == data.label || ticket_info.key == data.key) {
          appContext.setAlert("Ticket Field is also present", "alert_error");
          return;
        }
      }
    }

    let ticket_data = [];
    ticketEditData?.map((info, index) => {
      let { choices, ...fieldData } = info;
      if (info?.label && info?.key) {
        ticket_data.push(fieldData);
      }
    });

    ticket_data.push(data);

    if (
      data_sub?.parent_field &&
      item?.type == "dependent" &&
      data_sub?.label &&
      data_sub?.key
    ) {
      ticket_data.push(data_sub);
    }
    if (
      data_fb?.parent_field &&
      item?.type == "dependent" &&
      data_fb?.label &&
      data_fb?.key
    ) {
      ticket_data.push(data_fb);
    }

    let ticket_payload = {
      ticket_fields: ticket_data,
      db_fields: dbData,
    };

    if (isActive) {
      ticket_payload.options = { key: data.key, choices: choices };
    }

    if (data?.field_type == "dependent") {
      if (
        choices?.length > 0 &&
        checkSubChoices(choices) &&
        checkFurtherChoices(choices)
      ) {
        postTicketData(ticket_payload);
        item.isNew = false;
        setTicketData({ ...item });
      } else {
        appContext.setAlert(
          "Please fill all levels of atleast one choice",
          "alert_error"
        );
        return;
      }
    } else {
      postTicketData(ticket_payload);
      item.isNew = false;
      setTicketData({ ...item });
    }
  }

  async function postTicketData(ticket_payload) {
    const data = await post_data(
      `${API_URL}/convo/config/ticket/v1/`,
      ticket_payload,
      appContext,
      true
    );
  }

  //handles the delete of ticket item
  function handleDelete() {
    item.isNew = false;
    setTicketData({ ...item });
  }

  function checkDBKeys(val) {
    let ct = dbData?.filter((item) => {
      return val === item?.is_indexed && !item?.is_used;
    });

    if (data?.type == "dependent") {
      return ct.length >= 3;
    } else {
      return ct.length >= 1;
    }
  }

  function checkDBFeildsLeft() {
    let ct = dbData?.filter((item) => {
      return !item?.is_used;
    });

    if (data?.type == "dependent") {
      return ct.length >= 3;
    } else {
      return ct.length >= 1;
    }
  }

  function handleIndexChange(val) {
    if (!checkDBKeys(val)) {
      appContext.setAlert("Please change type of Db Fields", "alert_error");
      return;
    }

    if (!checkDBFeildsLeft()) {
      appContext.setAlert("No Db Fields", "alert_error");
      return;
    }

    setData({ ...data, is_indexed: val });
    setDataSub({ ...data_sub, is_indexed: val });
    setDataFB({ ...data_fb, is_indexed: val });
    setIndexed(val);
  }

  return (
    <form className={styles.ticket_wrapper} onSubmit={handleSubmit}>
      <div className={styles.ticket_box}>
        <div className={styles.item_flex_box}>
          <span className={styles.icon_style}>{item?.title[0]}</span>
          <span>New {item?.type} Field</span>
        </div>

        <div className={styles.ticket_container}>
          <div className={styles.radio_container}>
            <label className={styles.radio_label}>Indexed Field</label>
            <div className={styles.radio_btn}>
              <div className={styles.radio_item}>
                <input
                  type="radio"
                  checked={checkIndexed}
                  id="yes"
                  disabled={checkIndexed !== ""}
                  name="yes"
                  value="yes"
                  className={styles.radio_input}
                  onChange={() => handleIndexChange(true)}
                  required={checkIndexed == ""}
                />
                <label htmlFor="yes"> Yes</label>
              </div>
              <div className={styles.radio_item}>
                <input
                  type="radio"
                  checked={checkIndexed === false}
                  id="no"
                  value="yes"
                  name="yes"
                  className={styles.radio_input}
                  disabled={checkIndexed !== ""}
                  required={checkIndexed == ""}
                  onChange={() => handleIndexChange(false)}
                />
                <label htmlFor="no"> No</label>
              </div>
            </div>
          </div>

          <div style={checkIndexed === "" ? { display: "none" } : {}}>
            {item?.type != "dependent" ? (
              <div className={styles.ticket_item}>
                <div className={styles.item}>
                  <label className={styles.item_label}>Label</label>
                  <input
                    type="text"
                    className={styles.item_input}
                    required
                    value={data?.label}
                    name="label"
                    onChange={handleChange}
                    maxLength={checkIndexed ? 256 : undefined}
                  />
                </div>
                <div className={styles.item}>
                  <label className={styles.item_label}>Key</label>
                  <input
                    type="text"
                    className={`${styles.item_input} ${styles.disabled}`}
                    required
                    value={data?.key}
                    name="key"
                    disabled
                    onChange={handleChange}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.ticket_item}>
                <div className={styles.item}>
                  <label className={styles.item_label}>Label for Level 1</label>
                  <input
                    type="text"
                    className={styles.item_input}
                    required
                    value={data?.label}
                    name="label"
                    maxLength={checkIndexed ? 256 : undefined}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.item}>
                  <label className={styles.item_label}>Key for Level 1</label>
                  <input
                    type="text"
                    className={styles.item_input}
                    required
                    value={data?.key}
                    name="key"
                    disabled
                    onChange={handleChange}
                  />
                </div>
                {data_sub?.parent_field && (
                  <>
                    <div className={styles.item}>
                      <label className={styles.item_label}>
                        Label for Level 2
                      </label>
                      <input
                        type="text"
                        className={styles.item_input}
                        required
                        value={data_sub?.label}
                        name="label"
                        maxLength={checkIndexed ? 256 : undefined}
                        onChange={handleChange2}
                      />
                    </div>
                    <div className={styles.item}>
                      <label className={styles.item_label}>
                        Key for Label 2
                      </label>
                      <input
                        type="text"
                        className={styles.item_input}
                        required
                        value={data_sub?.key}
                        name="key"
                        disabled
                        onChange={handleChange2}
                      />
                    </div>
                  </>
                )}

                {data_fb?.parent_field && (
                  <>
                    <div className={styles.item}>
                      <label className={styles.item_label}>
                        Label for Label 3
                      </label>
                      <input
                        type="text"
                        className={styles.item_input}
                        value={data_fb?.label}
                        name="label"
                        maxLength={checkIndexed ? 256 : undefined}
                        onChange={handleChange3}
                      />
                    </div>
                    <div className={styles.item}>
                      <label className={styles.item_label}>
                        Key for Label 3
                      </label>
                      <input
                        type="text"
                        className={styles.item_input}
                        value={data_fb?.key}
                        name="key"
                        disabled
                        onChange={handleChange3}
                      />
                    </div>{" "}
                  </>
                )}
              </div>
            )}
            {(item?.type == "dependent" || item?.type == "dropdown") &&
              !isActive && (
                <div className={styles.choices_container}>
                  <button
                    className={styles.choice_btn}
                    onClick={handleActive}
                    type="button"
                  >
                    Add Choices
                  </button>
                </div>
              )}
            {isActive && (
              <IssueContainer
                choices={choices}
                setChoices={setChoices}
                check={item?.type != "dropdown"}
                label1={data.label}
                label2={data_sub.label}
                label3={data_fb.label}
                level1={data.key}
                level2={data_sub.key}
                level3={data_fb.key}
                checkIndexed={checkIndexed}
              />
            )}
          </div>
        </div>
        <div className={styles.ticket_btns}>
          <button
            className={styles.cancel_btn}
            type="button"
            onClick={handleDelete}
          >
            Cancel
          </button>
          <button className={styles.submit_btn}>Save Field</button>
        </div>
      </div>
    </form>
  );
}

export default TicketAdd;
