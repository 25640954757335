import React, { useContext } from "react";
import styles from "../css/style.module.css";
import { ReactComponent as ArticleIcon } from "../../../assets/clusters/Article.svg";
import { ReactComponent as Delete } from "../../../assets/clusters/Delete.svg";
import { delete_data } from "../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../config";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../App";

function Article({ item, idx }) {
  const appContext = useContext(AppContext);
  function handleDelete(e) {
    delete_data(
      `${API_URL}/convo/solution/article/${item?.id}/v1/`,
      appContext,
      true
    );
  }
  return (
    <>
      <div className={styles.article_list} key={idx}>
        <p className={styles.article_long_text}>
          <ArticleIcon />
          <div>
            <NavLink to={`/cluster/edit/${item?.id}`} className={styles.small}>
              {item?.title}
            </NavLink>
            {/* <p className={styles.small}>{item?.title}</p> */}
            <p className={styles.verysmall}>Published by Harshit</p>
          </div>
        </p>
        <p className={styles.article_text}>{item?.date}</p>
        <p className={styles.article_text}>11</p>
        <p className={styles.article_text}>3</p>
        <p className={styles.article_text}>0</p>
        <p className={styles.article_text}>
          <Delete onClick={handleDelete} style={{ cursor: "pointer" }} />
        </p>
      </div>
    </>
  );
}

export default Article;
