import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../../App";
import { API_URL } from "../../../config";
import { post_data } from "../../../cruxreactlib/networkhandler";
import styles from "../css/style.module.css";
import { ReactComponent as SuccessIcon } from "../../../assets/Dialler/Success.svg";
import { ReactComponent as FailureIcon } from "../../../assets/Dialler/Failure.svg";
import { useNavigate } from "react-router-dom";

function CsvUpload({ count, templateType, templateId, setClose }) {
  let [csvData, setCsvData] = useState(null);
  let [uploadStatus, setUploadStatus] = useState(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      appContext.setAlert("No file selected", "alert_error");
      return;
    }
    const allowedExtensions = ["csv"];
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      appContext.setAlert(
        "Invalid file type. Please select a CSV file.",
        "alert_error"
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      parseCsv(content);
    };

    reader.readAsText(file);
  };

  const parseCsv = (content) => {
    const lines = content.split("\n");
    const headers = lines[0].split(",");

    // Trim whitespace from column names
    const trimColumnNames = (columns) => columns.map((column) => column.trim());

    // Check if mandatory columns are present
    const mandatoryColumns = trimColumnNames(["Phone"]);
    const csvColumns = trimColumnNames(headers);
    const missingColumns = mandatoryColumns.filter(
      (column) => !csvColumns.includes(column)
    );

    if (missingColumns.length > 0) {
      appContext.setAlert(
        `Missing mandatory columns: ${missingColumns.join(", ")}`,
        "alert_error"
      );
      setUploadStatus("failure");
      inputRef.current.value = null;
      return;
    }

    // Process CSV data, filter out empty rows
    const data = lines
      .filter((line) => line.trim() !== "") // Filter out empty rows
      .slice(1) // Skip headers
      .map((line) => {
        const values = line.split(",");
        // Map values to columns based on the header order
        const rowData = {};
        csvColumns.forEach((column, index) => {
          rowData[column.toLowerCase()] = values[index].trim(); // Convert key to lowercase
        });

        return rowData;
      });

    setCsvData(data);
    handleCustomSubmit(data);
    inputRef.current.value = null;
  };

  const handleCustomSubmit = (data) => {
    let isValid = true;
    let msg = "";

    data?.forEach((item, idx) => {
      if (!item.hasOwnProperty("phone")) {
        isValid = false;
        msg = `Please check Phone value in the uploaded CSV at line ${idx + 2}`;
        return;
      }
      if (
        templateType &&
        templateType !== undefined &&
        templateType !== "text"
      ) {
        if (
          item?.hasOwnProperty(templateType) === "" ||
          !item?.hasOwnProperty(templateType)
        ) {
          isValid = false;
          msg = `Please provide ${templateType} value in the uploaded CSV at line ${
            idx + 2
          }`;
          return; // Exit loop early if phone is missing
        }
      }

      const varList = Object.keys(item)
        .filter((key) => key.startsWith("var_"))
        .map((key) => item[key]);

      if (varList.length !== count) {
        isValid = false;
        msg = `Please check No. of variabaales declared in the uploaded CSV at line ${
          idx + 2
        }`;
        return; // Exit loop early if phone is missing
      }
      // Check if any var_ key is empty
      const emptyVarKeys = Object.keys(item).filter(
        (key) => key.startsWith("var_") && item[key] === ""
      );
      if (emptyVarKeys.length > 0) {
        isValid = false;
        msg = `Please check empty var_ values in the uploaded CSV at line ${
          idx + 2
        }`;
        return; // Exit loop early if any var_ key is empty
      }

      // Check if any value in var_list is duplicated
      const hasDuplicates = new Set(varList).size !== varList.length;
      if (hasDuplicates) {
        isValid = false;
        msg = `Duplicate values found in var_list in the uploaded CSV at line ${
          idx + 2
        }`;
        return; // Exit loop early if duplicates found
      }

      // Remove var_ keys from the object
      Object.keys(item).forEach((key) => {
        if (key.startsWith("var_")) {
          delete item[key];
        }
      });

      // if (varList?.length !== 0) {
        // Add var_list array and retain the phone key
        item["var_list"] = varList;
      // }
    });

    if (!isValid) {
      appContext.setAlert(msg, "alert_error");
      return;
    }

    let body = { template_id: templateId, data: data };
    console.log("body", body);
    post_data(
      `${API_URL}/convo/hook/template/send/v1/`,
      body,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        setUploadStatus("success");
        appContext.setAlert(data?.message, "alert_success");
        inputRef.current.value = null;
        setClose(true);
      }
    });
  };

  return (
    <>
      <div className={styles.up_wrapper}>
        <label htmlFor={"template"} className="btn">{`Upload Bulk CSV`}</label>
        <input
          id={"template"}
          className="upload"
          name={`upload_${"template".toLowerCase()}`}
          type="file"
          style={{ display: "none" }}
          accept=".csv"
          ref={inputRef}
          onChange={handleFileUpload}
        />
      </div>
      {uploadStatus === "success" && <SuccessIcon />}
      {uploadStatus === "failure" && <FailureIcon />}
    </>
  );
}

export default CsvUpload;
