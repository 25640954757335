import React, { useContext, useEffect, useState } from "react";
import styles from "../css/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../../App.js";
import { post_data } from "../../../../../cruxreactlib/networkhandler.js";
import { API_URL } from "../../../../../config.js";
import ConditionList from "../components/ConditionList/index.js";
import { getTicketFields } from "../components/Constants.js";
import SlaTarget from "../components/SlaTarget/index.js";
import SlaSidebar from "../components/SlaSidebar.js";

function NewSla() {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [slaData, setSlaData] = useState([]);
  const [conditions, setConditions] = useState({
    match_type: "any",
    properties: [{}],
  });

  const [error, setError] = useState(false);

  const { type } = useParams();
  const [payload, setPayload] = useState({
    condition_type: "or",
    description: "",
  });

  const [countConditions, setCountConditions] = useState([1]);

  useEffect(() => {
    fetchTicketConstants();
  }, [appContext.reload, appContext.ticketFields]);

  // Fetch ticket constants for automation
  async function fetchTicketConstants() {
    const sla_data = await getTicketFields(appContext);
    setSlaData(sla_data);
  }

  // Handling condition validation
  function handleAddConditionValidation(data) {
    if (!data.match_type) {
      return false;
    }
    for (let i = 0; i < data?.properties?.length; i++) {
      let info = data?.properties[i];

      if (!info.operator || !info.key || !info.value) {
        appContext.setAlert("fill previous condition", "alert_error");
        return false;
      }
    }
    return true;
  }

  // Handling new condition
  function handleAddCondition(idx, matchType) {
    let info = conditions;
    let checkAction = handleAddConditionValidation(info);
    if (!checkAction) {
      return;
    }
    info = { match_type: matchType, properties: [...info.properties, {}] };
    let data = conditions;
    data = info;
    setConditions({ ...data });
  }

  // Handling rule submit
  function handleSubmit(e) {
    e.preventDefault();

    let finalPayload = {
      ...payload,
      conditions: conditions,
    };

    setPayload({
      ...finalPayload,
    });

    checkValidationErrors(finalPayload);
  }

  function checkFinalPayload(data) {
    if (data?.conditions?.properties?.[0] == null) {
      return false;
    }
    return true;
  }

  function cleanPayload(payload) {
    if (payload == null || payload == undefined) {
      return null;
    }

    if (Array.isArray(payload)) {
      return payload.map((item) => cleanPayload(item));
    }

    if (typeof payload == "object") {
      const cleanedObj = {};

      for (const key in payload) {
        const cleanedValue = cleanPayload(payload[key]);

        if (cleanedValue != null) {
          cleanedObj[key] = cleanedValue;
        }
      }

      if (cleanedObj.actions) {
        cleanedObj.actions = cleanedObj.actions.map((action) =>
          cleanPayload(action)
        );
      }

      if (cleanedObj.conditions) {
        cleanedObj.conditions = cleanPayload(cleanedObj.conditions);
      }

      if (cleanedObj.events) {
        cleanedObj.events = cleanPayload(cleanedObj.events);
      }

      return Object.keys(cleanedObj).length > 0 ? cleanedObj : null;
    }

    return payload;
  }

  // Check for validation errors and proceed with automation creation
  function checkValidationErrors(final_payload) {
    const finalData = cleanPayload(final_payload);
    if (checkFinalPayload(finalData)) {
      if (
        finalData.conditions &&
        finalData?.conditions?.properties?.[0] == null
      ) {
        finalData.conditions.properties = [];
      }
      handleAutomationCreation(finalData);
    } else {
      appContext.setAlert("Select one condition", "alert_error");
    }
  }

  // Handle automation creation
  async function handleAutomationCreation(ticket_payload) {
    const data = await post_data(
      `${API_URL}/convo/ticket/sla/v1/`,
      ticket_payload,
      appContext,
      true
    );

    if (data) {
      navigate("/workflows/sla/policy");
    }
  }

  // Handle match_type for condition
  function handleConditionData(type, idx) {
    let info = conditions;
    info = { ...info, match_type: type };
    setConditions({ ...info });
  }

  useEffect(() => {
    setError(false);
  }, [conditions]);

  function handleClose() {
    navigate("/workflows/sla/policy");
  }

  return (
    <div className={styles.automation_wrapper}>
      <form className={styles.container_wrapper} onSubmit={handleSubmit}>
        <div className={styles.header}>
          <span className={styles.header_rule_label}>New Policy for:</span>
          <h2 className={styles.ticket_heading}>Ticket {type}</h2>
        </div>
        <div className={styles.header_label}>
          <label className={styles.header_label_text}>Policy name</label>
          <input
            type="text"
            placeholder="Enter policy name"
            className={styles.header_label_input}
            value={payload?.name}
            name="name"
            onChange={(e) => setPayload({ ...payload, name: e.target.value })}
            required
          />
        </div>
        <div className={styles.header_label}>
          <label className={styles.header_label_text}>Description</label>
          <input
            type="text"
            placeholder="Enter Description"
            className={styles.header_label_input}
            value={payload?.description}
            required
            name="description"
            onChange={(e) =>
              setPayload({ ...payload, description: e.target.value })
            }
          />
        </div>

        <div className={styles.header_label}>
          <label>Perform these Conditions:</label>{" "}
          {countConditions?.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <ConditionList
                  handleConditionData={handleConditionData}
                  handleAddCondition={handleAddCondition}
                  conditions={conditions}
                  setConditions={setConditions}
                  countIndex={idx}
                  error={error}
                  slaData={slaData}
                  edit={false}
                />
              </React.Fragment>
            );
          })}
        </div>

        <SlaTarget payload={payload} setPayload={setPayload} />
        <div className={styles.border_bottom_line}></div>
        <div className={styles.save_btn}>
          <button className={styles.preview_btn}>Preview and save</button>
          <button
            className={styles.cancel_btn}
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </form>
      <SlaSidebar />
    </div>
  );
}

export default NewSla;
