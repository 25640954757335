import React from "react";
import styles from "../../css/template.module.css";
import AddTemplate from "./AddTemplate";
import BulkSend from "./BulkSend";

function TemplateList({ item }) {
  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.row}>
        <div className={styles.details}>
          <p className={styles.heading}>Template Name</p>
          <p className={styles.text}>{item?.name}</p>
        </div>
        <div className={styles.details}>
          <p className={styles.heading}>Template Id</p>
          <p className={styles.text}>{item?.vf_template_id}</p>
        </div>
        <div className={styles.details}>
          <p className={styles.heading}>Variable Count</p>
          <p className={styles.text}>{item?.variable_ct}</p>
        </div>
        <div className={styles.details}>
          <p className={styles.heading}>Type</p>
          <p className={styles.text}>{item?.type || "---"}</p>
        </div>
        <div className={styles.details} style={{ flex: ".5" }}>
          <p
            className={styles.text}
            style={{ marginTop: "1vh", display: "flex", gap: "10px" }}
          >
            <AddTemplate data={item} isEdit={true} />
            <BulkSend template_name={item?.name} data={item} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default TemplateList;
