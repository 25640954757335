import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../css/style.module.css";
import Select from "react-select";
import {
  get_data,
  patch_data,
} from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import { AppContext } from "../../../../../App";
import { TeamContext } from "../../../../../Routes/AdminRoutes/Components/TeamsRoute";

function EditAgent() {
  const navigate = useNavigate();
  const { agent_id } = useParams();
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { details } = teamContext || {};
  const { agents, groups, roles } = details || {};

  const supervisorOptions = (agents || []).map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  const mapOptions = (items) =>
    items?.map((item) => ({ label: item.name, value: item.id })) || [];

  let [originalData, setOriginalData] = useState({});
  let [agent, setAgent] = useState({
    name: "",
    phone: "",
    email: "",
    supervisor: 1,
    roles: [],
    groups: [],
  });

  useEffect(
    function () {
      get_data(`${API_URL}/convo/users/agent/${agent_id}/v1/`, appContext).then(
        function (data) {
          if (data) {
            setOriginalData({
              groups: data?.data?.groups,
              data: {
                name: data?.data?.name,
                email: data?.data?.email,
                phone: data?.data?.phone,
                roles: data?.data?.roles,
                supervisor: data?.data?.supervisor,
              },
            });
            setAgent({
              groups: data?.data?.groups,
              data: {
                name: data?.data?.name,
                email: data?.data?.email,
                phone: data?.data?.phone,
                roles: data?.data?.roles,
                supervisor: data?.data?.supervisor,
              },
            });
          }
        }
      );
    },
    [appContext.reload]
  );

  let [selectValue, setSelectValue] = useState(
    mapOptions(groups)?.filter((option) =>
      agent?.groups?.includes(option.value)
    )
  );
  let [selectRole, setSelectRole] = useState(
    mapOptions(roles)?.filter((option) =>
      agent?.data?.roles?.includes(option?.value)
    )
  );

  useEffect(() => {
    const selectedOptions = mapOptions(groups)?.filter((option) =>
      agent?.groups?.includes(option?.value)
    );
    const selectedRoleOptions = mapOptions(roles)?.filter((option) =>
      agent?.data?.roles?.includes(option?.value)
    );
    setSelectValue(selectedOptions);
    setSelectRole(selectedRoleOptions);
  }, [agent?.groups, groups, roles, agent?.roles]);

  const handleChange = (key, value) => {
    setAgent((prevAgent) => ({
      ...prevAgent,
      ...(key === "groups"
        ? { groups: value }
        : { data: { ...prevAgent.data, [key]: value } }),
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    const deepEqual = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };
    const changes = deepEqual(originalData, agent) ? [] : ["data"];

    if (changes.length > 0) {
      const patchData = {};
      if (changes.includes("data") || agent.groups !== originalData.groups) {
        patchData.groups = agent.groups;
      }
      const dataChanges = changes.includes("data")
        ? Object.keys(agent.data).filter(
            (key) => agent.data[key] !== originalData.data[key]
          )
        : [];
      if (dataChanges.length > 0) {
        patchData.data = {};
        dataChanges.forEach((key) => {
          patchData.data[key] = agent.data[key];
        });
      }
      patch_data(
        `${API_URL}/convo/users/agent/${agent_id}/v1/`,
        patchData,
        appContext,
        true
      ).then((data) => data && handleClose());
    } else {
      appContext.setAlert("Please make changes to update.", "alert_error");
    }
  }

  function handleClose() {
    navigate("/teams/agents");
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.edit_heading}>Edit Agent</h2>
      <form className={styles.edit_form} onSubmit={handleSubmit}>
        <div className={styles.input_container}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            placeholder="Enter name"
            value={agent?.data?.name}
            className={styles.input}
            onChange={(e) => handleChange("name", e.target.value)}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Phone</label>
          <input
            type="text"
            placeholder="Enter phone"
            value={agent?.data?.phone}
            className={styles.input}
            onChange={function (e) {
              if (e.target.value.length > 10 || isNaN(e.target.value)) {
                return;
              }
              handleChange("phone", e.target.value);
            }}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            placeholder="Enter email"
            className={styles.input}
            value={agent?.data?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Group </label>
          <Select
            className={`${styles.select} ${styles.multiselect}`}
            closeMenuOnSelect={false}
            isMulti
            options={mapOptions(groups)}
            value={selectValue}
            onChange={(selectedOptions) => {
              setSelectValue(selectedOptions);
              handleChange(
                "groups",
                selectedOptions?.map((item) => item?.value)
              );
            }}
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Supervisor</label>
          <Select
            className={`${styles.select} ${styles.multiselect}`}
            value={supervisorOptions?.find(
              (option) => option.value === agent?.data?.supervisor
            )}
            onChange={(selectedOption) =>
              handleChange("supervisor", selectedOption?.value)
            }
            options={supervisorOptions}
            placeholder="Select Supervisor"
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Roles</label>
          <Select
            className={`${styles.select} ${styles.multiselect}`}
            closeMenuOnSelect={false}
            isMulti
            options={mapOptions(roles)}
            value={selectRole}
            onChange={(selectedOptions) => {
              setSelectRole(selectedOptions);
              handleChange(
                "roles",
                selectedOptions.map((item) => item.value)
              );
            }}
          />
        </div>

        <div className={styles.btn_div}>
          <button className="dark-btn" type="submit">
            Update Agent
          </button>
          <button type="button" className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditAgent;
