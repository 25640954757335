import React, { useContext, useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../../../App";
import { API_URL } from "../../../../config";
import { get_data } from "../../../../cruxreactlib/networkhandler";
import styles from "./style.module.css";
import TabBar from "../../../../cruxreactlib/utils/TabBar";
import { checkClick } from "../../../../cruxreactlib/utils/utility";

function Search(props) {
  const appContext = useContext(AppContext);
  let search = appContext.search;
  let setSearch = appContext.setSearch;
  let [txt, setTxt] = useState("");
  let [tickets, setTickets] = useState([]);
  let [users, setUsers] = useState([]);
  let [searchType, setSearchType] = useState("ticket");
  let [page, setPage] = useState(1);
  let [next, setNext] = useState(true);
  let [loader, setLoader] = useState(true);

  function handleChange(e) {
    setTxt(e.target.value);
  }
  function handleClick() {
    setTxt("");
    setSearch(false);
  }
  useEffect(
    function () {
      setPage(1);
      setNext(true);
      setLoader(false);
      searchType == "ticket"
        ? get_data(
            `${API_URL}/convo/ticket/search/v1/?query_str=${txt}&page=1`,
            appContext
          ).then(function (data) {
            if (data) {
              setTickets(data.data);
              setNext(data?.has_next);
              setLoader(false);
              setPage(data?.page);
            }
          })
        : get_data(
            `${API_URL}/convo/customer/search/v1/?query_str=${txt}&page=1`,
            appContext
          ).then(function (data) {
            if (data) {
              setUsers(data.data);
              setNext(data?.has_next);
              setLoader(false);
              setPage(data?.page);
            }
          });
    },
    [txt, appContext.reload, searchType]
  );

  window.addEventListener("click", function (event) {
    checkClick(event, appContext.searchRef, appContext.setSearch);
  });

  return !search ? (
    <input
      className={styles.input}
      type="text"
      value={txt}
      onChange={handleChange}
      onFocus={function () {
        setSearch(true);
        setSearchType("ticket");
      }}
      placeholder="Search Tickets"
    />
  ) : (
    <div className={styles.search} ref={appContext.searchRef}>
      <input
        className={styles.input}
        type="text"
        value={txt}
        onChange={function (e) {
          if (e.target.value.length > 10) {
            return false;
          }
          setTxt(e.target.value);
        }}
        placeholder="Search Tickets"
      />

      <ul className={styles.ul} id="scrollableDiv">
        <TabBar
          tabs={[
            {
              title: "Tickets",
              execute: { func: setSearchType, value: "ticket" },
            },
            { title: "Users", execute: { func: setSearchType, value: "user" } },
          ]}
          styles={styles}
        />

        {searchType == "user" ? (
          <InfiniteScroll
            dataLength={users.length}
            pageStart={1}
            scrollableTarget="scrollableDiv"
            next={function () {
              get_data(
                `${API_URL}/convo/customer/search/v1/?query_str=${txt}&page=${page}`,
                appContext
              ).then(function (data) {
                if (data) {
                  setUsers(users.concat(data.data));
                  setNext(data?.has_next);
                  setPage(data?.page);
                }
              });
            }}
            hasMore={next}
            loader={
              <h4 style={{ textAlign: "center", width: "100%" }}>
                Loading ...{" "}
              </h4>
            }
            endMessage={
              users.length > 0 ? (
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen all users !!</b>
                </p>
              ) : (
                <></>
              )
            }
          >
            {users?.length > 0 ? (
              users.map(function (item, idx) {
                return (
                  <NavLink
                    key={idx}
                    to={`/users/details/${item.id}`}
                    onClick={handleClick}
                  >
                    <b>{item.name ? item.name : "Crux User"}</b>
                    <br />
                    <p>{item.phone}</p>
                  </NavLink>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>
                <b>No users found !!</b>
              </p>
            )}
          </InfiniteScroll>
        ) : (
          <InfiniteScroll
            dataLength={tickets.length}
            pageStart={1}
            scrollableTarget="scrollableDiv"
            next={function () {
              get_data(
                `${API_URL}/convo/ticket/search/v1/?query_str=${txt}&page=${page}`,
                appContext
              ).then(function (data) {
                if (data) {
                  setTickets(tickets.concat(data.data));
                  setNext(data?.has_next);
                  setPage(data?.page);
                }
              });
            }}
            hasMore={next}
            loader={
              <h4 style={{ textAlign: "center", width: "100%" }}>
                Loading ...{" "}
              </h4>
            }
            endMessage={
              users.length > 0 ? (
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen all tickets !!</b>
                </p>
              ) : (
                <></>
              )
            }
          >
            {tickets?.length > 0 ? (
              tickets.map(function (item, idx) {
                return (
                  <NavLink
                    key={idx}
                    to={`/details/${item.id}`}
                    onClick={handleClick}
                  >
                    <b>
                      {item.subject}&nbsp;#{item.id}
                    </b>
                    <br />
                    <p className={styles.row_div}>
                      <span>{item.phone}</span> <span>{item.created_at}</span>
                    </p>

                    <br />
                    {/* <p>Created on {item.n_slot_id}</p> */}
                  </NavLink>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>
                <b>No ticket found !!</b>
              </p>
            )}
          </InfiniteScroll>
        )}
      </ul>
    </div>
  );
}

export default Search;
