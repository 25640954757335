import React from "react";
import styles from "./styles.module.css";

function Activity({ data, idx, val }) {
  return (
    <div key={idx} className={styles.customer_info}>
      <span className={styles.heading}>
        {/* <b>{val?.agent_name || "A"}</b> &nbsp; */}
        {val?.description}
      </span>{" "}
      <label>{val?.updated_at}</label>
    </div>
  );
}

export default Activity;
