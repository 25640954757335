import React, { useRef, useState } from "react";
import styles from "./common.module.css";
import { IoIosSwitch } from "react-icons/io";
import { checkClick } from "./utils/utility";
import { navigateLogin } from "./networkhandler";
function SwitchHeader() {
  let [isOpen, setIsOpen] = useState(false);
  var baseUrl = window.location.origin;

  const switchRef = useRef(null);

  const handleNewButtonClick = () => {
    setIsOpen(!isOpen);
  };

  window.addEventListener("click", function (event) {
    checkClick(event, switchRef, setIsOpen);
  });

  function handleClick() {
    localStorage.clear();
    navigateLogin();
  }

  return (
    <div className={styles.cluster_cta} ref={switchRef}>
      <button className={styles.button} onClick={handleNewButtonClick}>
        <IoIosSwitch />
      </button>
      {isOpen && (
        <div className={styles.optionsContainer}>
          <a href={`${baseUrl}/ticket`} className={styles.optionButton}>
            Ticket
          </a>{" "}
          <a href={`${baseUrl}/sales`} className={styles.optionButton}>
            Sales
          </a>
          <a href={`${baseUrl}/chat`} className={styles.optionButton}>
            Chat
          </a>
          <a className={styles.optionButton_logout} onClick={handleClick}>
            Logout
          </a>
        </div>
      )}
    </div>
  );
}

export default SwitchHeader;
