import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import { API_URL } from "../../../../config";
import { patch_data } from "../../../../cruxreactlib/networkhandler";
import styles from "./style.module.css";
import CreateDependentField from "../FieldTypes/CreateDependentField";
import CreateDropDownField from "../FieldTypes/CreateDropdownField";
import CreateTextField from "../FieldTypes/CreateTextField";

function UpdateFilter({ ticket_data, ticket_id, filters, ticketFields }) {
  let [search, setSearch] = useState(false);
  let [txt, setTxt] = useState("");
  let [ticket, setTicket] = useState({});
  let [loader, setLoader] = useState(true);
  let [disabled, setDisabled] = useState(false);

  const appContext = useContext(AppContext);

  useEffect(
    function () {
      if (filters) {
        setLoader(false);
        setTicket(ticket_data);
      }
    },
    [filters]
  );

  function handleTxt(e) {
    setTxt(e.target.value);
  }
  function handleSubmit(e) {
    setDisabled(true);
    let payload = {};
    Object.entries(ticket).map(function (item, idx) {
      let key = item[0];
      let value = item[1];

      if (
        key &&
        key != "undefined" &&
        key != undefined &&
        ticket_data[key] !== value
      ) {
        if (key == "agent_name" || key == "group_name") {
          return;
        } else {
          payload[key] = value;
        }
      }
    });

    e.preventDefault();

    if (payload?.order_id === "") {
      appContext.setAlert("Enter order ID", "alert_error");
      setDisabled(false);
    } else {
      patch_data(
        `${API_URL}/convo/ticket/${ticket_id}/v1/`,
        { ...payload },
        appContext,
        true
      ).then(function (data) {
        if (data) {
          setDisabled(false);
        }
      });
    }
  }

  function checkValidFilter(item) {
    if (item?.field_type == "dropdown" || item?.field_type == "dependent") {
      return true;
    }
    return true;
  }

  function renderFieldType(item) {
    if (item?.field_type == "dependent" && !item?.parent_field) {
      return (
        <CreateDependentField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={ticketFields}
          isClearable={false}
        />
      );
    } else if (item?.field_type == "dropdown" && !item?.parent_field) {
      return (
        <CreateDropDownField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={ticketFields}
          isClearable={false}
        />
      );
    } else if (
      (item?.field_type == "text" || item?.field_type == "integer") &&
      !item?.parent_field
    ) {
      return (
        <CreateTextField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={ticketFields}
        />
      );
    } else if (item?.field_type == "phone" && !item?.parent_field) {
      return (
        <CreateTextField
          item={item}
          filter={ticket}
          setFilter={setTicket}
          ticketFields={ticketFields}
          phone={true}
        />
      );
    }
  }

  return loader ? (
    <></>
  ) : (
    <div className={styles.filter_container}>
      <div className={styles.filter_flex}>
        <div className={styles.search}>
          <p className={styles.label_heading}>Disposition</p>

          {search && (
            <input
              className={styles.input}
              type="text"
              value={txt}
              onChange={handleTxt}
              placeholder="Search Fields"
            />
          )}
        </div>

        <div className={styles.filter_flex_config}>
          {ticketFields?.config?.ticket_fields?.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                {checkValidFilter(item) && renderFieldType(item)}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <button className={styles.btn} onClick={handleSubmit} disabled={disabled}>
        Update
      </button>
    </div>
  );
}

export default UpdateFilter;
