import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../App";
import { TeamContext } from "../../../../Routes/AdminRoutes/Components/TeamsRoute";
import Navbar from "./components/Navbar";
import NewAgent from "./components/NewAgent";
import styles from "./css/style.module.css";

function AgentRoles() {
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  let [query, setQuery] = useState("");
  let [loader, setLoader] = useState(true);

  const context = { query, setQuery };
  const { details } = teamContext || {};
  const { agents } = details || {};

  useEffect(
    function () {
      appContext.setTitle("Agents");
      if (details && details?.agents !== undefined) {
        setLoader(false);
      }
    },
    [appContext.reload]
  );

  return loader ? (
    <div className="loader_container">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <Navbar context={context} />

      <div className={`"item-row-container" ${styles.container}`}>
        <div className={styles.header}>
          <p className={styles.heading}>Name</p>
          <p className={styles.heading}>Concurrency</p>
          <p className={styles.heading}>Roles</p>
          <p className={styles.heading}>Groups</p>
          <p className={styles.heading}>Ecosystem</p>
          <p className={styles.heading} style={{ visibility: "hidden" }}>
            Seen
          </p>
        </div>

        <div className={styles.agent_container}>
          {agents?.length > 0 ? (
            query ? (
              agents
                ?.filter((item) =>
                  item?.name?.toLowerCase().includes(query.toLowerCase())
                )
                .map((item, idx) => <NewAgent data={item} key={idx} />)
            ) : (
              agents?.map((item, idx) => <NewAgent data={item} key={idx} />)
            )
          ) : (
            <h1 style={{ textAlign: "center", margin: "1vh auto" }}>
              No Agents found
            </h1>
          )}
        </div>
      </div>
    </>
  );
}

export default AgentRoles;
