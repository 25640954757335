import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../css/style.module.css";
import Select from "react-select";
import { post_data } from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import { AppContext } from "../../../../../App";
import { TeamContext } from "../../../../../Routes/AdminRoutes/Components/TeamsRoute";

function AddAgent() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { details } = teamContext || {};
  const { agents, groups, roles } = details || {};

  const supervisorOptions = (agents || []).map((item) => ({
    value: item?.id,
    label: item?.name,
  }));

  let [agent, setAgent] = useState({
    name: "",
    phone: "",
    email: "",
    supervisor: "",
    roles: [],
    groups: [],
  });

  const mapOptions = (items) =>
    items?.map((item) => ({ label: item.name, value: item.id })) || [];

  function handleSubmit(e) {
    e.preventDefault();
    let { groups, ...dataWithoutGroups } = agent;
    let body = { groups: agent?.groups, data: dataWithoutGroups };
    post_data(`${API_URL}/convo/users/agent/v1/`, body, appContext, true).then(
      (data) => data && handleClose()
    );
  }

  function handleClose() {
    navigate("/teams/agents");
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.edit_heading}>New Agent</h2>
      <form className={styles.edit_form} onSubmit={handleSubmit}>
        <div className={styles.input_container}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            placeholder="Enter name"
            value={agent?.name}
            className={styles.input}
            onChange={(e) => setAgent({ ...agent, name: e.target.value })}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Phone</label>
          <input
            type="text"
            placeholder="Enter phone"
            value={agent?.phone}
            className={styles.input}
            onChange={function (e) {
              if (e.target.value.length > 10 || isNaN(e.target.value)) {
                return;
              }
              setAgent({ ...agent, phone: e.target.value });
            }}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            placeholder="Enter email"
            className={styles.input}
            value={agent?.email}
            onChange={function (e) {
              setAgent({ ...agent, email: e.target.value });
            }}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Group </label>
          <Select
            className={`${styles.select} ${styles.multiselect}`}
            closeMenuOnSelect={false}
            isMulti
            options={mapOptions(groups)}
            onChange={(e) =>
              setAgent({ ...agent, groups: e.map((item) => item.value) })
            }
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Supervisor</label>
          <Select
            className={`${styles.select} ${styles.multiselect}`}
            value={supervisorOptions?.find(
              (option) => option.value === agent?.supervisor
            )}
            onChange={(selectedOption) =>
              setAgent({ ...agent, supervisor: selectedOption?.value })
            }
            options={supervisorOptions}
            placeholder="Select Supervisor"
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Roles</label>
          <Select
            className={`${styles.select} ${styles.multiselect}`}
            closeMenuOnSelect={false}
            isMulti
            options={mapOptions(roles)}
            onChange={(e) =>
              setAgent({ ...agent, roles: e.map((item) => item.value) })
            }
          />
        </div>

        <div className={styles.btn_div}>
          <button className="dark-btn" type="submit">
            Create Agent
          </button>
          <button type="button" className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddAgent;
