import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { ReactComponent as Email } from "../../../assets/Admin/Email.svg";
import { ReactComponent as Phone } from "../../../assets/Admin/Phone.svg";
import Select from "react-select";
import styles from "../css/style.module.css";
import { patch_data } from "../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../config";

function User({ data, options }) {
  const appContext = useContext(AppContext);
  let [close, setClose] = useState(false);
  let [randomColor, setRandomColor] = useState("#ffffff");
  let [show, setShow] = useState(false);
  let [value, setValue] = useState("");

  useEffect(() => {
    const getRandomLightColor = () => {
      const getComponent = () => Math.floor(Math.random() * 128) + 128;
      const rgb = [getComponent(), getComponent(), getComponent()];
      return `#${rgb
        .map((component) => component.toString(16).padStart(2, "0"))
        .join("")}`;
    };
    setRandomColor(getRandomLightColor());
  }, [data]);

  useEffect(
    function () {
      setClose(false);
      setShow(false);
    },
    [close]
  );

  function handleChange(e) {
    patch_data(
      `${API_URL}/convo/users/agent/${data.id}/v1/`,
      {
        data: {
          agent_break: e.value,
        },
      },
      appContext,
      true
    );
  }

  return (
    <div className={styles.user_wrapper}>
      <div className={styles.wrapper}>
        <div className={styles.col}>
          <p
            className={styles.icon_div}
            style={{ backgroundColor: randomColor }}
          >
            {data?.name?.charAt(0)}
          </p>

          <p className={styles.text}>{data?.name || "--"}</p>
        </div>
        <div className={styles.col}>
          <Email />
          <p className={styles.text}>{data?.email || "--"}</p>
        </div>
        <div className={styles.col}>
          <Select
            options={options}
            value={options?.filter((item) => {
              return item.label == data.agent_break;
            })}
            className="status_select"
            onChange={handleChange}
          />
        </div>

        <div className={styles.col}>
          <Phone />
          <p className={styles.text}>{data?.phone || "--"}</p>
        </div>
      </div>
    </div>
  );
}

export default User;
