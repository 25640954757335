import React, { useState } from "react";
import styles from "../css/style.module.css";
import { ReactComponent as DownArrow } from "../../../assets/Dialler/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../../assets/Dialler/UpArrow.svg";
import Strip from "./Strip";
import ReactJson from "react-json-view";
function DiallerData({ item }) {
  let [click, setClick] = useState(false);

  function handleClick() {
    setClick(!click);
  }

  function handlePreventDefault(e) {
    e.stopPropagation();
  }

  return (
    <div className={styles.outer_wrapper} onClick={handleClick}>
      <div className={styles.row}>
        <div className={styles.details}>
          <p className={styles.heading}>Subject</p>
          <p className={styles.text}>{item?.subject}</p>
        </div>
        <div className={styles.details}>
          <p className={styles.heading}>Phone</p>
          <p className={styles.text}>{item?.phone}</p>
        </div>
        <div className={styles.details}>
          <p className={styles.heading}>Campaign</p>
          <p className={styles.text}>{item?.campaign}</p>
        </div>
        <div className={styles.svg}>{click ? <UpArrow /> : <DownArrow />}</div>
      </div>

      {click && (
        <div className={styles.strip_wrapper}>
          <Strip item={item} />
          <div className={styles.row_1}>
            <span className={styles.json_heading}>Logs</span>
            <span className={styles.json_text} onClick={handlePreventDefault}>
              <ReactJson
                src={item?.logs}
                theme="lightTheme"
                // collapsed={true}
                name={false}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiallerData;
