// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components_text_field__MCABO {
  width: 80%;
  outline: none;
  padding: 0.8vh 0.5vw;
  margin: 0.5vh 0;
  border-radius: 5px;
  font-weight: 500;
  justify-content: center;
  background-color: #f3f5f7;
  color: var(--primary-color);
  border: 1px solid #cfd7df;
  background-color: #fff;
  /* background-image: linear-gradient(to bottom, #fff, #f3f5f7); */
}

.components_select_field__0ll\\+N {
  width: 29.1vw;
}

.components_css-13cymwt-control__PZdj4 {
  z-index: 100 !important;
}
@media screen and (max-width: 1540px) {
  .components_select_field__0ll\\+N {
    width: 29.5vw;
  }
}
@media screen and (max-width: 1320px) {
  .components_select_field__0ll\\+N {
    width: 35.5vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/Admin/Workflows/SlaPolicy/css/components.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,2BAA2B;EAC3B,yBAAyB;EACzB,sBAAsB;EACtB,iEAAiE;AACnE;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".text_field {\n  width: 80%;\n  outline: none;\n  padding: 0.8vh 0.5vw;\n  margin: 0.5vh 0;\n  border-radius: 5px;\n  font-weight: 500;\n  justify-content: center;\n  background-color: #f3f5f7;\n  color: var(--primary-color);\n  border: 1px solid #cfd7df;\n  background-color: #fff;\n  /* background-image: linear-gradient(to bottom, #fff, #f3f5f7); */\n}\n\n.select_field {\n  width: 29.1vw;\n}\n\n.css-13cymwt-control {\n  z-index: 100 !important;\n}\n@media screen and (max-width: 1540px) {\n  .select_field {\n    width: 29.5vw;\n  }\n}\n@media screen and (max-width: 1320px) {\n  .select_field {\n    width: 35.5vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text_field": `components_text_field__MCABO`,
	"select_field": `components_select_field__0ll+N`,
	"css-13cymwt-control": `components_css-13cymwt-control__PZdj4`
};
export default ___CSS_LOADER_EXPORT___;
