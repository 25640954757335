import React, { useEffect, useState, useRef } from "react";
import styles from "./css/style.module.css";
import TicketAdd from "./Components/TicketAdd";
import TicketEdit from "./Components/TicketEdit";

function Ticket({ ticketData, setTicketData, appContext, dbFields }) {
  const containerRef = useRef(null);
  const [ticketEditData, setTicketEditData] = useState([]);
  const [fieldDict, setFieldDict] = useState({});
  const [loader, setLoader] = useState(false);
  const [openTicket, setOpenTicket] = useState(-1);
  useEffect(() => {
    // Scroll to the bottom when the component mounts
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [ticketData]);

  useEffect(() => {
    setLoader(true);
    getTicketData();
  }, [appContext.reload, appContext.ticketFields]);

  async function getTicketData() {
    setTicketEditData(appContext.ticketFields?.config?.ticket_fields);
    setFieldDict(appContext.ticketFields?.field_dict);
    setLoader(false);
  }

  return (
    <div className={styles.container} ref={containerRef}>
      {loader ? (
        <div className="loader_container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className={styles.header}>Ticket Fields</div>
          {!ticketData?.isNew && ticketEditData?.length == 0 && (
            <h1>No Ticket Found</h1>
          )}
          {ticketEditData?.map((item, idx) => {
            return item?.parent_field == "" ? (
              <React.Fragment key={idx}>
                <TicketEdit
                  setOpenTicket={setOpenTicket}
                  openTicket={openTicket}
                  item={item}
                  idx={idx}
                  ticketEditData={ticketEditData}
                  setTicketEditData={setTicketEditData}
                  disable={item?.default}
                  fieldDict={fieldDict}
                  isNew={ticketData?.isNew}
                />
              </React.Fragment>
            ) : null;
          })}

          {ticketData?.isNew && (
            <TicketAdd
              item={ticketData}
              setTicketData={setTicketData}
              ticketEditData={ticketEditData}
              setTicketEditData={setTicketEditData}
              dbFields={dbFields}
              containerRef={containerRef}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Ticket;
