import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../css/role.module.css";
import {
  get_data,
  patch_data,
} from "../../../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../../../config";
import { AppContext } from "../../../../../App";
import { TeamContext } from "../../../../../Routes/AdminRoutes/Components/TeamsRoute";
import PermissionsList from "./PermissionsList";

function EditRole() {
  const navigate = useNavigate();
  const { role_id } = useParams();
  const appContext = useContext(AppContext);
  const teamContext = useContext(TeamContext);
  const { details } = teamContext || {};
  const { permissions } = details || {};
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

  let [originalData, setOriginalData] = useState({});
  let [role, setRole] = useState({
    name: "",
    description: "",
    permissions: selectedPermissionIds,
  });

  useEffect(
    function () {
      get_data(`${API_URL}/convo/users/role/${role_id}/v1/`, appContext).then(
        function (data) {
          if (data) {
            setOriginalData({
              name: data?.data?.name,
              description: data?.data?.description,
              permissions: data?.data?.permissions,
            });
            setRole({
              name: data?.data?.name,
              description: data?.data?.description,
              permissions: data?.data?.permissions,
            });
            setSelectedPermissionIds(data?.data?.permissions);
          }
        }
      );
    },
    [appContext.reload]
  );

  function handleSubmit(e) {
    e.preventDefault();

    e.preventDefault();

    const changes = Object.keys(role).filter(
      (key) => role[key] !== originalData[key]
    );

    if (selectedPermissionIds !== originalData.permissions) {
      // If selectedPermissionIds have changed, include 'permissions' in the changes array
      changes.push("permissions");
    }

    if (changes.length > 0) {
      const patchData = {};
      changes.forEach((key) => {
        patchData[key] =
          key === "permissions" ? selectedPermissionIds : role[key];
      });

      patch_data(
        `${API_URL}/convo/users/role/${role_id}/v1/`,
        { ...patchData },
        appContext,
        true
      ).then((data) => data && handleClose());
    } else {
      appContext.setAlert("Please make changes to update.", "alert_error");
    }
  }
  function handleClose() {
    navigate("/teams/roles");
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.edit_heading}>Edit Role</h2>
      <form className={styles.edit_form} onSubmit={handleSubmit}>
        <div className={styles.input_container}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            placeholder="Enter name"
            value={role?.name}
            className={styles.input}
            onChange={(e) => setRole({ ...role, name: e.target.value })}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Description</label>
          <input
            type="text"
            placeholder="Enter Description"
            className={styles.input}
            value={role?.description}
            onChange={function (e) {
              setRole({ ...role, description: e.target.value });
            }}
            required
          />
        </div>

        <div
          className={`${styles.input_container} ${styles.permission_container}`}
        >
          <label className={styles.label}>Permissions</label>
          <PermissionsList
            selectedPermissionIds={selectedPermissionIds}
            setSelectedPermissionIds={setSelectedPermissionIds}
            data={permissions}
          />
        </div>

        <div className={styles.btn_div}>
          <button className="dark-btn" type="submit">
            Update Role
          </button>
          <button type="button" className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditRole;
