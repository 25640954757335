import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../../App";
import { API_URL } from "../../../../../config";
import { post_data } from "../../../../../cruxreactlib/networkhandler";
import styles from "./style.module.css";
import { ReactComponent as Delete } from "../../../../../assets/Admin/Delete.svg";

function NewBusinessHour(props) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  let [name, setName] = useState("");

  useEffect(function () {
    appContext.setTitle("Business Hour");
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    let payload = { slots: bh, name: name };
    let modifiedSlots = payload.slots.map((slot) => {
      const { idx, ...rest } = slot;
      return rest;
    });
    payload = { ...payload, slots: modifiedSlots };

    console.log(payload);
    if (!name) {
      appContext.setAlert("Provide valid name !!", "alert_error");
      return;
    }
    post_data(
      `${API_URL}/convo/users/working/hour/v1/`,
      payload,
      appContext,
      true
    ).then(function (data) {
      if (data) {
        navigate("/teams/business-hour");
      }
    });
  }

  function handleClose() {
    navigate("/teams/business-hour");
  }

  const [bh, setBh] = useState([
    { idx: 1, start_time: "1200", end_time: "1200" },
  ]);

  function handleAddHour() {
    setBh((prevBh) => [
      ...prevBh,
      { idx: prevBh.length + 1, start_time: "1200", end_time: "1200" },
    ]);
  }

  function handleDeleteHour(idx) {
    setBh((prevBh) => prevBh.filter((item) => item.idx !== idx));
  }

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.edit_heading}>New Business Hour</h2>
        <form className={styles.edit_form} onSubmit={handleSubmit}>
          <div className={styles.input_container}>
            <label className={styles.label}>Business Hour</label>
            <input
              type="text"
              className={styles.input}
              name="name"
              required
              placeholder="Business Hour .."
              value={name}
              onChange={function (e) {
                setName(e.target.value);
              }}
            />
          </div>

          <div className={styles.outer_div}>
            {bh.map(function (item, idx) {
              return (
                <div className={styles.input_box_row} key={idx}>
                  <input
                    type="time"
                    className={styles.inline_input}
                    style={{ width: "40%" }}
                    value={
                      item.start_time.substring(0, 2) +
                      ":" +
                      item.start_time.substring(2)
                    }
                    onChange={(e) => {
                      setBh((prevBh) =>
                        prevBh.map((element, index) =>
                          index === idx
                            ? {
                                ...element,
                                start_time: e.target.value.split(":").join(""),
                              }
                            : element
                        )
                      );
                    }}
                  />
                  <p>to</p>
                  <input
                    type="time"
                    className={styles.inline_input}
                    style={{ width: "40%" }}
                    value={
                      item.end_time.substring(0, 2) +
                      ":" +
                      item.end_time.substring(2)
                    }
                    onChange={(e) => {
                      setBh((prevBh) =>
                        prevBh.map((element, index) =>
                          index === idx
                            ? {
                                ...element,
                                end_time: e.target.value.split(":").join(""),
                              }
                            : element
                        )
                      );
                    }}
                  />
                  {bh.length > 1 && (
                    <button
                      type="button"
                      className={styles.cta}
                      onClick={() => handleDeleteHour(item.idx)}
                    >
                      <Delete />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className={`btn ${styles.add_btn}`}
            onClick={handleAddHour}
          >
            + Add New Row
          </button>

          <div className={styles.btn_div}>
            <button className="dark-btn" type="submit">
              Create New Hour
            </button>
            <button type="button" className="btn" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewBusinessHour;
