import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../css/style.module.css";
import { get_data, patch_data } from "../../../cruxreactlib/networkhandler";
import { API_URL } from "../../../config";
import { AppContext } from "../../../App";
function EditUser() {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const appContext = useContext(AppContext);

  let [originalData, setOriginalData] = useState({});
  let [user, setUser] = useState({});

  useEffect(
    function () {
      get_data(`${API_URL}/convo/customer/${user_id}/v1/`, appContext).then(
        function (data) {
          if (data) {
            setUser(data?.data);
            setOriginalData(data?.data);
          }
        }
      );
    },
    [appContext.reload]
  );

  function handleSubmit(e) {
    e.preventDefault();
    const changes = Object?.keys(user)?.filter(
      (key) => user[key] !== originalData[key]
    );
    const patchData = {};
    if (changes?.length > 0) {
      changes?.forEach((key) => {
        patchData[key] = user[key];
      });
    }
    patch_data(
      `${API_URL}/convo/customer/${user_id}/v1/`,
      patchData,
      appContext,
      true
    ).then((data) => {
      if (data) {
        navigate("/users");
      }
    });
  }

  function handleClose() {
    navigate("/users");
  }

  return (
    <div className={styles.edit_container}>
      <h2 className={styles.edit_heading}>Edit User</h2>
      <form className={styles.edit_form} onSubmit={handleSubmit}>
        <div className={styles.input_container}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            placeholder="Enter name"
            value={user?.name}
            className={styles.input}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            required
          />
        </div>

        <div className={styles.input_container}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            placeholder="Enter email"
            className={styles.input}
            value={user?.email}
            onChange={function (e) {
              setUser({ ...user, email: e.target.value });
            }}
            required
          />
        </div>
        <div className={styles.input_container}>
          <label className={styles.label}>Facebook ID</label>
          <input
            type="text"
            placeholder="Enter ID"
            className={styles.input}
            value={user?.facebook_id}
            onChange={function (e) {
              setUser({ ...user, facebook_id: e.target.value });
            }}
            required
          />
        </div>
        <div className={styles.input_container}>
          <label className={styles.label}>Twitter ID</label>
          <input
            type="text"
            placeholder="Enter ID"
            className={styles.input}
            value={user?.twitter_id}
            onChange={function (e) {
              setUser({ ...user, twitter_id: e.target.value });
            }}
            required
          />
        </div>

        <div className={styles.btn_div}>
          <button className="dark-btn" type="submit">
            Update User
          </button>
          <button type="button" className="btn" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditUser;
