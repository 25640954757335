import React, { Suspense } from "react";
import { isAgentLoggedIn } from "../../cruxreactlib/auth";
import { Navigate, Outlet } from "react-router-dom";

function AdminDetailRoutes() {
  return isAgentLoggedIn() ? (
    <div>
      <Suspense
        fallback={
          <div className="loader_container">
            <div className="loader"></div>
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </div>
  ) : (
    <Navigate to="/validate" replace={true} />
  );
}

export default AdminDetailRoutes;
