import React, { useEffect, useState, useContext } from "react";
import styles from "../css/style.module.css";
import { ReactComponent as TicketIcon } from "../../../../../assets/Automation/ticketicon.svg";
import { IoMdTrash } from "react-icons/io";
import { AppContext } from "../../../../../App";

function BreakEdit({ item, disable, isNew, idx, breaks, callbackfn }) {
  const appContext = useContext(AppContext);
  const [data, setData] = useState("");
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isNew) {
      setOpen(false);
    }
    setData(item);
  }, [item, isNew]);

  function checkValid(info) {
    let check = breaks?.find((val) => val == info);
    return !check && check === undefined;
  }

  //handles new item added
  function handleSubmit(e) {
    e.preventDefault();
    if (!checkValid(data)) {
      appContext.setAlert("Name should be unique", "alert_error");
      return;
    }
    let payload = breaks;
    payload[idx] = data;
    setOpen(false);
    callbackfn(payload);
  }

  //handles the delete of ticket item
  function handleDelete() {
    let payload = breaks?.filter((item, index) => index !== idx);
    callbackfn(payload);
  }
  function handleChange(e) {
    setData(e.target.value);
  }

  return (
    <form className={styles.ticket_wrapper} onSubmit={handleSubmit}>
      <div className={styles.ticket_box}>
        <div className={`${styles.item_flex} pointer`}>
          <div
            className={styles.item_flex_box}
            onClick={() => setOpen(!isOpen)}
          >
            <span className={styles.ticket_icon}>
              <TicketIcon />
            </span>
            <span className={styles.icon_style}>{data?.[0]}</span>
            <span className={styles.label_length}>{data}</span>
          </div>
          <span className={styles.delete_btn} onClick={handleDelete}>
            <IoMdTrash />
          </span>
        </div>

        {isOpen && (
          <>
            <div className={styles.ticket_container}>
              <div className={styles.ticket_item}>
                <div className={styles.item}>
                  {/* <label className={styles.item_label}>Label</label> */}
                  <input
                    type="text"
                    className={styles.item_input}
                    required
                    value={data}
                    name="label"
                    onChange={handleChange}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>

            <div className={styles.ticket_btns}>
              <button
                className={styles.cancel_btn}
                type="button"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button className={styles.submit_btn}>Save Field</button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

export default BreakEdit;
