import React, { Suspense, useContext, useEffect } from "react";
import Alert from "../../cruxreactlib/utils/Alert";
import { AppContext } from "../../App";
import { isAgentLoggedIn } from "../../cruxreactlib/auth";
import { Navigate, Outlet } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/Header";
import Drawer from "../../components/Drawer";
import { get_data } from "../../cruxreactlib/networkhandler";
import { API_URL } from "../../config";

function AdminRoutes() {
  const appContext = useContext(AppContext);
  useEffect(
    function () {
      appContext.setPage("admin");
      get_filters();
    },
    [appContext.reload]
  );

  function get_filters() {
    get_data(`${API_URL}/convo/config/v1/`, appContext).then(function (data) {
      if (data) {
        const filtered_data = data;
        appContext.setTicketFields(filtered_data);
      }
    });
  }

  return isAgentLoggedIn() ? (
    <>
      <Drawer className="col_1" />

      <div className={appContext.drawer ? "col_4" : "col_2"}>
        <Suspense
          fallback={
            <div className="loader_container">
              <div className="loader"></div>
            </div>
          }
        >
          <AdminHeader
            setShowContent={appContext.setShowContent}
            setSearchType={appContext.setSearchType}
          />
          <Outlet />
        </Suspense>
        <Alert
          className={appContext.alert_class}
          response={appContext.response}
          setClass={appContext.setClass}
        />
      </div>
    </>
  ) : (
    <Navigate to="/validate" replace={true} />
  );
}

export default AdminRoutes;
