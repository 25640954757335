function checkClick(e, currentRef, setState) {
  if (
    currentRef &&
    currentRef.current &&
    currentRef.current.contains(e?.target)
  ) {
    return;
  }
  setState(false);
}

export { checkClick };
